import { API_STATUS } from "../../../../constants/commonConstants";
import { Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../../../../utils/commonUtils";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Link } from "react-router-dom";
import {
  BreadCrumbDataDematInformation,
  DematStatusTabs,
  DematStatusType,
} from "../../constants/informationConstants";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import VerifyModal from "../../../userManagement/components/modals/VerifyModal";
import UserInformationManager from "../../managers/userInformationManager";

export default function DematInformation() {
  const authContext = useContext(AuthContext);
  const dashboardManager = new UserInformationManager(authContext);
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [selectedTab, setSelectedTab] = useState(DematStatusTabs[0].value);

  const [confirmVerify, setConfirmVerify] = useState<any>(false);
  const [verifying, setVerifying] = useState(false);
  const [confirmUnlock, setConfirmUnlock] = useState<any>(false);
  const [unlocking, setUnlocking] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Beneficiary Name",
      headerAlign: "left",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) => {
        return params?.row?.name ? (
          <Link
            to={`/user/user-panel/${params.row?.user_id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      field: "demate_account_number",
      headerName: "Demat Account number",
      align: "center",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "verified",
      headerName: "Verified",
      align: "center",

      minWidth: 100,
      renderCell: (params: any) => (params.row?.verified ? "Yes" : "No"),
    },

    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align:
        selectedTab === DematStatusType.WITHOUT_DEMAT_ACCOUNT
          ? "center"
          : "left",
      minWidth:
        selectedTab === DematStatusType.WITHOUT_DEMAT_ACCOUNT ? 260 : 280,
      renderCell: (params: any) => {
        const isVerified = params.row?.verified;
        const showUnlock = params.row?.unlock;
        const onClickMarkVerify = () => setConfirmVerify(params.row);
        const onClickUnlock = () => setConfirmUnlock(params.row);

        return (
          <Stack
            direction="row"
            gap={2}
            width={!isVerified && showUnlock ? "100%" : "60%"}
          >
            {!isVerified && (
              <OutlinedButton onClick={onClickMarkVerify} tableAction>
                Mark as Verified
              </OutlinedButton>
            )}
            {showUnlock && (
              <Box width={"40%"}>
                <OutlinedButton onClick={onClickUnlock} tableAction>
                  Unlock
                </OutlinedButton>
              </Box>
            )}
          </Stack>
        );
      },
    },
  ];

  const getColumns = (tab: any) => {
    return tab === DematStatusType.WITHOUT_DEMAT_ACCOUNT
      ? columns.filter(
          (item: any) =>
            item?.field !== "actions" && item?.field !== "demate_account_number"
        )
      : columns;
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getData(page + 1);
  };

  const getData = async (pageNo: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data = await dashboardManager.getDematInformation(
        searchText,
        pageNo,
        selectedTab
      );
      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    setSearchText(value);
    setPage(1);
  };

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
    setPage(1);
  };

  useEffect(() => {
    getData(1);
  }, [selectedTab, searchText]);

  const submitVerify = async () => {
    try {
      setVerifying(true);
      const result = await dashboardManager.verifyDematInformation(
        confirmVerify?.investment_profile_id
      );
      setConfirmVerify(false);
      setVerifying(false);
      toast.success(result?.message);
      setPage(1);
      getData(1);
    } catch (e: any) {
      toast.error(e);
      setConfirmVerify(false);
      setVerifying(false);
    }
  };
  const submitUnlock = async () => {
    try {
      setUnlocking(true);
      const result = await dashboardManager.unlockDematInformation(
        confirmUnlock?.investment_profile_id
      );
      setConfirmUnlock(false);
      setUnlocking(false);
      toast.success(result?.message);
      setPage(1);
      getData(1);
    } catch (e: any) {
      toast.error(e);
      setConfirmUnlock(false);
      setUnlocking(false);
    }
  };

  return (
    <Box>
      {confirmVerify && (
        <VerifyModal
          isOpen={confirmVerify}
          message="Are you sure to Verify?"
          handleClose={() => setConfirmVerify(false)}
          handleSubmit={submitVerify}
          submitting={verifying}
        />
      )}
      {confirmUnlock && (
        <VerifyModal
          isOpen={confirmUnlock}
          message="Are you sure to Unlock?"
          handleClose={() => setConfirmUnlock(false)}
          handleSubmit={submitUnlock}
          submitting={unlocking}
        />
      )}

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataDematInformation?.steps}
            pageHeader={BreadCrumbDataDematInformation.pageHeader}
            stepKey={BreadCrumbDataDematInformation.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box>
            <CustomTextField
              label={"Search by Investor Name or Email"}
              width={"300px"}
              InputLabelProps={{}}
              customStyles={{}}
              error={false}
              value={value}
              onChange={setValue}
              id="name"
              name="name"
            />
          </Box>

          <ActionButton onClick={handleSearchClick}>Search</ActionButton>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={DematStatusTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          handleTabChange(tab);
          setPage(1);
        }}
        scrollable={false}
      />

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="50px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={getColumns(selectedTab)}
            rowHeight={50}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={10}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}
    </Box>
  );
}
