import React, { useCallback, useEffect, useState } from "react";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { Box, Grid } from "@mui/material";
import UserManagementManager from "../../managers/userManagementManager";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import AwaitingInvestor from "./AwaitingInvestor";
import VerifiedInvestor from "./VerifiedInvestor";
import RejectedInvestor from "./RejectedInvestor";
import {
  BreadCrumbDataVerifyNewInvestor,
  NEW_VERIFY_INVESTOR_TAB,
} from "../../constants/VERIFY_INVESTOR_CONSTANTS";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

export default function VerifyNewInvestors() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);

  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedTab, setSelectedTab] = useState(
    NEW_VERIFY_INVESTOR_TAB[0].value
  );

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const getData = async (pageNO: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data;
      switch (selectedTab) {
        case "awaiting":
          data = await userManagementManager.getVerifyingInvestorAwaiting(
            searchText,
            pageNO,
            "new"
          );
          break;
        case "verified":
          data = await userManagementManager.getVerifyingInvestorVerified(
            searchText,
            pageNO,
            "new"
          );
          break;
        case "rejected":
          data = await userManagementManager.getVerifyingInvestorRejected(
            searchText,
            pageNO,
            "new"
          );
          break;
        default:
          break;
      }
      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    setPage(1);
    setSearchText(value);
  };

  const handleTabClick = (tab: any) => {
    setValue("");
    setSearchText("");
    setSelectedTab(tab);
    setPage(1);
  };

  useEffect(() => {
    getData(1);
  }, [selectedTab, searchText]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataVerifyNewInvestor?.steps}
            pageHeader={BreadCrumbDataVerifyNewInvestor.pageHeader}
            stepKey={BreadCrumbDataVerifyNewInvestor.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box>
            <CustomTextField
              label={"Search by Name/Email"}
              width={"300px"}
              InputLabelProps={{}}
              customStyles={{}}
              error={false}
              value={value}
              onChange={(val: string) => setValue(val)}
              id="name"
              name="name"
            />
          </Box>
          <ActionButton onClick={handleSearchClick}>Search</ActionButton>
        </Grid>
      </Grid>

      <Box>
        <HorizontalTabs
          tabs={NEW_VERIFY_INVESTOR_TAB}
          selectedTab={selectedTab}
          onTabChange={handleTabClick}
          scrollable={false}
        />
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <>
            {selectedTab === "awaiting" ? (
              <AwaitingInvestor
                formattedData={formattedData}
                page={page}
                setPage={setPage}
                count={count}
                refreshData={getData}
              />
            ) : null}
            {selectedTab === "verified" ? (
              <VerifiedInvestor
                formattedData={formattedData}
                page={page}
                setPage={setPage}
                count={count}
                refreshData={getData}
              />
            ) : null}
            {selectedTab === "rejected" ? (
              <RejectedInvestor
                formattedData={formattedData}
                page={page}
                setPage={setPage}
                count={count}
                refreshData={getData}
              />
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
}
