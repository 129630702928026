import {
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  TextField,
} from "@mui/material";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { useStyles } from "./styles";
import useDebounce from "../../utils/useDebounce";

type OptionObjType = { label: string; value: string };
type OptionArrayType = OptionObjType[];

type LvAutoCompleteType = {
  label: string;
  options: OptionArrayType;
  setOptions: (val: any) => void;
  selectedObject: any;
  setSelectedObject: (val: any) => void;
  onChange?: (val: any) => Promise<void>;
  onSelect?: (val: any) => void;
  helperText?: string;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  creatable?: boolean;
  error?: any;
};

const LvAutoComplete = React.forwardRef<
  HTMLBaseElement,
  PropsWithChildren<LvAutoCompleteType>
>((props, ref) => {
  const classes = useStyles();
  const filter = createFilterOptions<any>();

  const {
    label,
    options,
    setOptions,
    selectedObject = null,
    setSelectedObject,
    onChange,
    onSelect,
    helperText = "Please enter 1 or more character",
    required,
    multiple,
    disabled,
    creatable,
  } = props;
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const textDeb = useDebounce(text, 500);

  const onChangeOnTextChange = async () => {
    if (textDeb) {
      onChange && (await onChange(textDeb));
      setLoading(false);
    } else {
      onChange && setOptions([]);
    }
  };

  useEffect(() => {
    onChangeOnTextChange();
  }, [textDeb]);
  return (
    <Autocomplete
      disabled={disabled}
      multiple={multiple}
      ref={ref}
      loading={loading}
      fullWidth
      className={classes.root}
      options={options}
      value={
        multiple
          ? selectedObject?.length
            ? selectedObject
            : []
          : selectedObject?.value
          ? selectedObject
          : null
      }
      popupIcon={
        <img
          src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
          alt="arrow-down"
        />
      }
      onChange={(event: any, value: any) => {
        setSelectedObject(value);
        onSelect && onSelect(value);
      }}
      filterOptions={
        multiple
          ? (options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              if (creatable) {
                const isExisting = options.some(
                  (option) => inputValue === option.label
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    value: inputValue,
                    label: `#${inputValue}`,
                    inputValue,
                  });
                }
              }
              return filtered;
            }
          : undefined
      }
      getOptionLabel={(option: any) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return `Add new tag : #${option.inputValue}`;
        }
        // Regular option
        return option?.label;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          className={classes.textField}
          required={required}
          onChange={(e) => {
            if (!multiple) {
              setLoading(!!e.target.value);
              setText(e.target.value);
            }
          }}
          error={props?.error}
          helperText={helperText}
        />
      )}
      renderTags={
        (multiple
          ? (
              value: readonly {
                label: string;
                value: string;
                inputValue: string;
              }[],
              getTagProps: any
            ) =>
              value.map(
                (
                  option: {
                    label: string;
                    value: string;
                    inputValue: string;
                  },
                  index: number
                ) => (
                  <Chip
                    variant="outlined"
                    label={option.label}
                    {...getTagProps({ index })}
                    key={index}
                  />
                )
              )
          : undefined) as any
      }
      PopperComponent={(props) => {
        return props?.open ? (
          <Box position="relative" width="100%">
            <Box className={`${classes.optionsBox}`}>
              <div
                className={classes.menuBox}
                style={{
                  maxHeight: "200px",
                }}
              >
                {props.children as any}
              </div>
              <div onMouseDown={(event) => event.preventDefault()}></div>
            </Box>
          </Box>
        ) : (
          <></>
        );
      }}
      PaperComponent={(props) => {
        return (
          <Box
            sx={{
              "& .MuiAutocomplete-listbox": {
                maxHeight: "none !important",
              },
            }}
            {...props}
          />
        );
      }}
    />
  );
});

export default LvAutoComplete;
