import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { API_STATUS } from "../../../../constants/commonConstants";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import SchemeManager from "../../managers/schemeManager";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";
import { CardContainer } from "../../../../ui-library/card/card-container";
import { render } from "@testing-library/react";
import SchemeProgressSkeleton from "../../skeleton/SchemeProgressSkeleton";

const StartupSchemeProgress = () => {
  const columns = [
    {
      field: "investor_name",
      headerName: "Investor Name",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "investor_type",
      headerName: "Investor Type",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "is_lead",
      headerName: "Is Lead",
      // flex: 2,
      minWidth: 80,
      renderCell: (params: any) => {
        return params.row.is_lead ? "Yes" : "No";
      },
    },
    {
      field: "scheme_type",
      headerName: "Scheme Document Type",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "scheme_consent_done",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params.row.scheme_consent_done ? "Done" : "Pending";
      },
    },
    {
      field: "action",
      headerName: "View Document",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params: any) => {
        const row = params?.row;
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("action_button", 200),
              borderColor: getColor("action_button", 200),
            }}
            tableAction
            disabled={!row?.scheme_generated_doc}
            onClick={() => window.open(row?.scheme_generated_doc, "_blank")}
          >
            View
          </OutlinedButton>
        );
      },
    },
  ];

  const [selectedVal, setSelectedVal] = useState<any>({});
  const [options, setOptions] = useState<any>([]);
  const [tableData, setTableData] = useState<any>();
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [cardData, setCardData] = useState<any>({});
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const schemeManager = new SchemeManager();
  const [isLoading, setLoading] = useState(false);

  const cardOptions: any = [
    {
      label: "Name",
      spacing: 3,
      value: cardData?.startupName ?? "-",
    },
    {
      label: "Lead Investors",
      spacing: 3,
      value: cardData?.leadInvestors ?? "-",
    },
    {
      label: "Sectors",
      spacing: 3,
      value: cardData?.sector ?? "-",
    },

    {
      label: "Stage",
      spacing: 3,
      value: cardData?.stage ?? "-",
    },
    {
      label: "Market",
      spacing: 3,
      value: cardData?.market ?? "-",
    },
    {
      label: "Founded",
      spacing: 3,
      value: cardData?.founded ?? "-",
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const fetchStartups = async (search: string) => {
    try {
      const result = await schemeManager.getAdminLookupData(
        "startups_as_per_tab",
        search,
        "progress"
      );
      setOptions(result);
    } catch (error: any) {
      console.error(error);
    }
  };

  const fetchSchemeData = async (selected: any) => {
    try {
      setLoading(true);
      if (selected?.value) {
        const result = await schemeManager.getSchemeAndStartupData(
          selected.value
        );
        setCardData(result.specific_data);
        setTableData(result.investor_data);
      }
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Stack spacing={3}>
      <Stack width="50%">
        <LvAutoComplete
          label="Select Startup"
          options={options}
          setOptions={setOptions}
          selectedObject={selectedVal}
          setSelectedObject={setSelectedVal}
          onChange={fetchStartups}
          onSelect={fetchSchemeData}
        />
      </Stack>
      {selectedVal?.value && (
        <>
          {isLoading ? (
            <SchemeProgressSkeleton />
          ) : (
            <>
              {" "}
              {!isEmpty(cardData) && (
                <>
                  <Typography fontWeight="600" textAlign="left">
                    Startup Details
                  </Typography>
                  <Box
                    sx={{
                      border: "1px solid #ececec",
                      borderRadius: "0.5rem",
                      textAlign: "left",
                      marginTop: "1rem",
                    }}
                  >
                    <CardContainer options={cardOptions} />
                  </Box>
                </>
              )}
              <br />
              {!isEmpty(tableData) && (
                <>
                  <Typography fontWeight="600" textAlign="left">
                    Investor Details
                  </Typography>

                  <LvComplexTable
                    rows={tableData}
                    columns={columns}
                    rowHeight={40}
                    headerHeight={60}
                    minWidth={750}
                    enableAdvancedFiltering
                    isUnFilteredDataPresent={!isEmpty([])}
                    enableAutoHeight={true}
                    pageSize={20}
                    page={page}
                    enablePagination={false}
                    totalRowCount={count}
                    handlePageChange={handlePageChange}
                    paginationMode="server"
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
};

export default StartupSchemeProgress;
