import { API_STATUS } from "../../../../constants/commonConstants";
import { Box, Grid } from "@mui/material";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { Link } from "react-router-dom";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import {
  BreadCrumbDataPvtInvestorInformation,
  caDocStatus,
  faasNonFaasTypes,
} from "../../constants/informationConstants";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import moment from "moment";
import { getColor } from "../../../../ui-library/Styles/Colors";
import UserInformationManager from "../../managers/userInformationManager";

export default function InvestorInformation() {
  const authContext = useContext(AuthContext);
  const dashboardManager = new UserInformationManager(authContext);
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [selectedCaDocStatus, setSelectedCaDocStatus] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getData({
      page: page + 1,
      search_text: searchText,
      ca_doc_sent_status: selectedCaDocStatus,
      profile_type: selectedType,
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Investor Name",
      headerAlign: "left",
      flex: 2,
      minWidth: 180,
      renderCell: (params: any) => {
        return params?.row?.name ? (
          <Link
            to={`/user/user-panel/${params.row?.user_id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      field: "email",
      headerName: "Investor Email",
      headerAlign: "left",
      flex: 2,
      minWidth: 300,
    },
    {
      field: "kyc_kra_check",
      headerName: "KYC & KRA Check",
      headerAlign: "left",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "date",
      headerName: "Last Modified",
      align: "center",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) =>
        params?.row?.date && moment(params?.row?.date).format("MMM DD, YYYY"),
    },
    {
      field: "ca_doc_sent",
      headerName: "Ca Doc Sent",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "ca_doc_sent_on",
      headerName: "Ca Doc Sent Date",
      align: "center",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) =>
        params?.row?.ca_doc_sent_date &&
        moment(params?.row?.ca_doc_sent_date).format("MMM DD, YYYY"),
    },
    {
      field: "faas_non_faas",
      headerName: "Faas/Non-Faas",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
  ];

  type GetDataParams = {
    search_text?: any;
    page?: number;
    ca_doc_sent_status?: { value: any };
    profile_type?: { value: any };
  };

  const getData = async ({
    search_text = "",
    page = 1,
    ca_doc_sent_status = { value: null },
    profile_type = { value: null },
  }: GetDataParams = {}) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data = await dashboardManager.getInvestorInformation(
        search_text,
        page,
        ca_doc_sent_status?.value,
        profile_type?.value
      );
      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    setPage(1);
    setSearchText(value);
  };

  const filterData = (type: any, value: any) => {
    if (type == "ca_doc_sent_status") {
      setSelectedCaDocStatus(value);
      setPage(1);
    } else {
      setSelectedType(value);
      setPage(1);
    }
  };

  useEffect(() => {
    getData({
      page: 1,
      search_text: searchText,
      ca_doc_sent_status: selectedCaDocStatus,
      profile_type: selectedType,
    });
  }, [selectedCaDocStatus, selectedType, searchText]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Breadcrumb
            steps={BreadCrumbDataPvtInvestorInformation?.steps}
            pageHeader={BreadCrumbDataPvtInvestorInformation.pageHeader}
            stepKey={BreadCrumbDataPvtInvestorInformation.stepKey}
          />
        </Grid>
        <Grid item xs={8} display="flex" gap={2} justifyContent="end">
          <Box width={"150px"}>
            <CoreDropdown
              label="CA Doc Sent"
              options={caDocStatus}
              value={selectedCaDocStatus}
              onClickMenuItem={(val: any) => {
                filterData("ca_doc_sent_status", val);
              }}
            />
          </Box>
          <Box width={"150px"}>
            <CoreDropdown
              label="Faas/Non-Faas"
              options={faasNonFaasTypes}
              value={selectedType}
              onClickMenuItem={(val: any) => {
                filterData("profile_type", val);
              }}
            />
          </Box>
          <Box>
            <CustomTextField
              label={"Search by Investor Name/Email"}
              width={"300px"}
              InputLabelProps={{}}
              error={false}
              value={value}
              onChange={setValue}
              id="name"
              name="name"
            />
          </Box>
          <ActionButton onClick={handleSearchClick}>Search</ActionButton>
        </Grid>
      </Grid>

      <div style={{ overflow: "auto" }}>
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={40}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={10}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </div>
    </Box>
  );
}
