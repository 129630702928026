import React from "react";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getColor } from "../../Styles/Colors";
import { LVThemeProvider } from "../../Styles/Themes";

export type LinkButtonProps = {
  /**
   * title text for button .
   */
  title?: string;
  /**
   *function definition for on click routing.
   */
  onClick?: () => void;
  /**
   * prop to hide the button
   */
  hidden?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles({
  root: {
    color: getColor("action_button", 300),
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  rootDisabled: {
    color: getColor("neutral", 300),
    cursor: "not-allowed",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
});
export function LinkButton({
  title = "view all",
  onClick,
  hidden = false,
  disabled = false,
}: LinkButtonProps) {
  const classes = useStyles();
  const handleClick = (e: any) => {
    e.preventDefault();
    onClick && onClick();
  };
  if (hidden) {
    return <div />;
  }
  return (
    <LVThemeProvider>
      <a
        className={disabled ? classes.rootDisabled : classes.root}
        onClick={!disabled ? handleClick : () => {}}
      >
        <Typography
          variant="heading_06_medium"
          color={
            disabled ? getColor("neutral", 300) : getColor("action_button", 300)
          }
          display={"inline"}
        >
          {title}
        </Typography>
      </a>
    </LVThemeProvider>
  );
}
