import requestManager from "../../../api/requestManager";

export const getDealDocuments = (queryString: string) => {
  const finalURL = `/v1/document_vault/startup/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getDealDocumentTypeOptions = () => {
  const finalURL = `/v1/document_vault/startup/get_document_list/`;
  return requestManager.apiGet(finalURL);
};

export const getInvestorDocuments = (queryString: string) => {
  const finalURL = `/v1/document_vault/investor/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const handleDocStatus = (documentId: number | null, data: object) => {
  const finalURL = `/v1/document_vault/investor/${documentId}/`;
  return requestManager.apiPatch(finalURL, data);
};

export const uploadBulkDocument = (data: any) => {
  const finalURL = `/v1/document_vault/investor/bulk_upload_docs/`;
  return requestManager.apiPost(finalURL, data, {
    "Content-Type": "multipart/form-data",
  });
};

export const uploadDocument = (data: any) => {
  const config = {
    "Content-Type": "multipart/form-data",
  };
  const finalURL = `/v1/document_vault/investor/`;
  return requestManager.apiPost(finalURL, data, config);
};

export const searchDealName = (search: string) => {
  const finalURL = `/v1/admin/lookups/get_lookup_data/?type=admin_search_startups&q=${search}`;
  return requestManager.apiGet(finalURL);
};

export const uploadDealDocument = (data: any) => {
  const config = {
    "content-type": "multipart/form-data",
  };
  const finalURL = `/v1/document_vault/startup/`;
  return requestManager.apiPost(finalURL, data, config);
};

export const publishUnpublish = (id: string, data: any) => {
  const finalURL = `/v1/document_vault/startup/${id}/`;
  return requestManager.apiPatch(finalURL, data);
};
