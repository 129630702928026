export const startupDetailsBreadCrumbData = {
  stepKey: 2,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: true,
      key: "startup-management",
    },
    {
      title: "Startup Details",
      titleDisplay: true,
      key: "startup-details",
    },
  ],
  pageHeader: {
    header: "Startup Details",
    navigationButton: true,
  },
};

export const EditFundraiseDetailsBreadCrumbData = {
  stepKey: 3,
  breadcrumbSeparator: true,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: true,
    },
    {
      title: "Fundraise Details",
      titleDisplay: true,
    },
    { title: "Edit Fundraise Details", titleDisplay: true },
  ],
  pageHeader: {
    header: "Edit Fundraise Details",
    navigationButton: true,
  },
};

export const StartupDetailTabs: any = [
  {
    name: "Basic Details",
    value: "basic_details",
  },
  {
    name: "Fundraise Details",
    value: "fundraise_details",
  },
  {
    name: "Screening Details",
    value: "screening_details",
  },
  {
    name: "Portfolio Details",
    value: "portfolio_details",
  },
];

export const visibilityOptions = [
  { value: 1, label: "Limited Visibility to Investors" },
  { value: 3, label: "Visible to All Investors" },
  { value: 4, label: "Stealth/Private" },
];

export const keyMapping = {
  // ### Basic Details ###
  dealDashboardAccess: "deal_dashboard_access",
  published: "publish",
  visibility: "complete_profile_privacy",
  superVisibility: "super_stealth",
  selectedPitchingFounder: "pitching_founder_id",
  typeOfStartup: "startup_type",
  privateSyndicateId: "private_syndicate_id",

  // ### Fundraising Details ###

  syndicateCloseThresholdPercent: "commit_threshold",
  fundCarryPercent: "fund_carry",
  leadCarryPercent: "lead_carry",
  leadInvestors: "update_lead_lead_carry",
  hurdleRate: "hurdle_rate",
  transactionFeesPercent: "default_transaction_fee",
  defaultTransactionFee: "default_transaction_fee",
  transactionFeesAmount: "transaction_fees_amount",
  minCommitment: "min_commitment",
  lvAllocation: "lv_allocated_deal_amount",
  schemeConsentToggle: "scheme_consent_switch",
  showCommitmentSwitch: "lead_show_commitment",
  startupDD: "update_startup_dd",
  proRataRights: "update_startup_prr",
  lightningDeal: "lightning_deal_status",
  maxNoOfCommits: "max_commits",
  minCommitAmount: "min_commit_amount",
  percentageOfRound: "percentage_of_round",
  startDate: "start_date",
  endDate: "end_date",
  mailerList: "allow_mailer_list",
  pitchingSession: "pitching_medias",

  // ### Portfolio Details ###

  fundPanNumber: "pan_no",
  selectedPrimaryStartup: "primary_startup_id",

  // ### Dropdown Actions ###
  markTrending: "trending_status",
  markFeatured: "mark_featured",
  removeFeatured: "remove_featured",
  markFunded: "mark_funded",
  markFundedOutsideLV: "mark_funded_outside_lv",
  lockEditProfilePage: "lock_edit_profile",
};

export const initialSelectedAction = {
  markTrending: false,
  removeTrending: false,
  markFeatured: false,
  removeFeatured: false,
  markFunded: false,
  markFundedOutsideLV: false,
  lockEditProfilePage: false,
  unlockEditProfilePage: false,
};

export const getActionDropdownOptions = (startupDetails: any) => [
  {
    key: "updateStartup",
    label: "Update Startup",
  },
  {
    key: "cloneStartup",
    label: "Clone Startup",
  },
  {
    key: "markTrending",
    label: "Mark as Trending",
    disabled: !startupDetails?.can_make_trending,
    disabledReason: "Startup is already trending",
  },
  {
    key: "removeTrending",
    label: "Removed from Trending",
    disabled: !startupDetails?.can_remove_trending,
    disabledReason: "Startup is not trending",
  },
  {
    key: "markFeatured",
    label: "Mark as Featured",
    disabled: !startupDetails?.can_make_featured,
    disabledReason: "Startup is already featured",
  },
  {
    key: "removeFeatured",
    label: "Remove from Featured",
    disabled:
      !startupDetails?.can_remove_featured,
    disabledReason: "Startup is not featured",
  },
  {
    key: "markFunded",
    label: "Mark as Funded",
    disabled: !startupDetails?.can_mark_funded_on_lv,
    disabledReason: "Startup is already funded",
  },
  {
    key: "markFundedOutsideLV",
    label: "Mark as Funded Outside LV",
    disabled: !startupDetails?.can_make_funded_outside,
    disabledReason: "Startup is already funded outside LV",
  },
  {
    key: "lockEditProfilePage",
    label: "Lock Edit Profile Page",
    disabled: startupDetails?.is_featured || startupDetails?.lock_edit_profile,
    disabledReason: "Edit profile page is already locked",
  },
  {
    key: "unlockEditProfilePage",
    label: "Unlock Edit Profile Page",
    disabled:
      !startupDetails?.is_featured && !startupDetails?.lock_edit_profile,
    disabledReason: "Edit profile page is not locked",
  },
];

export const getDropdownActionData = (selectedAction: any) => {
  const actionMapping: { [key: string]: any } = {
    markTrending: { markTrending: true },
    removeTrending: { markTrending: false },
    markFeatured: { markFeatured: true },
    removeFeatured: { removeFeatured: true },
    markFunded: { markFunded: true },
    markFundedOutsideLV: { markFundedOutsideLV: true },
    lockEditProfilePage: { lockEditProfilePage: true },
    unlockEditProfilePage: { lockEditProfilePage: false },
  };

  for (const key in actionMapping) {
    if (selectedAction?.[key]) {
      return actionMapping[key];
    }
  }

  return {};
};
