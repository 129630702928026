import requestManager from "../../../api/requestManager";

export const fetchDatabaseTables = () => {
  const finalURL = `/db/admin_query_editor/get_all_table_names/`;
  return requestManager.apiGet(finalURL);
};

export const fetchTableColumns = (tableName: string) => {
  const finalURL = `/db/admin_query_editor/get_table_columns/?table_name=${tableName}`;
  return requestManager.apiGet(finalURL);
};

export const executeQuery = (query: string) => {
  const finalURL = `/db/admin_query_editor/execute_query/
  `;
  return requestManager.apiPost(finalURL, { query });
};

export const saveQuery = (query: string, name: string) => {
  const finalURL = `/db/admin_query_editor/save_query/`
  return requestManager.apiPost(finalURL, { query, name });
};

export const getSavedQueries = () => {
  const finalURL = `/db/admin_query_editor/fetch_saved_queries/`
  return requestManager.apiGet(finalURL);
};
