import { Box, Divider, Grid, Typography } from "@mui/material";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import React, { useState } from "react";
import { breadCrumbData } from "../../constants/institutionPanelConstant";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";

const InstitutionsPanel = () => {
  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);

  const handleTextChange = async (value: any) => {
    try {
      // let result = await userManagementManager.getLookupData(value, "users");
      // result = result?.results?.map((item: any) => ({
      //   label: item.text,
      //   value: item.id,
      // }));
      // setOption(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <HorizontalTabs
            tabs={
              [
                {
                  name: "Select Institutional Investor",
                  value: "search_institutional_investor",
                },
              ] as any
            }
            selectedTab="search_institutional_investor"
          />

          <>
            <LvAutoComplete
              label={"Select Institution"}
              options={option}
              setOptions={setOption}
              selectedObject={selectedVal}
              setSelectedObject={setSelectedVal}
              onSelect={() => setOption([])}
              onChange={handleTextChange}
            />

            <br />

            <Link
              aria-disabled={!selectedVal?.value && !selectedVal?.value}
              style={{
                marginLeft: "auto",
                display: "block",
                width: "fit-content",

                pointerEvents:
                  !selectedVal?.value && !selectedVal?.value ? "none" : "auto",
              }}
              to={`/user/show-institution/${selectedVal?.value}/`}
            >
              <ActionButton disabled={!selectedVal?.value}>
                Go to User&apos;s Panel
              </ActionButton>
            </Link>
          </>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InstitutionsPanel;
