import React from "react";
import { Box } from "@mui/material";
import { CardContainer } from "../../../../../ui-library/card/card-container";
import { isEmpty } from "../../../../../utils/commonUtils";

const AccessDetails = ({ details = {} }: any) => {
  const cardOptions: any = [
    {
      label: "Activation Status",
      spacing: 3,
      value: details?.activated ? "Activated" : "Not Activated",
    },
    {
      label: "Publish Status",
      spacing: 3,
      value: details?.published ? "Published" : "Not Published",
    },
    {
      label: "Access for Deals/Feed/Syndicate",
      spacing: 3,
      value: details?.is_blocked ? "Blocked" : "Allowed",
    },

    {
      label: "Private Syndicate Investor Landing Page",
      spacing: 3,
      value: !isEmpty(details?.backer_has_fass_no_platform_access)
        ? "Access Revoked - Lands on Syndicates Page"
        : "Access Granted - Lands on Feed Page",
    },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ececec",
        borderRadius: "0.5rem",
        textAlign: "left",
        marginTop: "1rem",
      }}
    >
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default AccessDetails;
