import { Box, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { HorizontalTabs } from "../../../../../ui-library/tabs/horizontal-tabs";
import AddCommitment from "./commitment-action-tabs/AddCommitment";
import ModifyCommit from "./commitment-action-tabs/ModifyCommit";
import WithdrawCommitment from "./commitment-action-tabs/WithdrawCommitment";

const tabs: any = [
  {
    name: "Add Commitment",
    value: "add_commitment",
  },
  {
    name: "Modify Commitment",
    value: "modify_commitment",
  },
  {
    name: "Withdraw Commitment",
    value: "withdraw_commitment",
  },
];

const CommitmentActions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { detail } = location?.state ?? {};

  const [selectedTab, setSelectedTab] = useState(tabs[0]?.value);

  const EditBreadCrumbData = useMemo(
    () => ({
      stepKey: 3,
      breadcrumbSeparator: true,
      steps: [
        { title: "User Management", titleDisplay: true },
        { title: "Commitment Actions", titleDisplay: true },
        { title: "Edit Commitment Actions", titleDisplay: true },
      ],
      pageHeader: {
        header: detail?.name ?? "Edit Commitment Actions",
        navigationButton: true,
        navigationFunction: () => navigate(-1),
      },
    }),
    [navigate]
  );

  useEffect(() => {
    if (!detail?.investment_profiles_data?.length) {
      navigate(-1);
    }
  }, [location]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={5}>
          <Breadcrumb
            steps={EditBreadCrumbData.steps}
            pageHeader={EditBreadCrumbData.pageHeader}
            stepKey={EditBreadCrumbData.stepKey}
            breadcrumbSeparator
          />
        </Grid>
      </Grid>
      <HorizontalTabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />
      {selectedTab === "add_commitment" ? <AddCommitment /> : null}
      {selectedTab === "modify_commitment" ? <ModifyCommit /> : null}
      {selectedTab === "withdraw_commitment" ? <WithdrawCommitment /> : null}
    </Box>
  );
};

export default CommitmentActions;
