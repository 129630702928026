import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { useEffect, useState } from "react";
import {
  RSVPEventModalMode,
  RsvpTabs,
  breadCrumbData,
} from "../../constants/rsvpConstants/RsvpConstant";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import CreateRSVPEventModal from "./modals/createRSVPEvents";
import CampaignManagementManager from "../../managers/campaignManagementManager";
import { toast } from "react-toastify";
import { ThreeDotMenu } from "../../../../ui-library/tables/three-dot-menu";

export default function Rsvp() {
  const [selectedTab, setSelectedTab] = useState(RsvpTabs[0].value);
  const [mode, setMode] = useState(RSVPEventModalMode.CREATE);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
  const campaignManagementManager = new CampaignManagementManager();

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    fetchRsvpEventData(page + 1);
  };

  const column = [
    {
      field: "title",
      headerName: "Event Title",
      headerAlign: "left",
      flex: 1,
      minWidth: 160,
    },
    {
      field: "event_type",
      headerName: "Event Type",
      headerAlign: "left",
      minWidth: 200,
      renderCell: (params: any) =>
        params?.row?.event_type ? (
          <p style={{ textTransform: "capitalize" }}>
            {params?.row?.event_type?.replaceAll("_", " ")}
          </p>
        ) : (
          "-"
        ),
    },
    {
      field: "event_date",
      headerName: "Event Date & Time",
      headerAlign: "left",
      minWidth: 140,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 280,
      headerAlign: "left",
      renderCell: (params: any) => {
        return (
          <div
            contentEditable="true"
            dangerouslySetInnerHTML={{ __html: params?.row?.description }}
          ></div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      maxWidth: 70,
      headerAlign: "left",
      renderCell: (params: any) => {
        return (
          <ThreeDotMenu
            position="left"
            options={[
              {
                name: "See Details",
                onClickFunction: () => {
                  setSelectedEvent(params.row.id);
                  setIsCreateEventModalOpen(true);
                  setMode(RSVPEventModalMode.VIEW);
                },
              },
              {
                name: "Edit",
                onClickFunction: () => {
                  setSelectedEvent(params.row.id);
                  setIsCreateEventModalOpen(true);
                  setMode(RSVPEventModalMode.EDIT);
                },
              },
              {
                name: params.row.published ? "Un-Publish" : "Publish",
                onClickFunction: () =>
                  PublishRsvpEvent(params.row.id, !params.row.published),
              },
              {
                name: "Delete",
                onClickFunction: () => deleteRsvpEvent(params.row.id),
              },
            ]}
          />
        );
      },
    },
  ];

  const deleteRsvpEvent = async (event_id: any) => {
    try {
      let result = await campaignManagementManager.removeEvent(event_id);
      fetchRsvpEventData(1);
      setPage(1);
      toast.success("Event deleted Successfully");
    } catch (err) {
      toast.success("Something went wrong!");
    }
  };

  const PublishRsvpEvent = async (event_id: any, publish: boolean) => {
    try {
      let result = await campaignManagementManager.publishEvent(
        event_id,
        publish
      );
      fetchRsvpEventData(1);
      setPage(1);
      let msg = publish
        ? "Event Published Successfully"
        : "Event Un-Published Successfully";
      toast.success(msg);
    } catch (err) {
      toast.success("Something went wrong!");
    }
  };

  const fetchRsvpEventData = async (pageNo: number) => {
    setApiStatus(API_STATUS.LOADING);
    try {
      let result = await campaignManagementManager.getEventList(
        selectedTab,
        pageNo
      );
      setFormattedData(result?.formattedData);
      setCount(result?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (err) {
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const refreshData = () => {
    fetchRsvpEventData(1);
    setPage(1);
  };

  useEffect(() => {
    fetchRsvpEventData(1);
  }, [selectedTab]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={4}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>

        <Grid item xs={8} justifyContent="end" display="flex">
          <ActionButton
            onClick={() => {
              setIsCreateEventModalOpen(true);
              setMode(RSVPEventModalMode.CREATE);
            }}
          >
            Create Event
          </ActionButton>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={RsvpTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
          setPage(1);
        }}
        scrollable={false}
      />

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={column}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}
      {isCreateEventModalOpen && (
        <CreateRSVPEventModal
          isOpen={isCreateEventModalOpen}
          handleClose={() => {
            setSelectedEvent(null);
            setIsCreateEventModalOpen(false);
          }}
          selected={selectedEvent}
          mode={mode}
          refreshData={refreshData}
        />
      )}
    </Box>
  );
}
