import React, { FC, useEffect } from "react";
import "quill/dist/quill.snow.css"; // Add css for snow theme
import { useQuill } from "react-quilljs";
import { Box, FormHelperText, FormLabel } from "@mui/material";

type RichTextEditorProps = {
  label?: string;
  editorState: string;
  setEditorState: (editorState: string) => void;
  disabled?: boolean;
};

const RichTextEditor: FC<RichTextEditorProps> = (props) => {
  const { editorState, setEditorState, label, disabled } = props;
  const [loaded, setLoaded] = React.useState(false);
  const { quill, quillRef } = useQuill({
    theme: "snow",

    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
      ],
    },
  });

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    if (quill) {
      {
        if (!loaded) {
          quill.clipboard.dangerouslyPasteHTML(editorState);
          quill.blur();
          if (disabled) {
            quill.disable();
          }
        }
      }
      quill.on("text-change", (delta: any, oldDelta: any, source: any) => {
        setLoaded(true);
        onEditorStateChange(quill.root.innerHTML);
      });
    }
  }, [quill, editorState]);

  return (
    <Box>
      {label && <FormHelperText>{label}</FormHelperText>}
      <div ref={quillRef} />
    </Box>
  );
};

export default RichTextEditor;
