import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, FormHelperText, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field } from "formik";
import moment from "moment";
import { LVThemeProvider } from "../Styles/Themes";
import { getColor } from "../Styles/Colors";

const useStyles = makeStyles({
  datePickerLeftStyles: {
    "& .MuiInputBase-root": {
      display: "flex",
      flexDirection: "row-reverse",
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px",
    },
  },
  datePickerRightStyles: {
    "& .MuiInputAdornment-root": {
      marginLeft: "0px",
    },
  },
  bottomMargin: {
    marginBottom: "30px !important",
  },
  root: {
    "& .MuiInputBase-root": {
      caretColor: "transparent",
      cursor: "pointer",
    },
    "& label": {
      top: "-4px",
      fontSize: "14px",
      transform: "translate(14px, 14px) scale(1)",
    },
    "&:hover label": {
      color: `${getColor("primary", 700)}`,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -4px) scale(0.86)",
    },
    "& label.Mui-focused": {
      color: `${getColor("primary", 700)}`,
    },
    "& .MuiInputLabel-root.Mui-disabled": {
      color: `${getColor("primary", 700)} !important`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: getColor("primary", 700),
      transition: "none",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: getColor("primary", 700),
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${getColor("primary", 700)}`,
      },
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& fieldset": {
        borderColor: `${getColor("primary", 700)} !important`,
      },
      "& input": {
        "-webkit-text-fill-color": getColor("border", 200),
      },
      backgroundColor: getColor("background", 50),
    },
  },
});

export type DateFormat =
  | "dd/MM/yyyy"
  | "MM/dd/yyyy"
  | "yyyy/MM/dd"
  | "yyyy/dd/MM"
  | "dd MMM yyyy";

export type Mask = "__/__/____" | "____/__/__";

export type CalenderIconAlign = "left" | "right";

export type DatePickerProps = {
  placeholder?: string;
  iconColor?: string;
  defaultValue?: null | string | Date;
  mask?: string;
  dateFormat?: DateFormat;
  onChange?: (date: any) => void;
  alignCalenderIcon?: CalenderIconAlign;
  required?: boolean;
  id?: string;
  name?: string;
  form?: any;
  value?: any;
  error?: boolean;
  disabled?: boolean;
  index?: number;
  fieldArray?: boolean;
  formName?: string;
  fieldName?: string;
  minDate?: any;
  maxDate?: any;
  height?: string | number;
  defaultCursor?: boolean;
  showLabel?: boolean;
  helperText?: any;
  avoidDatePicker?: boolean;
  ref?: any;
  openAdvancedFilter?: Function | null;
  touched?: any;
  views?: Array<"year" | "month" | "day">;
};
export const CustomDatePicker = ({
  placeholder = "Select",
  iconColor = getColor("primary", 700),
  defaultValue = null,
  mask = "__/__/____",
  dateFormat = "dd/MM/yyyy",
  onChange,
  alignCalenderIcon = "left",
  name = "",
  id = "",
  value = null,
  error,

  form,
  required,
  disabled = false,
  fieldArray = false,
  fieldName = "",
  index = 0,
  formName = "",
  minDate,
  maxDate,
  height,
  defaultCursor = false,
  showLabel = true,
  helperText,
  avoidDatePicker = false,
  openAdvancedFilter = null,
  ref = null,
  touched,
  views = ["year", "month", "day"],
}: DatePickerProps) => {
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleDateChange = (selectedDate: any) => {
    const value = selectedDate
      ? moment(selectedDate).format("YYYY-MM-DD")
      : null;
    if (form) {
      // if (fieldArray) {
      //   const newTouched = form.touched[formName] ?? [];
      //   newTouched[index] = { ...(newTouched[index] ?? {}), [fieldName]: true };
      //   form.setTouched({ ...form.touched, [formName]: newTouched }, true);
      // } else {
      //   form?.setTouched({ ...form.touched, [id]: true }, true);
      // }
      // form?.setFieldValue(id, value);
    }
    onChange && onChange(value);
  };

  const classes = useStyles();

  const isValidDate = (date: any) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  return (
    <Box
      className={` ${classes.root} ${
        alignCalenderIcon === "left"
          ? classes.datePickerLeftStyles
          : classes.datePickerRightStyles
      }  ${invalidDate && classes.bottomMargin} `}
      sx={{
        "& .MuiInputBase-root": {
          height: height ? height : 40,
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MUIDatePicker
          format={dateFormat}
          value={
            isValidDate(value)
              ? typeof value === "string"
                ? new Date(value)
                : value
              : null
          }
          onChange={handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          views={views}
          onClose={() => setDatePickerOpen(false)}
          open={datePickerOpen}
          slots={{ textField: TextField }}
          slotProps={{
            textField: {
              id,
              name,
              label: showLabel ? placeholder : "",
              required,
              helperText: error ? helperText : "",
              error: error,
              onClick: () => setDatePickerOpen(true),
              onKeyDown: (e: React.KeyboardEvent) => {
                if (e.code === "Backspace") handleDateChange(null);
              },
              fullWidth: true,
            },
            inputAdornment: {
              onClick: () => setDatePickerOpen(!datePickerOpen),
            },
          }}
        />
      </LocalizationProvider>
      {error && (
        <FormHelperText error={true} sx={{ ml: 1.5 }}>
          {error}
        </FormHelperText>
      )}
      {helperText && (
        <Typography
          variant="caption_regular"
          sx={{ position: "relative", top: "5px" }}
        >
          <span style={{ color: getColor("neutral", 600) }}>{helperText}</span>
        </Typography>
      )}
    </Box>
  );
};

export function DatePicker(props: any) {
  return (
    <LVThemeProvider>
      <Field component={CustomDatePicker} {...props} />
    </LVThemeProvider>
  );
}
