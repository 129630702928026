export const startupStageOptions = [
  { label: "Ideation", value: 1 },
  { label: "Proof of Concept", value: 2 },
  { label: "Beta Launched", value: 3 },
  { label: "Early Revenues", value: 4 },
  { label: "Steady Revenues", value: 5 },
];

export const bussinessTypeOptions = [
  { label: "B2B", value: "B2B" },
  { label: "B2C", value: "B2C" },
  { label: "D2C", value: "D2C" },
  { label: "P2P", value: "P2P" },
  { label: "B2G", value: "B2G" },
  { label: "B2B2C", value: "B2B2C" },
  { label: "B2B2G", value: "B2B2G" },
];
