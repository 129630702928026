import React, { FC, useContext, useState } from "react";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { Box, Grid } from "@mui/material";
import StartupManagement from "../../managers/startupManager";
import { AuthContext } from "../../../../contexts/authContext";
import { toast } from "react-toastify";
import {
  CustomTextField,
  InputField,
} from "../../../../ui-library/form-elements/CustomTextField";
import { Formik } from "formik";

type VerifyModalType = {
  isOpen: any;
  handleClose?: () => void;
  handleSubmit?: () => void;
};

const RejectModal: FC<VerifyModalType> = (props) => {
  const { isOpen, handleClose, handleSubmit } = props;
  const [rejectReason, setRejectReason] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);

  const handleReject = async (id: any) => {
    try {
      setSubmitting(true);
      const result = await startupManagement.finalScreeningAction(
        isOpen,
        false,
        rejectReason
      );
      toast.success(result?.detail);
      setSubmitting(false);
      handleSubmit && handleSubmit();
    } catch (e: any) {
      setSubmitting(false);
      toast.error(e?.detail);
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleReject}
      isLoading={submitting}
      disableSubmitButton={submitting}
      title="Reject Startup"
      submitText="&nbsp;&nbsp;Yes&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;No&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
      closeIcon
    >
      <Box width="40vw">
        <Grid container gap={2}>
          <Box width="100%">
            <Grid item>
              Are you sure you want to reject this startup from final screening?
            </Grid>
            <br />

            <CustomTextField
              label="Reject Reason"
              value={rejectReason}
              onChange={(value: any) => setRejectReason(value)}
              multiline
              minRows={4}
            />
          </Box>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default RejectModal;
