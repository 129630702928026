import { FC, useContext, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { InputField } from "../../../../../ui-library/form-elements/CustomTextField";
import { FileUpload } from "../../../../../ui-library/form-elements/file-upload/FileUpload";
import { Dropdown } from "../../../../../ui-library/dropdowns/core-dropdown";
import { AuthContext } from "../../../../../contexts/authContext";
import { useStyles } from "../../dealDocuments/styles";
import { toast } from "react-toastify";
import {
  DocumentTypeNames,
  InvestorDocumentNameOptions,
  QuarterOptions,
} from "../../../constants/documentConstants";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";
import UserManagementManager from "../../../../userManagement/managers/userManagementManager";
import DocumentManagement from "../../../managers/documentManager";
import { Formik } from "formik";
import * as Yup from "yup";
import { OutlinedButton } from "../../../../../ui-library/buttons/OutlinedButton";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import { DatePicker } from "../../../../../ui-library/form-elements/DatePicker";

type ReactUploadInvestorDocumentModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
};

const UploadInvestorDocumentModal: FC<ReactUploadInvestorDocumentModalType> = (
  props
) => {
  const { isOpen, handleClose } = props;
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const documentManagementManager = new DocumentManagement(authContext);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    selectedInvestor: "",
    selectedInvestmentProfile: "",
    documentType: null,
    documentName: "",
    year: "",
    quarter: "",
    document: [],
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    selectedInvestor: Yup.object().required("Investor is required"),
    selectedInvestmentProfile: Yup.object().required(
      "Investment Profile is required"
    ),
    documentName: Yup.object().required("Document Type is required"),
    documentType: Yup.object().when("documentName", {
      is: (val: any) => val?.value === "Onboarding",
      then: (schema: any) => schema.required("Document Name is required"),
      otherwise: (schema: any) => schema.notRequired(),
    }),
    year: Yup.date().when("documentName", {
      is: (val: any) => val?.value !== "Onboarding",
      then: (schema: any) => schema.required("Year is required"),
      otherwise: (schema: any) => schema.notRequired(),
    }),
    quarter: Yup.object().when("documentName", {
      is: (val: any) => val?.value === "Unit_Statements",
      then: (schema: any) => schema.required("Quarter is required"),
      otherwise: (schema: any) => schema.notRequired(),
    }),
    document: Yup.array().min(1).required("File is required"),
  });

  const [nameEmailOptions, setNameEmailOptions] = useState<any>([]);
  const [investmentProfileOptions, setInvestmentProfileOptions] = useState<any>(
    []
  );

  const DocumentCategoryOptionsExtended = [...DocumentTypeNames];

  const handleInvestorSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "only_investor_users",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setNameEmailOptions(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const handleProfileSearch = async (
    value: any,
    setFieldValue: (name: string, value: any) => void
  ) => {
    try {
      if (!value?.value) return;
      let result = await userManagementManager.getInvestmentProfile(
        value?.value
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setInvestmentProfileOptions(result);
      setFieldValue("selectedInvestmentProfile", result[0]);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);

      const result = await documentManagementManager.uploadDocument(values);
      toast.success("Document Uploaded Successfully");
      setIsLoading(false);
      handleClose();
    } catch (error: any) {
      toast.error(error.message ?? "Something Went Wrong");
      setIsLoading(false);
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      hideButtons
      handleClose={handleClose}
      title="Upload Investor Document"
      submitText="&nbsp;&nbsp;Submit&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Close&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
      closeIcon
    >
      <Box width="40vw">
        {
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              handleSubmit,
              touched,
            }: any) => (
              <Box>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div
                        // className={classes.fieldItem}
                        style={{ marginTop: "5px" }}
                      >
                        <LvAutoComplete
                          label="Investor Name or Email"
                          options={nameEmailOptions}
                          setOptions={setNameEmailOptions}
                          selectedObject={values.selectedInvestor}
                          setSelectedObject={(value: any) =>
                            setFieldValue("selectedInvestor", value)
                          }
                          onChange={handleInvestorSearch}
                          onSelect={(value: any) =>
                            handleProfileSearch(value, setFieldValue)
                          }
                          required
                          error={
                            errors.selectedInvestor && touched.selectedInvestor
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div
                        // className={classes.fieldItem}
                        style={{ marginTop: "5px" }}
                      >
                        <LvAutoComplete
                          label="Investment Profile"
                          options={investmentProfileOptions}
                          setOptions={setInvestmentProfileOptions}
                          selectedObject={values.selectedInvestmentProfile}
                          disabled
                          setSelectedObject={(value: any) =>
                            setFieldValue("selectedInvestmentProfile", value)
                          }
                          required
                          error={
                            errors.selectedInvestmentProfile &&
                            touched.selectedInvestmentProfile
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Dropdown
                        label="Choose Document Type"
                        options={DocumentCategoryOptionsExtended}
                        value={values.documentName}
                        id="documentName"
                        name="documentName"
                        fieldName="documentName"
                        required
                        error={
                          errors.documentName && touched.documentName
                            ? errors.documentName
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>

                {values?.documentName?.value === "Onboarding" ? (
                  <Box mt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Dropdown
                          label="Choose Document Name"
                          options={InvestorDocumentNameOptions}
                          value={values.documentType}
                          id="documentType"
                          name="documentType"
                          fieldName="documentType"
                          required
                          error={
                            errors.documentType && touched.documentType
                              ? errors.documentType
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <>
                    <Box mt={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <DatePicker
                            placeholder="Choose Year"
                            id="year"
                            name="year"
                            fieldName="year"
                            value={values?.year}
                            onChange={(value: any) =>
                              setFieldValue("year", value)
                            }
                            required
                            alignCalenderIcon="right"
                            views={["year"]}
                            error={
                              errors.year && touched.year ? errors.year : ""
                            }
                            touched={touched}
                            minDate={new Date("2020-01-01")}
                            maxDate={new Date()}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    {values?.documentName?.value === "Unit_Statements" && (
                      <Box mt={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Dropdown
                              id="quarter"
                              name="quarter"
                              fieldName="quarter"
                              label="Choose Quarter"
                              options={QuarterOptions}
                              value={values?.quarter}
                              required
                              error={
                                errors.quarter && touched.quarter
                                  ? errors.quarter
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </>
                )}

                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FileUpload
                        width={"100%"}
                        placeholder="select a file*"
                        helperText="You can upload a pdf, jpg, jpeg, doc or docx file only"
                        selectedFile={(file: any) =>
                          setFieldValue("document", file)
                        }
                        value={values?.document}
                        accept={{
                          "document/file": [
                            ".pdf",
                            ".jpg",
                            ".jpeg",
                            ".doc",
                            ".docx",
                          ],
                        }}
                        title="File Upload Dialog Box"
                        hideDocumentVault
                        error={!!(errors.document && touched.document)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputField
                        label="Notes"
                        width="100%"
                        placeholder="Notes"
                        id="notes"
                        fieldName="notes"
                        name="notes"
                        multiline
                        minRows={4}
                        value={values.notes}
                        error={errors.notes && touched.notes}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Stack mt={3} gap={2} direction="row" justifyContent="flex-end">
                  <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
                  <ActionButton
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={handleSubmit}
                  >
                    Submit
                  </ActionButton>
                </Stack>
              </Box>
            )}
          </Formik>
        }
      </Box>
    </CommonModal>
  );
};

export default UploadInvestorDocumentModal;
