import { FC, useState, useMemo, useContext, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { FormContainer } from "../../../../../ui-library/form-elements/FormContainer";
import { AuthContext } from "../../../../../contexts/authContext";
import UserManagementManager from "../../../managers/userManagementManager";
import { toast } from "react-toastify";
import { ToggleSwitch } from "../../../../../ui-library/buttons/ToggleSwitch";
import { getChangedValues } from "../../../../../utils/formUtils";
import { InputField } from "../../../../../ui-library/form-elements/CustomTextField";
import * as Yup from "yup";

type QuizParams = {
  user_id: string;
};

const AccessDetailsEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { detail } = location.state;
  const { user_id = "" } = useParams<QuizParams>();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const userManagementManager = new UserManagementManager(authContext);
  const initialValues = useMemo(
    () => ({
      activated: detail?.activated ?? false,
      deactivateNote: "",
      published: detail?.published ?? false,
      isBlocked: !detail?.is_blocked,
      privateSyndicateInvestorLandingPage:
        !detail?.backer_has_fass_no_platform_access,
    }),
    [detail]
  );

  const validationSchema = Yup.object().shape({
    deactivateNote: Yup.string().when("activated", {
      is: false,
      then: (schema) => schema.required("Deactivate Note is required"),
      otherwise: (schema) => schema,
    }),
  });

  const EditBreadCrumbData = useMemo(
    () => ({
      stepKey: 3,
      breadcrumbSeparator: true,
      steps: [
        { title: "User Management", titleDisplay: true },
        { title: "Access Details", titleDisplay: true },
        {
          title: "Edit Access Details",
          titleDisplay: true,
        },
      ],
      pageHeader: {
        header: detail?.name ?? "Edit Access Details",
        navigationButton: true,
        navigationFunction: () =>
          navigate(`/user/user-panel/${user_id}/`, {
            state: { selectedTab: "access_details" },
          }),
      },
    }),
    [navigate]
  );

  const handleAccessDetailSubmit = async () => {
    try {
      setSubmitting(true);
      const newValues = getChangedValues(
        isConfirmationModalOpen,
        initialValues
      );
      if ("isBlocked" in newValues) {
        newValues.isBlocked = !newValues?.isBlocked;
      }

      await userManagementManager.updateUserData(user_id, newValues);
      toast.success("User access details submitted successfully");
      navigate(`/user/user-panel/${user_id}/`, {
        state: { selectedTab: "access_details" },
      });
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      toast.error("Failed to submit user access details");
    }
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={5}>
          <Breadcrumb
            steps={EditBreadCrumbData.steps}
            pageHeader={EditBreadCrumbData.pageHeader}
            stepKey={EditBreadCrumbData.stepKey}
            breadcrumbSeparator
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any) => setIsConfirmationModalOpen(values)}
      >
        {({ values, errors, setFieldValue, handleSubmit, dirty }) => (
          <FormContainer>
            <Stack gap={2}>
              <Stack alignItems="start">
                <ToggleSwitch
                  switchLabel="Activated"
                  showYesOrNo
                  switchLabelPositon="start"
                  defaultChecked={values.activated}
                  labelSwitchDistance="20px"
                  onSwitchToggle={(value: boolean) =>
                    setFieldValue("activated", value)
                  }
                />
              </Stack>
              {!values.activated && detail?.activated ? (
                <InputField
                  name="deactivateNote"
                  id="deactivateNote"
                  fieldName="deactivateNote"
                  label="Deactivate Note"
                  multiline
                  minRows={4}
                  value={values.deactivateNote}
                  error={!!errors.deactivateNote}
                />
              ) : null}
              <Stack alignItems="start">
                <ToggleSwitch
                  switchLabel="Published"
                  showYesOrNo
                  switchLabelPositon="start"
                  defaultChecked={values.published}
                  labelSwitchDistance="20px"
                  onSwitchToggle={(value: boolean) =>
                    setFieldValue("published", value)
                  }
                />
              </Stack>
              <Stack alignItems="start">
                <ToggleSwitch
                  switchLabel="Access for Deals/Feed/Syndicate"
                  showYesOrNo
                  switchLabelPositon="start"
                  defaultChecked={values.isBlocked}
                  labelSwitchDistance="20px"
                  onSwitchToggle={(value: boolean) =>
                    setFieldValue("isBlocked", value)
                  }
                />
              </Stack>
              <Stack alignItems="start">
                <ToggleSwitch
                  switchLabel="Private Syndicate Investor Landing Page"
                  showYesOrNo
                  switchLabelPositon="start"
                  defaultChecked={values.privateSyndicateInvestorLandingPage}
                  labelSwitchDistance="20px"
                  onSwitchToggle={(value: boolean) =>
                    setFieldValue("privateSyndicateInvestorLandingPage", value)
                  }
                />
              </Stack>
              <Stack direction="row" justifyContent="end" mt={2}>
                <ActionButton disabled={!dirty} onClick={handleSubmit}>
                  Submit
                </ActionButton>
              </Stack>
            </Stack>
          </FormContainer>
        )}
      </Formik>
      <CommonModal
        isOpen={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleSubmit={handleAccessDetailSubmit}
        isLoading={submitting}
        disableSubmitButton={submitting}
        title="Are you sure?"
        submitText="Submit"
        buttonStyles={{ width: "100%" }}
        closeIcon
        hideCancelButton
      >
        <Stack>Are you sure you want to submit the changes?</Stack>
      </CommonModal>
    </Stack>
  );
};

export default AccessDetailsEdit;
