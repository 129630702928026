import { FC, useState, useMemo, useContext, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { FormContainer } from "../../../../../ui-library/form-elements/FormContainer";
import { AuthContext } from "../../../../../contexts/authContext";
import UserManagementManager from "../../../managers/userManagementManager";
import { InputField } from "../../../../../ui-library/form-elements/CustomTextField";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";
import { toast } from "react-toastify";
import { Dropdown } from "../../../../../ui-library/dropdowns/core-dropdown";
import { ToggleSwitch } from "../../../../../ui-library/buttons/ToggleSwitch";
import { getChangedValues } from "../../../../../utils/formUtils";
import { FormPlaceholder } from "../../../../../ui-library/placeholders/form-placeholder/FormPlaceholder";
import { formPlaceholderConfig } from "../../../constants/USER_PANEL_CONSTANTS";

type BasicDetailsEditType = {};
type QuizParams = {
  user_id: string;
};

const BasicUserDetailsEdit: FC<BasicDetailsEditType> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { detail } = location.state;
  const { user_id = "" } = useParams<QuizParams>();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const userManagementManager = new UserManagementManager(authContext);
  const initialValues = useMemo(
    () => ({
      email: detail?.email ?? "",
      hashtags: detail?.private_hashtag_tokens?.length
        ? detail?.private_hashtag_tokens?.map((item: any) => ({
            label: item?.name,
            value: item?.id,
          }))
        : [],

      backerOfPrivateSyndicate: detail?.backer_private_syndicate?.length
        ? detail?.backer_private_syndicate?.map((item: any) => ({
            label: item?.syndicate_name,
            value: item?.id,
          }))
        : [],
      onboardingFee: detail?.investor_ca_onboarding_info?.onboarding_fee ?? "",
      upfrontDrawdown:
        detail?.investor_ca_onboarding_info?.upfront_drawdown ?? "",
      placementFee: detail?.investor_ca_onboarding_info?.placement_fee ?? "",
      isFaas: detail?.is_faas ?? false,
      isLeadInvester: detail?.is_lead_investor ?? false,
    }),
    [detail]
  );

  const [hashtagOptions, setHashtagOptions] = useState<any>([]);
  const [syndicateOptions, setSyndicateOptions] = useState<any>([]);

  const EditBreadCrumbData = useMemo(
    () => ({
      stepKey: 3,
      breadcrumbSeparator: true,
      steps: [
        { title: "User Management", titleDisplay: true },
        { title: "Basic Details", titleDisplay: true },
        { title: "Edit Basic Details", titleDisplay: true },
      ],
      pageHeader: {
        header: detail?.name ?? "Edit User Details",
        navigationButton: true,
        navigationFunction: () =>
          navigate(`/user/user-panel/${user_id}/`, {
            state: { selectedTab: "basic_details" },
          }),
      },
    }),
    [navigate]
  );

  const getHashTagOptions = async () => {
    try {
      let result = await userManagementManager.getLookupData({
        search: "",
        type: "select_user_hashtags",
      });

      result = result?.private_hashtags.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setHashtagOptions(result);
    } catch (err) {
      toast.error("Error while fetching hashtag options");
    }
  };

  const getPvtSyndOptions = async () => {
    try {
      let result = await userManagementManager.getLookupData({
        search: "",
        type: "private_syndicate",
      });

      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setSyndicateOptions(result);
    } catch (err) {
      toast.error("Error while fetching hashtag options");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([getHashTagOptions(), getPvtSyndOptions()])
      .then((res) => setIsLoading(false))
      .catch((e) => {
        toast.error("Failed to load options");
        setIsLoading(false);
      });
  }, []);

  const handleStartupDetailsSubmit = async () => {
    try {
      setSubmitting(true);
      const newValues = getChangedValues(
        isConfirmationModalOpen,
        initialValues
      );

      if ("backerOfPrivateSyndicate" in newValues) {
        const removedPvtSynd = initialValues?.backerOfPrivateSyndicate.filter(
          ({ value: id1 }: any) =>
            !newValues?.backerOfPrivateSyndicate.some(
              ({ value: id2 }: any) => id2 === id1
            )
        );
        const addedPvtSynd = newValues?.backerOfPrivateSyndicate.filter(
          ({ value: id1 }: any) =>
            !initialValues?.backerOfPrivateSyndicate.some(
              ({ value: id2 }: any) => id2 === id1
            )
        );
        newValues.BackerOfPrivateSyndicateAdded = addedPvtSynd;
        newValues.backerOfPrivateSyndicateRemoved = removedPvtSynd;
      }

      await userManagementManager.updateUserData(user_id, newValues);
      toast.success("User details submitted successfully");
      navigate(`/user/user-panel/${user_id}/`, {
        state: { selectedTab: "basic_details" },
      });
      setSubmitting(false);
    } catch (error) {
      toast.error("Error while submitting the user details");
      setSubmitting(false);
    }
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={5}>
          <Breadcrumb
            steps={EditBreadCrumbData.steps}
            pageHeader={EditBreadCrumbData.pageHeader}
            stepKey={EditBreadCrumbData.stepKey}
            breadcrumbSeparator
          />
        </Grid>
      </Grid>

      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => setIsConfirmationModalOpen(values)}
      >
        {({ values, errors, setFieldValue, handleSubmit, dirty }) => (
          <FormContainer>
            {isLoading ? (
              <FormPlaceholder elementsConfig={formPlaceholderConfig} />
            ) : (
              <Stack gap={2}>
                <InputField
                  label="Email"
                  error={errors?.email}
                  id="name"
                  name="name"
                  formName="name"
                  value={values?.email}
                  required
                />
                <LvAutoComplete
                  multiple
                  creatable
                  label="Admin Hashtags"
                  options={hashtagOptions}
                  setOptions={setHashtagOptions}
                  selectedObject={values?.hashtags}
                  setSelectedObject={(value: any) =>
                    setFieldValue("hashtags", value)
                  }
                  helperText="Choose or add new hashtags"
                />

                <Dropdown
                  id="backerOfPrivateSyndicate"
                  name="backerOfPrivateSyndicate"
                  fieldName="backerOfPrivateSyndicate"
                  label="Backer of Private Syndicate"
                  options={syndicateOptions}
                  value={values.backerOfPrivateSyndicate}
                  error={!!errors.backerOfPrivateSyndicate}
                  multiple
                />

                <InputField
                  label="Onboarding Fee"
                  error={errors?.onboardingFee}
                  id="onboardingFee"
                  name="onboardingFee"
                  formName="onboardingFee"
                  value={values?.onboardingFee}
                  required
                />
                <InputField
                  label="Upfront Drawdown"
                  error={errors?.upfrontDrawdown}
                  id="upfrontDrawdown"
                  name="upfrontDrawdown"
                  formName="upfrontDrawdown"
                  value={values?.upfrontDrawdown}
                  required
                />
                <InputField
                  label="Placement Fee"
                  error={errors?.placementFee}
                  id="placementFee"
                  name="placementFee"
                  formName="placementFee"
                  value={values?.placementFee}
                  required
                />

                <Stack alignItems="start">
                  <ToggleSwitch
                    switchLabel="Is Faas"
                    showYesOrNo
                    switchLabelPositon="start"
                    defaultChecked={values.isFaas}
                    labelSwitchDistance="20px"
                    onSwitchToggle={(value: boolean) =>
                      setFieldValue("isFaas", value)
                    }
                  />
                </Stack>

                <Stack alignItems="start">
                  <ToggleSwitch
                    switchLabel="Is Lead Investor"
                    showYesOrNo
                    switchLabelPositon="start"
                    defaultChecked={values.isLeadInvester}
                    labelSwitchDistance="20px"
                    onSwitchToggle={(value: boolean) =>
                      setFieldValue("isLeadInvester", value)
                    }
                  />
                </Stack>

                <Stack direction="row" justifyContent="end" mt={2}>
                  <ActionButton disabled={!dirty} onClick={handleSubmit}>
                    Submit
                  </ActionButton>
                </Stack>
              </Stack>
            )}
          </FormContainer>
        )}
      </Formik>

      <CommonModal
        isOpen={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleSubmit={handleStartupDetailsSubmit}
        isLoading={submitting}
        disableSubmitButton={submitting}
        title="Are you sure?"
        submitText="Submit"
        buttonStyles={{ width: "100%" }}
        closeIcon
        hideCancelButton
      >
        <Stack>Are you sure you want to submit the changes?</Stack>
      </CommonModal>
    </Stack>
  );
};

export default BasicUserDetailsEdit;
