import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "./App.css";
import { Auth0Provider, AppState } from "@auth0/auth0-react";
import { PermissionContextProvider } from "./contexts/permissionContext";
import { BrowserRouter } from "react-router-dom";
import ThemeContext from "./ui-library/theme/ThemeContext";

const firebaseConfig = {
  apiKey: "AIzaSyBHrdXpZqqtiUyoJ3lF_wq1m-jhddi3psw",
  authDomain: "letsventure-admin-portal.firebaseapp.com",
  projectId: "letsventure-admin-portal",
  storageBucket: "letsventure-admin-portal.appspot.com",
  messagingSenderId: "546821536794",
  appId: "1:546821536794:web:26f139223a3f38dabb65a3",
  measurementId: "G-9MFB0RY7GT",
};

const domain: any = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId: any = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience: any = process.env.REACT_APP_AUTH0_AUDIENCE;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const onRedirectCallback = (appState?: AppState) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

root.render(
  <BrowserRouter>
    <ThemeContext>
      <PermissionContextProvider>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          authorizationParams={{
            audience: audience,
            redirect_uri: `${window.location.origin}/callback`,
          }}
          onRedirectCallback={onRedirectCallback}
          useRefreshTokens
          useRefreshTokensFallback
          cacheLocation="localstorage"
        >
          <App />
        </Auth0Provider>
      </PermissionContextProvider>
    </ThemeContext>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
