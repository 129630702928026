import React, { FC } from "react";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { Box, Grid, Typography } from "@mui/material";

type VerifyModalType = {
  isOpen: any;
  handleClose?: () => void;
  emailData?: any;
};

const EmailContentModal: FC<VerifyModalType> = (props) => {
  const { isOpen, handleClose, emailData } = props;
  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleClose}
      hideCancelButton
      title={
        <Typography
          variant="heading_07_bold"
          component={"span"}
          dangerouslySetInnerHTML={{
            __html: emailData?.subject,
          }}
        />
      }
      submitText="&nbsp;&nbsp;Okay&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
      closeIcon
    >
      <Box width="35vw">
        <Grid container gap={2}>
          <Box width="100%">
            <Grid item></Grid>
            <Grid item>
              <Typography
                variant="paragraph_02_medium"
                component={"span"}
                dangerouslySetInnerHTML={{
                  __html: emailData?.content,
                }}
              />
            </Grid>
          </Box>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default EmailContentModal;
