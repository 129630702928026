import React from "react";
import QueryEditor from "./Editor";
import ResultsTable from "./ResultTable";
import QueryEditorManager from "../../managers/queryEditorManager";
import { Stack } from "@mui/material";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { toast } from "react-toastify";

const Index = () => {
  const [results, setResults] = React.useState([]);
  const queryEditorManager = new QueryEditorManager();
  const [fetchingData, fetchingDataLoader] = React.useState(false);

  const handleExecute: any = async (query: string) => {
    try {
      fetchingDataLoader(true);
      const queryResults = await queryEditorManager.executeQuery(query);
      if (queryResults.message) {
        toast.info(queryResults.message);
        setResults([]);
      } else {
        setResults(queryResults?.data || []);
        toast.success("Query executed successfully!");
      }
      fetchingDataLoader(false);
    } catch (error) {
      fetchingDataLoader(false);
    }
  };

  const handleClear = () => {
    setResults([]);
  };

  const handleUpdate = (
    rowIndex: number,
    columnName: string,
    value: string
  ) => {
    setResults((prev: any) =>
      prev.map((row: any, index: any) =>
        index === rowIndex ? { ...row, [columnName]: value } : row
      )
    );
  };

  return (
    <Stack>
      <QueryEditor onExecute={handleExecute} onClear={handleClear} />
      {fetchingData ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <ResultsTable data={results} onUpdate={handleUpdate} />
      )}
    </Stack>
  );
};

export default Index;
