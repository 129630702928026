import { AuthContextProps, UserAuthInfo } from "../../../contexts/authContext";

import {
  getSyndicateDealDocuments,
  createSyndicate,
  updateSyndicate,
  createStartup,
  getSyndicateDetail,
  deleteSyndicateLeadCarry,
  addSyndicateLeadCarry,
} from "../services/syndicateManagementService";

export default class SyndicateManagement {
  private userData: UserAuthInfo;

  constructor(AuthContext: AuthContextProps) {
    this.userData = AuthContext.authState;
  }

  async getSyndicateDealDocuments(page: number) {
    try {
      let result = await getSyndicateDealDocuments(page);
      const formattedData = result?.data?.results?.map(
        (item: any, index: number) => ({
          ...item,
          sno: index + 1,
          dpiit_file: item?.dpiit_file ? "Yes" : "No",
        })
      );
      return { result: formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  private prepareSyndicateFormData(
    data: any,
    selectedInvestor: any,
    selectedMailList: any
  ) {
    const formData = new FormData();
    formData.append("syndicate_name", data.syndicateName);
    formData.append("investor_user_id", selectedInvestor.value);
    formData.append("syndicate_type", data.syndicateType.value);
    if (selectedMailList?.value) {
      formData.append("mailer_list_id", selectedMailList?.value);
    }
    if (data.syndicateType.value === "zeno") {
      formData.append("syndicate_corpus", data.maxCorpus);
      formData.append("pre_commit_tenure", data.preCommitTenure);
      formData.append("management_fee", data.managementFee);
      formData.append("no_of_drawdowns", data.numberOfDrawdowns);
      formData.append("drawdown_interval", data.drawDownIntervals);
      formData.append("lv_carry", data.LvCarry);
      formData.append("hurdle_rate", data.hurdleRate);
    }
    if (data.syndicateType.value === "normal") {
      formData.append("header", data.syndicateHeader);
      formData.append("description", data.aboutSyndicate);
      formData.append("investment_thesis", data.syndicateInvestmentThesis);
      formData.append("min_typical_investment", data.minInvestment);
      formData.append("max_typical_investment", data.maxInvestment);
      formData.append("deal_per_year", data.syndicateDealPerYear);
      if (
        data?.syndicateProfileImage?.length &&
        data?.syndicateProfileImage?.[0]?.name
      ) {
        formData.append("syndicate_profile_img", data.syndicateProfileImage[0]);
      }
      if (
        data?.syndicateCoverImage.length &&
        data?.syndicateCoverImage?.[0]?.name
      ) {
        formData.append("syndicate_cover_img", data.syndicateCoverImage[0]);
      }
    }
    return formData;
  }

  async createSyndicate(
    data: any,
    selectedInvestor: any,
    selectedMailList: any
  ) {
    try {
      const formData = this.prepareSyndicateFormData(
        data,
        selectedInvestor,
        selectedMailList
      );
      let result = await createSyndicate(formData);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateSyndicate(
    data: any,
    selectedInvestor: any,
    selectedMailList: any,
    permalink: any
  ) {
    try {
      const formData = this.prepareSyndicateFormData(
        data,
        selectedInvestor,
        selectedMailList
      );
      let result = await updateSyndicate(formData, permalink);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getSyndicateDetail(id: string) {
    try {
      let result = await getSyndicateDetail(id);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteSyndicateLeadCarry(syndicate_id: string, investor_id: string) {
    try {
      let result = await deleteSyndicateLeadCarry(syndicate_id, investor_id);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async addSyndicateLeadCarry(
    selected_syn_id: string,
    lead_user_id: string,
    lead_carry: number
  ) {
    try {
      const data = {
        selected_syn_id,
        lead_user_id,
        lead_carry,
      };
      let result = await addSyndicateLeadCarry(data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async createStartup(data: any, selectedLocation: any) {
    try {
      const formData = new FormData();

      formData.append("startup_name", data.startupName);
      formData.append("permalink", data.startupPermalink);
      formData.append("product_summary", data.summary);
      if (data?.businessType?.value)
        formData.append("startup_business_type", data?.businessType?.value);
      if (data?.startupStage?.value)
        formData.append("startup_stage", data?.startupStage?.value);
      formData.append("funding_amount", data.amount);
      if (selectedLocation?.value)
        formData.append("startup_location_tokens", selectedLocation?.value);
      formData.append("summary", data.twitterPitch);
      if (data?.pitchDeck?.length && data?.pitchDeck?.[0]?.name)
        formData.append("startup_pitch_deck", data?.pitchDeck[0]);

      let result = await createStartup(formData);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }
}
