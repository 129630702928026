import React, { useEffect, useState } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import AifManagementManager from "../../managers/aifManagementManagers";
import { toast } from "react-toastify";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { NoDataAvailable } from "../../../../ui-library/error/NoDataAvailable";
import { IconConstant } from "../../../../constants/IconConstants";
import { Link } from "react-router-dom";
import { getColor } from "../../../../ui-library/Styles/Colors";

const PaidPayments = () => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const aifManagementManager = new AifManagementManager();

  const getPaymentDetails = async (currentPage = 1) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await aifManagementManager.getForeignInvestorPayments(
          "paid",
          currentPage
        );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getPaymentDetails(page);
  }, [page]);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  let columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      headerAlign: "left",
      //   renderCell: (params: any) => {
      //     return params?.row?.name ? (
      //       <Link
      //         to={`/user/user-panel/${params.row?.user}/`}
      //         style={{ color: getColor("action_button", 300) }}
      //       >
      //         {params?.row?.name}
      //       </Link>
      //     ) : (
      //       "-"
      //     );
      //   },
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",
      minWidth: 300,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      headerAlign: "left",
      minWidth: 150,
    },
    {
      field: "transaction_reference",
      headerName: "Transaction Reference",
      headerAlign: "left",
      sortable: false,
      minWidth: 250,
    },
    {
      field: "payment_confirmation_date",
      headerName: "Payment Date",
      sortable: false,
      headerAlign: "right",
      align: "right",
      minWidth: 130,
    },

    {
      field: "amount",
      headerName: "Amount",
      align: "right",
      headerAlign: "right",
      minWidth: 150,
    },
    {
      field: "note",
      headerName: "Note",
      align: "left",
      headerAlign: "left",
      minWidth: 150,
    },
    {
      field: "created_date",
      headerName: "Created Date",
      headerAlign: "right",
      align: "right",
      minWidth: 150,
    },
    {
      field: "last_updated_date",
      headerName: "Last Updated Date",
      headerAlign: "right",
      align: "right",
      minWidth: 180,
    },
  ];

  if (apiStatus === API_STATUS.ERROR) {
    return (
      <NoDataAvailable
        title="Error"
        subTitle={"Something went wrong"}
        image={IconConstant.DATA_NOT_FOUND_ICON}
      />
    );
  }

  return apiStatus == API_STATUS.LOADING ? (
    <TablePlaceholder
      rows={6}
      columns={6}
      rowHeight="40px"
      columnHeaderHeight="40px"
    />
  ) : (
    <div>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={40}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={20}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
      />
    </div>
  );
};

export default PaidPayments;
