import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { FormContainer } from "../../../../../../ui-library/form-elements/FormContainer";
import { InputAdornment, Stack, Typography } from "@mui/material";
import LvAutoComplete from "../../../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../../../ui-library/buttons/ActionButton";
import { AuthContext } from "../../../../../../contexts/authContext";
import UserManagementManager from "../../../../managers/userManagementManager";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CommonModal } from "../../../../../../ui-library/modals/common-modal";
import { TimePeriodDropdown } from "../../../../../../ui-library/dropdowns/time-period-dropdown";
import { getColor } from "../../../../../../ui-library/Styles/Colors";
import { UserDetailParams } from "../../../../constants/USER_PANEL_CONSTANTS";

const ModifyCommit = () => {
  const [startupOptions, setStartupOptions] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const navigate = useNavigate();
  const { user_id = "" } = useParams<UserDetailParams>();

  const initialValues = {
    modifyCommit: true,
    startupId: "",
    proposedAmount: "",
  };

  const handleSearchStartup = async (value: string) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "startups_commited_by_user",
        user_id,
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setStartupOptions(result);
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleStartupDetailsSubmit = async () => {
    try {
      setSubmitting(true);
      await userManagementManager.updateUserData(
        user_id,
        isConfirmationModalOpen
      );
      toast.success("Commitment modified successfully");
      navigate(-1);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      toast.error("Failed to modify commitment");
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => {
          setIsConfirmationModalOpen(values);
        }}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit, dirty }) => (
          <FormContainer>
            <Stack gap={2}>
              <LvAutoComplete
                selectedObject={values?.startupId}
                setSelectedObject={(value: any) =>
                  setFieldValue("startupId", value)
                }
                options={startupOptions}
                setOptions={setStartupOptions}
                onSelect={() => setStartupOptions([])}
                onChange={handleSearchStartup}
                label="Select Startup"
                required
              />
              <TimePeriodDropdown
                coreDropdownProps={{
                  label: "",
                  options: [{ label: "Lakhs", value: 100000 }],
                  defaultValue: { label: "Lakhs", value: 100000 },
                  id: "amountUnit",
                  name: "amountUnit",
                  disabled: true,
                  hideEndAdornment: true,
                }}
                customTextFieldProps={{
                  id: "proposedAmount",
                  name: "proposedAmount",
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography color={getColor("primary", 700)}>
                          ₹
                        </Typography>
                      </InputAdornment>
                    ),
                  },
                  value: values.proposedAmount,
                  label: "Amount",
                }}
                dropdownWidth="120px"
                error={errors?.proposedAmount}
                touched={touched?.proposedAmount}
              />
              <Stack direction="row" justifyContent="end" mt={2}>
                <ActionButton disabled={!dirty} onClick={handleSubmit}>
                  Submit
                </ActionButton>
              </Stack>
            </Stack>
          </FormContainer>
        )}
      </Formik>

      <CommonModal
        isOpen={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleSubmit={handleStartupDetailsSubmit}
        isLoading={submitting}
        disableSubmitButton={submitting}
        title="Are you sure?"
        submitText="Submit"
        buttonStyles={{ width: "100%" }}
        closeIcon
        hideCancelButton
      >
        <Stack>Are you sure you want to submit the changes?</Stack>
      </CommonModal>
    </>
  );
};

export default ModifyCommit;
