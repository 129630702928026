import { Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import { useState, useContext } from "react";
import { Dropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { FileUpload } from "../../../../ui-library/form-elements/file-upload/FileUpload";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../contexts/authContext";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import SyndicateManagement from "../../managers/syndicateManagementManager";
import { API_STATUS } from "../../../../constants/commonConstants";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TimePeriodDropdown } from "../../../../ui-library/dropdowns/time-period-dropdown";
import { getColor } from "../../../../ui-library/Styles/Colors";
import {
  syndicateFormValidationSchema,
  SyndicateTypeOptions,
} from "../../constants";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";

const SyndicateForm = ({
  data = null,
  isUpdate = false,
  resetUpdateForm,
}: any) => {
  const authUser = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authUser);
  const syndicateManagementManager = new SyndicateManagement(authUser);
  const [investorOptions, setInvestorOptions] = useState<any>([]);
  const [mailerOptions, setMailerOptions] = useState<any>([]);
  const [submitting, setSubmitting] = useState(API_STATUS.SUCCESS);

  const formSchema: any = syndicateFormValidationSchema;
  if (isUpdate) delete formSchema?.selectedInvestor;
  const validationScheme = Yup.object().shape(formSchema);

  const initialValues = data ?? {
    syndicateName: "",
    selectedInvestor: "",
    syndicateType: SyndicateTypeOptions[0],
    selectedMailer: "",
    syndicateHeader: "",
    aboutSyndicate: "",
    syndicateInvestmentThesis: "",
    minInvestment: "",
    maxInvestment: "",
    syndicateDealPerYear: "",
    syndicateProfileImage: [],
    syndicateCoverImage: [],
    maxCorpus: "",
    preCommitTenure: "",
    managementFee: "",
    numberOfDrawdowns: "",
    drawDownIntervals: "",
    drawDownIntervalsUnit: { label: "Months", value: 1 },
    LvCarry: "",
    hurdleRate: "",
  };

  const handleInvestorSearch = async (value: any) => {
    try {
      const result = await userManagementManager.getLookupData({
        search: value,
        type: "create_syndicate_investors",
      });
      setInvestorOptions(
        result?.results.map((item: any) => ({
          label: item.text,
          value: item.id,
        }))
      );
    } catch (err: any) {
      toast.error(err);
    }
  };

  const getMailersOptions = async (search: string) => {
    try {
      const result = await userManagementManager.getLookupData({
        search,
        type: "mailer_list",
      });
      setMailerOptions(
        result?.results.map((item: any) => ({
          label: item.text,
          value: item.id,
        }))
      );
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleSubmit = async (values: any, actions: any) => {
    try {
      setSubmitting(API_STATUS.LOADING);
      const result = isUpdate
        ? await syndicateManagementManager.updateSyndicate(
            values,
            values?.selectedInvestor,
            values?.selectedMailer,
            data?.syndicatePermalink
          )
        : await syndicateManagementManager.createSyndicate(
            values,
            values?.selectedInvestor,
            values?.selectedMailer
          );
      if (result) {
        setSubmitting(API_STATUS.SUCCESS);
        toast.success(result?.detail);
        if (isUpdate && resetUpdateForm) {
          resetUpdateForm();
        }
        actions.resetForm();
      }
    } catch (e: any) {
      setSubmitting(API_STATUS.ERROR);
      toast.error(e);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={validationScheme}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => {
          return (
            <FormContainer>
              <Form>
                <Stack gap={2} mb={3}>
                  <InputField
                    required
                    label="Syndicate Name"
                    fieldName="syndicateName"
                    id="syndicateName"
                    name="syndicateName"
                    value={values.syndicateName}
                    error={!!errors.syndicateName}
                    type="text"
                    helperText="Please enter a unique name"
                  />
                  {!isUpdate && (
                    <LvAutoComplete
                      required
                      label="Select Investor"
                      options={investorOptions}
                      setOptions={setInvestorOptions}
                      selectedObject={values.selectedInvestor}
                      setSelectedObject={(value: any) =>
                        setFieldValue("selectedInvestor", value)
                      }
                      onSelect={() => setInvestorOptions([])}
                      onChange={handleInvestorSearch}
                      helperText="Only the investors who are active, published, joined, and not part of any private syndicate can be selected"
                      error={
                        !!errors.selectedInvestor &&
                        touched.selectedInvestor &&
                        errors.selectedInvestor
                      }
                    />
                  )}
                  <Dropdown
                    label="Syndicate Type"
                    required
                    id="syndicateType"
                    name="syndicateType"
                    fieldName="syndicateType"
                    options={SyndicateTypeOptions}
                    value={values.syndicateType}
                    error={!!errors.syndicateType && touched.syndicateType}
                  />
                  <LvAutoComplete
                    label="Add Specific Backers to Private Syndicate (Select mailer list)"
                    options={mailerOptions}
                    setOptions={setMailerOptions}
                    selectedObject={values?.selectedMailer}
                    setSelectedObject={(values: any) =>
                      setFieldValue("selectedMailer", values)
                    }
                    onSelect={() => setMailerOptions([])}
                    onChange={getMailersOptions}
                  />
                  {values.syndicateType.value === "normal" ||
                  values.syndicateType.value === "private" ? (
                    <>
                      <InputField
                        required
                        label="Syndicate Header"
                        fieldName="syndicateHeader"
                        id="syndicateHeader"
                        name="syndicateHeader"
                        value={values.syndicateHeader}
                        error={!!errors.syndicateHeader}
                      />
                      <InputField
                        label="About Syndicate"
                        fieldName="aboutSyndicate"
                        id="aboutSyndicate"
                        name="aboutSyndicate"
                        value={values.aboutSyndicate}
                        error={!!errors.aboutSyndicate}
                        type="text"
                        multiline
                        required
                        minRows={4}
                      />
                      <InputField
                        label="Syndicate Investment Thesis"
                        fieldName="syndicateInvestmentThesis"
                        id="syndicateInvestmentThesis"
                        name="syndicateInvestmentThesis"
                        value={values.syndicateInvestmentThesis}
                        error={!!errors.syndicateInvestmentThesis}
                        type="text"
                        multiline
                        required
                        minRows={4}
                      />
                      <TimePeriodDropdown
                        coreDropdownProps={{
                          label: "",
                          options: [{ label: "Lakhs", value: 100000 }],
                          disabled: true,
                          defaultValue: { label: "Lakhs", value: 100000 },
                          value: { label: "Lakhs", value: 100000 },
                          id: "minInvestmentUnit",
                          name: "minInvestmentUnit",
                          hideEndAdornment: true,
                        }}
                        customTextFieldProps={{
                          id: "minInvestment",
                          name: "minInvestment",
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography color={getColor("primary", 700)}>
                                  ₹
                                </Typography>
                              </InputAdornment>
                            ),
                          },
                          value: values.minInvestment,
                          label: "Syndicate Minimum Typical Investment",
                          required: true,
                        }}
                        dropdownWidth="120px"
                        error={errors?.minInvestment}
                        touched={touched?.minInvestment}
                        name="minInvestment"
                        fieldName="minInvestment"
                        id="minInvestment"
                      />
                      <TimePeriodDropdown
                        coreDropdownProps={{
                          label: "",
                          options: [{ label: "Lakhs", value: 100000 }],
                          disabled: true,
                          defaultValue: { label: "Lakhs", value: 100000 },
                          value: { label: "Lakhs", value: 100000 },
                          id: "maxInvestmentUnit",
                          name: "maxInvestmentUnit",
                          hideEndAdornment: true,
                        }}
                        customTextFieldProps={{
                          id: "maxInvestment",
                          name: "maxInvestment",
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography color={getColor("primary", 700)}>
                                  ₹
                                </Typography>
                              </InputAdornment>
                            ),
                          },
                          value: values.maxInvestment,
                          label: "Syndicate Maximum Typical Investment",
                          required: true,
                        }}
                        dropdownWidth="120px"
                        error={errors?.maxInvestment}
                        touched={touched?.maxInvestment}
                        id="maxInvestment"
                        name="maxInvestment"
                        fieldName="maxInvestment"
                        required
                      />
                      <InputField
                        label="Syndicate Deal Per Year"
                        fieldName="syndicateDealPerYear"
                        id="syndicateDealPerYear"
                        name="syndicateDealPerYear"
                        value={values.syndicateDealPerYear}
                        error={!!errors.syndicateDealPerYear}
                        type="number"
                        required
                        helperText="How many deals the syndicate is planning to bring in an year?"
                      />
                      <FileUpload
                        placeholder="Syndicate Profile Image*"
                        helperText="upload your file here"
                        selectedFile={(file: any) =>
                          setFieldValue("syndicateProfileImage", file)
                        }
                        value={values?.syndicateProfileImage ?? []}
                        accept={{
                          "image/svg": [".png", ".jpg", ".jpeg"],
                        }}
                        title="Upload Syndicate Profile Image"
                        hideDocumentVault
                        error={
                          !!errors?.syndicateProfileImage &&
                          !!touched?.syndicateProfileImage
                        }
                        required
                      />
                      <FileUpload
                        placeholder="Syndicate Cover Image*"
                        helperText="upload your file here"
                        selectedFile={(file: any) =>
                          setFieldValue("syndicateCoverImage", file)
                        }
                        value={values?.syndicateCoverImage ?? []}
                        accept={{
                          "image/svg": [".png", ".jpg", ".jpeg"],
                        }}
                        title="Upload Syndicate Cover Image"
                        hideDocumentVault
                        required
                        error={
                          !!errors?.syndicateCoverImage &&
                          !!touched?.syndicateCoverImage
                        }
                      />
                    </>
                  ) : (
                    <>
                      <TimePeriodDropdown
                        coreDropdownProps={{
                          label: "",
                          options: [{ label: "Lakhs", value: 100000 }],
                          disabled: true,
                          defaultValue: { label: "Lakhs", value: 100000 },
                          value: { label: "Lakhs", value: 100000 },
                          id: "maxCorpusUnit",
                          name: "maxCorpusUnit",
                          hideEndAdornment: true,
                        }}
                        customTextFieldProps={{
                          id: "maxCorpus",
                          name: "maxCorpus",
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography color={getColor("primary", 700)}>
                                  ₹
                                </Typography>
                              </InputAdornment>
                            ),
                          },
                          value: values.maxCorpus,
                          label: "Maximum Corpus",
                          required: true,
                        }}
                        dropdownWidth="120px"
                        error={errors?.maxCorpus}
                        touched={touched?.maxCorpus}
                        id="maxCorpus"
                        name="maxCorpus"
                        fieldName="maxCorpus"
                      />
                      <TimePeriodDropdown
                        coreDropdownProps={{
                          label: "",
                          options: [{ label: "Years", value: 12 }],
                          disabled: true,
                          defaultValue: { label: "Years", value: 12 },
                          value: { label: "Years", value: 12 },
                          id: "preCommitTenureUnit",
                          name: "preCommitTenureUnit",
                          hideEndAdornment: true,
                        }}
                        customTextFieldProps={{
                          id: "preCommitTenure",
                          name: "preCommitTenure",
                          value: values.preCommitTenure,
                          label: "Pre-commit Syndicate Tenure",
                          required: true,
                        }}
                        dropdownWidth="120px"
                        error={errors?.preCommitTenure}
                        touched={touched?.preCommitTenure}
                        id="preCommitTenure"
                        name="preCommitTenure"
                        fieldName="preCommitTenure"
                      />
                      <InputField
                        label="Management Fee Percent (per year)"
                        fieldName="managementFee"
                        id="managementFee"
                        name="managementFee"
                        value={values.managementFee}
                        error={!!errors.managementFee}
                        type="number"
                        required
                      />
                      <InputField
                        label="Number of Drawdowns"
                        fieldName="numberOfDrawdowns"
                        id="numberOfDrawdowns"
                        name="numberOfDrawdowns"
                        value={values.numberOfDrawdowns}
                        error={!!errors.numberOfDrawdowns}
                        type="number"
                        required
                      />
                      <TimePeriodDropdown
                        coreDropdownProps={{
                          label: "",
                          options: [{ label: "Months", value: 1 }],
                          disabled: true,
                          defaultValue: { label: "Months", value: 1 },
                          value: values.drawDownIntervalsUnit,
                          id: "drawDownIntervalsUnit",
                          name: "drawDownIntervalsUnit",
                          hideEndAdornment: true,
                        }}
                        customTextFieldProps={{
                          id: "drawDownIntervals",
                          name: "drawDownIntervals",
                          value: values.drawDownIntervals,
                          label: "Expected Drawdown Interval",
                          required: true,
                        }}
                        dropdownWidth="120px"
                        error={errors?.drawDownIntervals}
                        touched={touched?.drawDownIntervals}
                        id="drawDownIntervals"
                        name="drawDownIntervals"
                        fieldName="drawDownIntervals"
                      />
                      <InputField
                        label="LV Carry Percent"
                        fieldName="LvCarry"
                        id="LvCarry"
                        name="LvCarry"
                        value={values.LvCarry}
                        error={!!errors.LvCarry}
                        type="number"
                        required
                      />
                      <InputField
                        label="Hurdle Rate Percent"
                        fieldName="hurdleRate"
                        id="hurdleRate"
                        name="hurdleRate"
                        value={values.hurdleRate}
                        error={!!errors.hurdleRate}
                        type="number"
                        required
                      />
                    </>
                  )}
                  <Stack mt={2} direction="row" justifyContent="end">
                    <ActionButton
                      disabled={submitting === API_STATUS.LOADING}
                      isLoading={submitting === API_STATUS.LOADING}
                      onClick={handleSubmit}
                    >
                      {isUpdate ? "Update" : "Create"} Syndicate
                    </ActionButton>
                  </Stack>
                </Stack>
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default SyndicateForm;
