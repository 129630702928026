import React, { ReactNode } from "react";
import { Grid, Typography, Box, Tooltip, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getColor } from "../Styles/Colors";
import { SectionHeader } from "../header/section-header";

const useStyles = makeStyles({
  containerClass: {
    borderRadius: "0.5rem",
    padding: "1.875rem",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "5%",
  },
  subHeader: {
    fontSize: "1.125rem",
    lineHeight: "1.875rem",
    color: getColor("neutral", 900),
    fontFamily: "Work Sans",
    fontWeight: 600,
    marginBottom: "1.25rem",
  },
  expansionPanelContainer: {
    padding: `0px !important`,
  },
});

type optionsObject = {
  label: any;
  value?: any;
  spacing: number;
  /**
   * here you can customize the design of the values and pass the ReactNode (elements) directly instead of just value
   * Eg: <Typography>value</Typography>
   */
  renderer?: any;
  /**
   * boolean value, if we want to make this particular item to become expansion panel .Value to this item will be iside of the expansion panel
   */
  isExpansionPanel?: boolean;
  helpText?: string;
};

export type CardContainerProps = {
  /**
   * section header
   */
  header?: string;
  /**
   * section header react node
   */
  headerNode?: ReactNode;
  /**
   * sub header in the card itself
   */
  subHeader?: string;
  /**
   * sub header react node in the card itself
   */
  subHeaderNode?: ReactNode;
  /**
   * here options is the array of objects , here you can add options to render the key value data
   */
  options: optionsObject[];
  /**
   * add custom styles
   */
  customClass?: string;
  customStyle?: any;
};

export function CardContainer({
  header,
  headerNode,
  subHeader,
  subHeaderNode,
  options,
  customClass,
  customStyle,
}: CardContainerProps) {
  const classes = useStyles();
  const renderOptions = () => {
    return (
      <Box
        className={`${classes.containerClass} ${customClass}`}
        style={customStyle}
      >
        {subHeader && !subHeaderNode && (
          <Box className={classes.subHeader}>{subHeader}</Box>
        )}
        {!subHeader && subHeaderNode && <>{subHeaderNode}</>}
        <Grid container>
          {options.map((ele, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={ele?.spacing ?? 6}
              xl={ele?.spacing ?? 4}
              key={`${ele.value} ${index}`}
            >
              <Box mb={1.875}>
                <Stack direction="row" gap={1}>
                  <Typography
                    align="left"
                    variant="paragraph_03_bold"
                    component="span"
                  >
                    {ele.label}
                  </Typography>{" "}
                  {ele?.helpText && (
                    <Tooltip
                      title={ele?.helpText}
                      placement="top-start"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: { background: "#f2f2f2", color: "#000" },
                        },
                      }}
                    >
                      <img
                        data-testid="info-tooltip"
                        src="https://dsjvxb1plg419.cloudfront.net/v2.0/InfoBlueIcon.svg"
                        alt="info"
                        style={{
                          cursor: "pointer",
                          display: "inline",
                          marginLeft: 0,
                        }}
                      />
                    </Tooltip>
                  )}
                </Stack>
                <Box width="100%" sx={{ wordBreak: "break-word" }}>
                  {ele.renderer ? (
                    <Typography
                      align="left"
                      variant="heading_04_medium"
                      fontWeight={600}
                    >
                      {ele.renderer}
                    </Typography>
                  ) : (
                    <Typography
                      align="left"
                      variant="heading_04_medium"
                      fontWeight={600}
                    >
                      {`${ele.value}`}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  return (
    <Box id="card-container">
      {header && !headerNode && (
        <Box mb={2.5}>
          <SectionHeader>{header}</SectionHeader>
        </Box>
      )}
      {!header && headerNode && <Box mb={2.5}>{headerNode}</Box>}
      {renderOptions()}
    </Box>
  );
}
