import { CloudDownloadOutlined } from "@mui/icons-material";
import React, { FC } from "react";
import { toast } from "react-toastify";

type DownloadButtonProps = { url: string };

const DownloadButton: FC<DownloadButtonProps> = ({ url }) => {
  const handleDownload = () => {
    try {
      window.open(url, "_blank", "noreferrer");
    } catch (e: any) {
      toast.error("Error in fetching document");
    }
  };

  return (
    <CloudDownloadOutlined
      sx={{ cursor: "pointer" }}
      onClick={handleDownload}
    />
  );
};

export default DownloadButton;
