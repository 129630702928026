import { getDashboardData } from "../services/dashboardServices";

export default class DashboardManager {
  constructor() {}

  async getDashboardData() {
    try {
      let result = await getDashboardData();
      return result?.data;
    } catch (error) {
      throw error;
    }
  }
}
