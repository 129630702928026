import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { useState } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { FileTypeIcon, isEmpty } from "../../../../utils/commonUtils";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import StartupManagement from "../../managers/startupManager";
import { toast } from "react-toastify";
import { useEffect } from "react";
import StartupUpdateModal from "./modals/StartupUpdateModal";
import { investorCommunicationBreadCrumbData } from "../../constants/InvestorCommunicationConstants";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { Link } from "react-router-dom";
import { AttachEmailOutlined, WysiwygOutlined } from "@mui/icons-material";
import VerifyModal from "../../../userManagement/components/modals/VerifyModal";
import EmailContentModal from "./modals/EmailContentModal";

export default function addNewInstitution() {
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [emailData, setEmailData] = useState<any>(null);

  const [isStartupUpdateModalOpen, setIsStartupUpdateModalOpen] =
    useState(false);

  const getCommittedInvestorCommunication = async (pageNo: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await startupManagement.getCommitmentInvestorCommunication(pageNo);
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleReportAction = async (id: any, status: any) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      await startupManagement.handleReportAction(id, status);
      setPage(1);
      setIsUpdateModalOpen(false);
      setSelectedRow(null);
      setSelectedAction(null);
      toast.success(`Startup Update ${status} successfully`);
      await getCommittedInvestorCommunication(1);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (e: any) {
      toast.error(e);
      toast.error(e?.detail);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleStartupUpdateModal = () => {
    setIsStartupUpdateModalOpen(false);
  };

  const Columns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      headerAlign: "left",
      sortable: false,
      minWidth: 180,
      flex: 2,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/startup/startup-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.startup_name || "Go to Startup"}{" "}
          </Link>
        );
      },
    },
    {
      field: "update_type",
      headerAlign: "left",
      headerName: "Update Type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email_subject",
      headerAlign: "left",
      headerName: "Email Subject",
      flex: 2,
      minWidth: 180,
    },
    {
      field: "submitted_date",
      headerName: "Submitted Date",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "email-content",
      headerName: "Email Content",
      flex: 1,
      align: "center",
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.email_content ? (
          <Tooltip title="Show Email content">
            <IconButton
              onClick={() => {
                setOpenEmailModal(true);
                setEmailData({
                  subject: params?.row?.email_subject ?? "",
                  content: params?.row?.email_content ?? "",
                });
              }}
            >
              <WysiwygOutlined
                fontSize="small"
                color={getColor("action_button", 300)}
                sx={{ color: getColor("action_button", 300) }}
              />
            </IconButton>
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "attachments",
      headerName: "Attachments",
      flex: 1,
      align: "left",
      headerAlign: "left",
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <Stack direction="row" spacing={1}>
            {params.row.attachements.map((attachment: any, index: number) => (
              <Tooltip key={index} title={attachment.display_name}>
                <IconButton
                  component="a"
                  href={attachment.file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileTypeIcon fileType={attachment.file_type} />
                </IconButton>
              </Tooltip>
            ))}
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      align: "center",
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <OutlinedButton
            customStyles={{
              color:
                params.row.action === "publish"
                  ? getColor("action_button", 300)
                  : getColor("error", 500),
              borderColor:
                params.row.action === "publish"
                  ? getColor("action_button", 300)
                  : getColor("error", 500),
            }}
            onClick={() => {
              setSelectedAction(params?.row?.action);
              setSelectedRow(params?.row?.id);
              setIsUpdateModalOpen(true);
            }}
            tableAction
          >
            {params.row.action}
          </OutlinedButton>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getCommittedInvestorCommunication(page + 1);
  };

  useEffect(() => {
    getCommittedInvestorCommunication(1);
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Breadcrumb
            steps={investorCommunicationBreadCrumbData?.steps}
            pageHeader={investorCommunicationBreadCrumbData.pageHeader}
            stepKey={investorCommunicationBreadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={7} display="flex" justifyContent="end">
          <ActionButton
            onClick={() => {
              setIsStartupUpdateModalOpen(true);
            }}
          >
            Create Startup Update
          </ActionButton>
        </Grid>
      </Grid>

      <VerifyModal
        isOpen={isUpdateModalOpen}
        title={`${selectedAction} Startup Update`}
        message={`Do you want to proceed with ${selectedAction}?`}
        handleSubmit={() => handleReportAction(selectedRow, selectedAction)}
        handleClose={() => {
          setIsUpdateModalOpen(false);
          setSelectedAction(null);
          setSelectedRow(null);
        }}
        submitting={apiStatus === API_STATUS.LOADING}
      />

      {openEmailModal && (
        <EmailContentModal
          isOpen={openEmailModal}
          emailData={emailData}
          handleClose={() => {
            setOpenEmailModal(false);
            setEmailData(null);
          }}
        />
      )}
      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={Columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
            enableDefaultSort={false}
          />
        </>
      )}

      <StartupUpdateModal
        isOpen={isStartupUpdateModalOpen}
        handleClose={handleStartupUpdateModal}
        selected={null}
        handleSubmitted={() => {
          getCommittedInvestorCommunication(1);
        }}
      />
    </Box>
  );
}
