import requestManager from "../../../api/requestManager";

export const fetchCrmList = (type: string) => {
  const finalURL = `/v1/admin/commitment_management/commit_tracker/fetch_crm_list/?type=${type}`;
  return requestManager.apiGet(finalURL);
};

export const fetchEventList = (tab: string, page: number) => {
  const finalURL = `/v1/admin/campaign/rsvp/?tab=${tab}&&page=${page}`;
  return requestManager.apiGet(finalURL);
};

export const deleteEvent = (event_id: number) => {
  const finalURL = `/v1/admin/campaign/rsvp/delete_event/`;
  let data = { event_id };
  return requestManager.apiPost(finalURL, data);
};

export const publishRSVPEvent = (event_id: number, publish: boolean) => {
  const finalURL = `/v1/admin/campaign/rsvp/publish_event/`;
  let data = { event_id, publish };
  return requestManager.apiPost(finalURL, data);
};

export const createUpdateRSVPEvent = (data: any) => {
  const finalURL = `/v1/admin/campaign/rsvp/create_or_update_event/`;
  return requestManager.apiPost(finalURL, data);
};

export const fetchRsvpEventData = (event_id: any) => {
  const finalURL = `/v1/admin/campaign/rsvp/get_event_details/?id=${event_id}`;
  return requestManager.apiGet(finalURL);
};
