import React, { ReactNode } from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import { getColor } from "../../Styles/Colors";

type Placement = "end" | "start" | "top" | "bottom";

export type ToggleSwitchProps = {
  /**
   * Label for the toggle switch (optional).
   */
  switchLabel?: String | ReactNode;
  /**
   * Label position for the toggle switch (optional).
   */
  switchLabelPositon?: Placement;

  /**
   * A callback function which gets triggered when ever switch is toggled (optional).
   */
  onSwitchToggle?: Function;

  /**
   * A boolean value to disable the switch (optional).
   */
  isDisabled?: boolean;

  /**
   * A boolean value to se teh default value of the switch (optional).
   */
  defaultChecked?: boolean;

  /**
   * show yes or no instead of on or off
   */
  showYesOrNo?: boolean;

  /**
   * distance between label and switch   */
  labelSwitchDistance?: any;
};

const useStyles = makeStyles({
  root: {
    "& .MuiFormControlLabel-root": {
      marginLeft: "0px !important",
    },
  },
});

const MyToggleSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 21,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 1,
    transform: "translateX(1px)",
    "&.Mui-checked": {
      color: getColor("shades", 0),
      transform: "translateX(26px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          getColor("success", 500)
        )}" d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark"
            ? getColor("background", 500)
            : getColor("success", 500),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "white",
    width: 17,
    height: 17,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: "-1.1px",
      top: "-1px",
      strokeWidth: "1",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        getColor("background", 500)
      )}" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? getColor("background", 500)
        : getColor("background", 600),
    borderRadius: 20 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "65%",
      transform: "translateY(-50%)",
      width: 24,
      height: 16,
    },
  },
}));

const YesOrNoButton = styled(MyToggleSwitch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track:after": {
    backgroundImage: `url('https://dsjvxb1plg419.cloudfront.net/v2.0/yes.svg')`,
    backgroundRepeat: "no-repeat",
    left: 4.5,
    top: 14.5,
  },
  "& .MuiSwitch-track:after": {
    backgroundImage: `url('https://dsjvxb1plg419.cloudfront.net/v2.0/no.svg')`,
    backgroundRepeat: "no-repeat",
    left: 25,
    top: 14.5,
  },
}));

const OnOrOffButton = styled(MyToggleSwitch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track:after": {
    backgroundImage: `url('https://dsjvxb1plg419.cloudfront.net/v2.0/ON.svg')`,
    backgroundRepeat: "no-repeat",
    left: 6,
  },
  "& .MuiSwitch-track:after": {
    backgroundImage: `url('https://dsjvxb1plg419.cloudfront.net/v2.0/Off.svg')`,
    backgroundRepeat: "no-repeat",
    left: 21.5,
  },
}));

export function ToggleSwitch({
  switchLabel,
  switchLabelPositon = "end",
  onSwitchToggle,
  isDisabled = false,
  defaultChecked = false,
  showYesOrNo = false,
  labelSwitchDistance = "8px",
}: ToggleSwitchProps) {
  const classes = useStyles();
  const [checked, setIsChecked] = React.useState(defaultChecked);
  const handleChange = () => {
    setIsChecked(!checked);
    onSwitchToggle && onSwitchToggle(!checked);
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        data-testid="my-switch"
        control={showYesOrNo ? <YesOrNoButton /> : <OnOrOffButton />}
        checked={checked}
        label={switchLabel ? switchLabel : ""}
        labelPlacement={switchLabelPositon ? switchLabelPositon : "end"}
        onChange={handleChange}
        disabled={isDisabled}
        sx={{
          textAlign: "left !important",
          "& .MuiFormControlLabel-label": {
            color: `${getColor("border", 200)}`,
            fontSize: "14px",
            marginLeft: switchLabelPositon === "end" ? labelSwitchDistance : 0,
            marginRight:
              switchLabelPositon === "start" ? labelSwitchDistance : 0,
          },
          "& .MuiSwitch-track": {
            backgroundColor: isDisabled
              ? checked
                ? "#c5c5c7 !important"
                : "#161616 !important"
              : "inherit",
          },
        }}
      />
    </div>
  );
}
