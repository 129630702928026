import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { InputField } from "../../../../../ui-library/form-elements/CustomTextField";
import { Dropdown } from "../../../../../ui-library/dropdowns/core-dropdown";

import { AuthContext } from "../../../../../contexts/authContext";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";
import { Formik } from "formik";
import { DateTimePicker } from "../../../../../ui-library/form-elements/DateTimePicker";
import { getColor } from "../../../../../ui-library/Styles/Colors";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import {
  RSVPEventTypes,
  RSVPDeadlineHours,
  RSVPEventModalMode,
} from "../../../constants/rsvpConstants/RsvpConstant";
import SchemeManager from "../../../../aifManagement/managers/schemeManager";
import UserManagementManager from "../../../../userManagement/managers/userManagementManager";
import CampaignManagementManager from "../../../managers/campaignManagementManager";
import moment from "moment";
import RichTextEditor from "../../../../../ui-library/form-elements/RichTextEditor";
import { FormPlaceholder } from "../../../../../ui-library/placeholders/form-placeholder/FormPlaceholder";
import { schemeFormPlaceholderConfig } from "../../../../aifManagement/constants/CADocConstants";
import { isEmpty } from "../../../../../utils/commonUtils";
import { LvComplexTable } from "../../../../../ui-library/tables/lv-complex-table";

type CreateRSVPEventModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
  mode: any;
  refreshData: () => void;
};

const CreateRSVPEventModal: FC<CreateRSVPEventModalType> = ({
  isOpen,
  handleClose,
  selected,
  mode,
  refreshData,
}) => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [startupOptions, setStartupOptions] = useState<any>([]);
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const schemeManager = new SchemeManager();
  const campaignManagementManager = new CampaignManagementManager();
  const userManagementManager = new UserManagementManager(authContext);
  const [initialValues, setInitialValues] = useState<any>({
    eventId: null,
    eventType: null,
    eventTitle: null,
    eventDateAndTime: null,
    rsvpDeadline: null,
    isOnlineEvent: false,
    addOnRightSideBar: false,
    eventLink: null,
    address: null,
    description: null,
    location: null,
    startup: null,
    zoho_url: null,
  });
  const isDisabled = mode === RSVPEventModalMode.VIEW;
  let modalTitle = "Add New Event for RSVP";
  if (mode === RSVPEventModalMode.VIEW) {
    modalTitle = `${initialValues?.eventTitle ?? "RSVP"} Details`;
  } else if (mode === RSVPEventModalMode.EDIT) {
    modalTitle = `Edit ${initialValues.eventTitle}`;
  }

  const fetchRsvpEventData = async (id: number) => {
    try {
      setLoading(true);
      const result: any = await campaignManagementManager.fetchRsvpEventData(
        id
      );
      if (result?.success) {
        let data = result?.data;
        setStartupOptions(data?.event_speakers);
        setLocationOptions([data?.location?.city]);
        setEditorState(data?.event?.description || "");
        setInitialValues({
          eventId: data?.event?.id || "",
          eventType:
            RSVPEventTypes.filter(
              (event: any) => event.value === data?.event?.event_type
            )[0] || "",
          eventTitle: data?.event?.title || "",
          eventDateAndTime: new Date(data?.event?.event_date) || "",
          rsvpDeadline:
            RSVPDeadlineHours.filter(
              (event: any) => event.value === data?.event?.rsvp_deadline
            )[0] || "",
          isOnlineEvent: data?.event?.online || false,
          addOnRightSideBar: data?.event?.on_right_side || false,
          eventLink: data?.event?.link || "",
          description: data?.event?.description || "",
          location: data?.location?.city,
          address: data?.location?.address || "",
          startup: data?.event_speakers[0] || "",
          zoho_url: data?.zoho_url || "",
          eventGuestList: data?.event_guests || [],
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
  };

  const fetchStartups = async (search: string) => {
    try {
      const result = await schemeManager.getAdminLookupData(
        "startups_as_per_tab",
        search,
        "progress"
      );
      setStartupOptions(result);
    } catch (error: any) {
      console.error(error);
    }
  };

  const getLocationOptions = async (search: string) => {
    try {
      let result = await userManagementManager.getLookupData({
        search,
        type: "locations",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setLocationOptions(result);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleEditorChange = (content: string) => {
    setEditorState(content);
  };

  const submitRsvpForm = async (values: any) => {
    try {
      setSubmitting(true);
      let event = {
        id: null,
        event_type: values.eventType?.value,
        title: values.eventTitle,
        event_date: values.eventDateAndTime,
        rsvp_deadline: values.rsvpDeadline?.value,
        online: values.isOnlineEvent,
        description: editorState,
        link: values.eventLink,
        on_right_side: values.addOnRightSideBar,
      };

      if (mode === RSVPEventModalMode.EDIT) {
        let date = moment(
          event.event_date,
          "ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)",
          true
        );
        if (date.isValid()) {
          let event_date = moment(
            event.event_date,
            "ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)"
          );
          event["event_date"] = event_date.format("DD-MM-YYYY hh:mm a");
        }
        event["id"] = values.eventId;
      }

      let data = {
        event,
        eventLocations: !event.online
          ? {
              location_id: values.location.value,
              address: values.address,
            }
          : {},
        update: mode === RSVPEventModalMode.EDIT,
        startup_ids: String(values.startup.value),
      };
      await campaignManagementManager.createUpdateRSVPEvent(data);
      setSubmitting(false);
      handleClose();
      let toastMsg = `RSVP Event ${
        mode === RSVPEventModalMode.EDIT ? "Updated" : "Created"
      } Successfully`;
      toast.success(toastMsg);
      refreshData();
    } catch (error) {
      setSubmitting(false);
      toast.error("Error while creating RSVP Event");
    }
  };

  const validationSchema = Yup.object().shape({
    eventType: Yup.object().required("Event Type is required"),
    eventTitle: Yup.string().required("Event Title is required"),
    eventDateAndTime: Yup.string().required("Event Date And Time is required"),
    rsvpDeadline: Yup.object().required("RSVP Deadline is required"),
    eventLink: Yup.string().required("Event Link is required"),
    location: Yup.object().when("isOnlineEvent", {
      is: (val: any) => !val,
      then: (schema) => schema.required("location is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    address: Yup.string().when("isOnlineEvent", {
      is: (val: any) => !val,
      then: (schema) => schema.required("address is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  let columns = [
    {
      field: "user__name",
      headerName: "Guest Name",
      flex: 0.7,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "user__email",
      headerName: "Guest Email",
      flex: 0.7,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "created_at",
      headerName: "Guest RSVP time",
      flex: 0.7,
      headerAlign: "left",
      align: "left",
    },
  ]

  useEffect(() => {
    if (selected) {
      fetchRsvpEventData(selected);
    }
  }, [selected]);

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      hideButtons
      title={modalTitle}
      submitText="Submit"
      cancelText="Close"
      closeIcon
    >
      {loading ? (
        <Box width={"50vw"}>
          <FormPlaceholder elementsConfig={schemeFormPlaceholderConfig} />
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={submitRsvpForm}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleSubmit }) => (
            <Stack width="50vw" gap={2} mt={2}>
              <Dropdown
                label="Event Type"
                required
                id="eventType"
                name="eventType"
                fieldName="eventType"
                options={RSVPEventTypes}
                value={values.eventType}
                error={!!errors.eventType}
                disabled={isDisabled}
              />

              <InputField
                label="Event Title"
                fieldName="eventTitle"
                id="eventTitle"
                name="eventTitle"
                value={values.eventTitle}
                error={!!errors.eventTitle}
                type="text"
                required
                disabled={isDisabled}
              />
              <DateTimePicker
                placeholder="Event Date & Time"
                alignCalenderIcon="right"
                iconColor={getColor("primary", 700)}
                required
                name="eventDateAndTime"
                id="eventDateAndTime"
                error={!!errors?.eventDateAndTime}
                value={values?.eventDateAndTime}
                disabled={isDisabled}
              />

              <Dropdown
                label="RSVP Deadline"
                required
                id="rsvpDeadline"
                name="rsvpDeadline"
                fieldName="rsvpDeadline"
                options={RSVPDeadlineHours}
                value={values.rsvpDeadline}
                error={!!errors.rsvpDeadline}
                disabled={isDisabled}
              />

              <FormControlLabel
                key={1}
                control={
                  <Checkbox
                    checked={values?.isOnlineEvent}
                    onChange={(e) =>
                      setFieldValue("isOnlineEvent", e.target.checked)
                    }
                    value={values?.isOnlineEvent}
                    size="small"
                  />
                }
                label="Online Event"
                disabled={isDisabled}
              />

              {!values.isOnlineEvent && (
                <Fragment>
                  <LvAutoComplete
                    label="Location"
                    options={locationOptions}
                    setOptions={setLocationOptions}
                    selectedObject={values.location}
                    setSelectedObject={(value: any) =>
                      setFieldValue("location", value)
                    }
                    onSelect={() => setLocationOptions([])}
                    onChange={getLocationOptions}
                    disabled={isDisabled}
                  />
                  <InputField
                    label="Address"
                    fieldName="address"
                    id="address"
                    name="address"
                    value={values.address}
                    error={!!errors.address}
                    type="text"
                    required
                    disabled={isDisabled}
                  />
                </Fragment>
              )}

              <RichTextEditor
                label="Description"
                editorState={editorState}
                setEditorState={setEditorState}
                disabled={isDisabled}
              />

              <InputField
                label="Event Link"
                fieldName="eventLink"
                id="eventLink"
                name="eventLink"
                value={values.eventLink}
                error={!!errors.eventLink}
                type="text"
                required
                disabled={isDisabled}
              />
              <LvAutoComplete
                label="Select Startup"
                options={startupOptions}
                setOptions={setStartupOptions}
                selectedObject={values.startup}
                setSelectedObject={(value: any) =>
                  setFieldValue("startup", value)
                }
                onChange={fetchStartups}
                disabled={isDisabled}
              />

              <FormControlLabel
                key={1}
                control={
                  <Checkbox
                    checked={values?.addOnRightSideBar}
                    onChange={(e) =>
                      setFieldValue("addOnRightSideBar", e.target.checked)
                    }
                    value={values?.addOnRightSideBar}
                    size="small"
                  />
                }
                label="Add On Rightside Bar"
                disabled={isDisabled}
              />
              {isDisabled && (
                <InputField
                  label="Zoho URL"
                  fieldName="zoho_url"
                  id="zoho_url"
                  name="zoho_url"
                  value={values.zoho_url}
                  type="text"
                  disabled={isDisabled}
                />
              )}
              {isDisabled ? (
                <Box width="min-width">
                  <br />
                  <LvComplexTable
                    rows={values.eventGuestList}
                    columns={columns}
                    rowHeight={40}
                    headerHeight={40}
                    // minWidth={750}
                    filterMode="server"
                    enableAdvancedFiltering
                    isUnFilteredDataPresent={!isEmpty(values.eventGuestList)}
                    enableAutoHeight={true}
                    customNoRowsHeader="No guest have RSVP'd"
                    customNoRowsMessage=""
                    hideToobar
                  />
                </Box>
              ) : null}
              {!isDisabled && (
                <Stack direction="row" justifyContent="end" mt={2}>
                  <ActionButton
                    isLoading={submitting}
                    disabled={submitting}
                    onClick={handleSubmit}
                  >
                    Submit
                  </ActionButton>
                </Stack>
              )}
            </Stack>
          )}
        </Formik>
      )}
    </CommonModal>
  );
};

export default CreateRSVPEventModal;
