export const INVESTMENT_TYPE = {
  AIF: {
    label: "AIF",
    value: "aif",
  },
  DIRECT: {
    label: "Direct",
    value: "direct",
  },
};

export const INVESTOR_RANKING = {
  P0: {
    label: "P0",
    value: 0,
  },
  P1: {
    label: "P1",
    value: 1,
  },
};

export const SCHEME_DOC_STATUS = {
  SENT: {
    label: "Sent",
    value: "Sent",
  },
  VIEWED: {
    label: "Viewed",
    value: "Viewed",
  },
  TO_BE_SENT: {
    label: "To be sent",
    value: "To be sent",
  },
};

export const DealConsentOptions = [
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

export const TransferTypeOptions = [
  {
    label: "Foreign",
    value: "foreign",
  },
  {
    label: "Indian",
    value: "indian",
  },
  {
    label: "NRO",
    value: "nro",
  },
  {
    label: "NRE",
    value: "nre",
  },
];

export const CommitTrackerBreadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Commit Tracker",
    navigationButton: false,
  },
};

export const ActionType = {
  edit: "Edit",
  lock: "Lock",
  unlock: "Unlock",
  restrict: "Restrict Whatsapp Switch",
  without_gst: "Without GST",
  send_drawdown: "Send Drawdown",
};
