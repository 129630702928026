import { Box, Grid, Stack } from "@mui/material";
import { HorizontalTabs } from "../../../../../ui-library/tabs/horizontal-tabs";
import { useEffect, useState } from "react";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import {
  getActionDropdownOptions,
  getDropdownActionData,
  initialSelectedAction,
  startupDetailsBreadCrumbData,
  StartupDetailTabs,
} from "../../../constants/StartupDetailsContant";
import { toast } from "react-toastify";
import StartupManagementManager from "../../../managers/startupManager"; // Adjust the import path as necessary
import { AuthContext } from "../../../../../contexts/authContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StartupBasicDetails from "./BasicDetails";
import FundraiseDetails from "./FundraiseDetails";
import ScreeningDetails from "./ScreeningDetails";
import PortfolioDetails from "./PortfolioDetails";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import StartupPanelProgressSkeleton from "../../../skeleton/StartupPanelSkeleton";
import { DropdownButton } from "../../../../../ui-library/buttons/DropdownButton";
import { redirectToLetsventure } from "../../../../../utils/commonUtils";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import VerifyModal from "../../../../userManagement/components/modals/VerifyModal";

export default function StartupDetails() {
  const [selectedTab, setSelectedTab] = useState(StartupDetailTabs[0].value);
  const [loading, setLoading] = useState(false);
  const [startupDetails, setStartupDetail] = useState<any>();
  const [selectedAction, setSelectedAction] = useState(initialSelectedAction);
  const [submittingAction, setSubmittingAction] = useState(false);
  const [confirmCloneModal, setConfirmCloneModal] = useState(false);
  const [cloningStartup, setCloningStartup] = useState(false);

  let breadcrumbData = startupDetailsBreadCrumbData;
  const startupManagementManager = new StartupManagementManager(AuthContext);
  const { startup_id } = useParams();
  const location = useLocation();
  const { selectedTab: preSelectedTab = null } = location?.state ?? {};

  const navigate = useNavigate();

  const getStartupDetails = async () => {
    try {
      setLoading(true);
      if (!startup_id) {
        toast.error("Startup ID is required");
        return;
      }
      const result = await startupManagementManager.getStartupDetail(
        startup_id
      );
      if (result) {
        setStartupDetail(result);
        breadcrumbData.pageHeader.header = result.name;

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error("Failed to fetch startup details");
    }
  };

  useEffect(() => {
    getStartupDetails();
    if (preSelectedTab) {
      setSelectedTab(preSelectedTab);
    }
  }, [startup_id, preSelectedTab]);

  const handleDropdownOptionSubmission = async () => {
    try {
      setSubmittingAction(true);
      await startupManagementManager.updateStartupData(
        getDropdownActionData(selectedAction),
        startup_id
      );
      setSelectedAction(initialSelectedAction);
      setSubmittingAction(false);
      toast.success("Startup details updated successfully");
    } catch (error) {
      setSelectedAction(initialSelectedAction);
      setSubmittingAction(false);
      toast.error("Error while submitting the startup details");
    }
  };

  const handleDropdownOptionClick = (key: string) => {
    if (key === "updateStartup") {
      const URL = `/startup/${startupDetails.permalink}/edit/basic_information/`;
      redirectToLetsventure(URL);
    } else if (key === "cloneStartup") {
      setConfirmCloneModal(true);
    } else {
      setSelectedAction({ ...selectedAction, [key]: true });
    }
  };

  const handleEditDetail = () => {
    if (selectedTab === "basic_details") {
      navigate(`/startup/startup-panel/${startup_id}/edit/basic`, {
        state: { startupDetails },
      });
    } else if (selectedTab === "fundraise_details") {
      navigate(`/startup/startup-panel/${startup_id}/edit/fundraise`, {
        state: { startupDetails },
      });
    } else if (selectedTab === "screening_details") {
      // navigate(`/startup/startup-panel/${startup_id}/edit/screening`, {
      //   state: { startupDetails },
      // });
    } else if (selectedTab === "portfolio_details") {
      navigate(`/startup/startup-panel/${startup_id}/edit/portfolio`, {
        state: { startupDetails },
      });
    }
  };

  async function handleCloneStartup() {
    try {
      setCloningStartup(true);
      let result = await startupManagementManager.cloneStartup(startup_id);
      if (result.success) {
        setCloningStartup(false);
        setConfirmCloneModal(false);
        toast.success(result.msg);
      } else {
        toast.error("Error while cloning startup");
      }
    } catch (error) {
      toast.error("Error while cloning startup");
      setCloningStartup(false);
    }
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={4}>
          <Breadcrumb
            steps={breadcrumbData?.steps}
            breadcrumbSeparator={true}
            pageHeader={{
              ...breadcrumbData?.pageHeader,
              navigationFunction: () => navigate("/startup/startup-panel"),
            }}
            stepKey={breadcrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={8} display="flex" gap={2} justifyContent="end">
          <DropdownButton
            buttonText="Actions"
            options={getActionDropdownOptions(startupDetails)}
            onOptionClick={(option: string, key: string) =>
              handleDropdownOptionClick(key)
            }
            menuItemStyle={{ "& .MuiPaper-root": { width: 260 } }}
          />

          <ActionButton
            onClick={handleEditDetail}
            disabled={loading || selectedTab === "screening_details"}
          >
            Edit{" "}
            {
              StartupDetailTabs.find((tab: any) => tab.value === selectedTab)
                ?.name
            }
          </ActionButton>
        </Grid>
      </Grid>

      {selectedAction && (
        <CommonModal
          isOpen={
            selectedAction.markTrending ||
            selectedAction.removeTrending ||
            selectedAction.markFeatured ||
            selectedAction.removeFeatured ||
            selectedAction.markFunded ||
            selectedAction.markFundedOutsideLV ||
            selectedAction.lockEditProfilePage ||
            selectedAction.unlockEditProfilePage
          }
          title="Are You Sure?"
          handleClose={() => setSelectedAction(initialSelectedAction)}
          handleSubmit={handleDropdownOptionSubmission}
          submitText="Submit"
          buttonStyles={{ width: "100%" }}
          closeIcon
          hideCancelButton
          isLoading={submittingAction}
          disableSubmitButton={submittingAction}
        >
          <Box>
            Are you sure you want to mark this startup as{" "}
            {selectedAction.markTrending
              ? "Trending"
              : selectedAction.markFeatured
              ? "Featured"
              : selectedAction.markFunded
              ? "Funded"
              : selectedAction.markFundedOutsideLV
              ? "Funded Outside LV"
              : ""}
            ?
          </Box>
        </CommonModal>
      )}

      {confirmCloneModal && (
        <VerifyModal
          isOpen={confirmCloneModal}
          title="Are you sure?"
          message="Are you sure you want to clone this startup?"
          handleClose={() => setConfirmCloneModal(false)}
          handleSubmit={handleCloneStartup}
          submitting={cloningStartup}
        />
      )}

      <HorizontalTabs
        tabs={StartupDetailTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />

      {loading ? (
        <StartupPanelProgressSkeleton />
      ) : (
        <>
          {selectedTab === "basic_details" ? (
            <StartupBasicDetails details={startupDetails} />
          ) : null}
          {selectedTab === "fundraise_details" ? (
            <FundraiseDetails details={startupDetails} />
          ) : null}
          {selectedTab === "screening_details" ? (
            <ScreeningDetails details={startupDetails} />
          ) : null}
          {selectedTab === "portfolio_details" ? (
            <PortfolioDetails details={startupDetails} />
          ) : null}
        </>
      )}
    </Box>
  );
}
