import { API_STATUS } from "../../../../constants/commonConstants";
import UserManagementManager from "../../managers/userManagementManager";
import { Box, Grid } from "@mui/material";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { Link } from "react-router-dom";

const columns = [
  {
    field: "name",
    headerName: "Name",
    headerAlign: "left",
    flex: 2,
    minWidth: 300,
    renderCell: (params: any) => {
      return params?.row?.id ? (
        <Link
          to={`/user/user-panel/${params.row?.id}/`}
          style={{ color: getColor("action_button", 300) }}
        >
          {params?.row?.name ?? "Go to User Panel"}
        </Link>
      ) : (
        "-"
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    headerAlign: "left",
    flex: 2,
    minWidth: 400,
  },
  {
    field: "date",
    headerName: "Applied On",
    align: "center",
    flex: 2,
    minWidth: 200,
  },
];

export default function ProfilePendingInvestor() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getData(page + 1);
  };

  const getData = async (pageNo: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);

      let data = await userManagementManager.getProfilePendingInvestors(
        value,
        pageNo
      );

      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    setSearchText(value);
    setPage(1);
  };

  useEffect(() => {
    getData(1);
  }, [searchText]);

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "Profile Pending Investor",
      navigationButton: false,
    },
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={7} display="flex" gap={2} justifyContent="end">
          <Box>
            <CustomTextField
              label={"Search by Name/Email"}
              width={"300px"}
              InputLabelProps={{}}
              customStyles={{}}
              error={false}
              value={value}
              onChange={setValue}
              id="name"
              name="name"
            />
          </Box>
          <ActionButton
            onClick={handleSearchClick}
            disabled={apiStatus === API_STATUS.LOADING}
            isLoading={apiStatus === API_STATUS.LOADING && !!value}
          >
            Search
          </ActionButton>
        </Grid>
      </Grid>

      <div style={{ overflow: "auto" }}>
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={40}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </div>
    </Box>
  );
}
