import { FC, useContext, useState } from "react";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { InputField } from "../../../../../ui-library/form-elements/CustomTextField";
import { FileUpload } from "../../../../../ui-library/form-elements/file-upload/FileUpload";
import { Dropdown } from "../../../../../ui-library/dropdowns/core-dropdown";
import { AuthContext } from "../../../../../contexts/authContext";
import StartupManagement from "../../../managers/startupManager";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";
import { Formik } from "formik";
import { DatePicker } from "../../../../../ui-library/form-elements/DatePicker";
import { getColor } from "../../../../../ui-library/Styles/Colors";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import { update_types } from "../../../constants/InvestorCommunicationConstants";
import RichTextEditor from "../../../../../ui-library/form-elements/RichTextEditor";

type StartupUpdateModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
  handleSubmitted: () => void;
};

const initialValues = {
  emailSubject: "",
  updateType: "",
  uploadFile: [],
  publishDate: "",
  sendEmailToInvestor: false,
};

const StartupUpdateModal: FC<StartupUpdateModalType> = ({
  isOpen,
  handleClose,
  handleSubmitted,
}) => {
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const [editorState, setEditorState] = useState("");
  const [dealOptions, setDealOptions] = useState<any>([]);
  const [selectedDeal, setSelectedDeal] = useState<any>({});
  const [submitting, setSubmitting] = useState(false);

  const handleSearchDealName = async (value: string) => {
    try {
      const result = await startupManagement.searchDealName(value);
      const formattedResult = result?.data.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setDealOptions(formattedResult);
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleEditorChange = (content: string) => {
    setEditorState(content);
  };

  const sendInvestorCommunication = async (values: any) => {
    try {
      setSubmitting(true);
      const sendEmail: any = values.sendEmailToInvestor ? true : false;
      const formData = new FormData();
      formData.append("startup_id", selectedDeal.value);
      formData.append("email_subject", values.emailSubject);
      formData.append("email_content", editorState);
      formData.append("update_type", values.updateType.value);
      formData.append("publish_date", values.publishDate);
      formData.append("send_email", sendEmail);
      values.uploadFile.forEach((file: any) => formData.append("files", file));
      await startupManagement.SendInvestorCommunication(formData);

      setSubmitting(false);
      handleClose();
      handleSubmitted();
      toast.success("Investor Communication Sent Successfully");
    } catch (error) {
      setSubmitting(false);
      toast.error("Error while sending Investor Communication");
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      hideButtons
      title="Send Investor Communication"
      submitText="Submit"
      cancelText="Close"
      buttonStyles={{ width: "min-width" }}
      closeIcon
    >
      <Formik
        initialValues={initialValues}
        onSubmit={sendInvestorCommunication}
      >
        {({ values, errors, setFieldValue, handleSubmit }) => (
          <Stack width="40vw" gap={2} mt={2}>
            <LvAutoComplete
              label="Enter Deal Name"
              options={dealOptions}
              setOptions={setDealOptions}
              selectedObject={selectedDeal}
              setSelectedObject={setSelectedDeal}
              onSelect={() => setDealOptions([])}
              onChange={handleSearchDealName}
            />
            <Dropdown
              label="Update Type"
              required
              id="updateType"
              name="updateType"
              fieldName="updateType"
              options={update_types}
              value={values.updateType}
              error={!!errors.updateType}
            />
            <InputField
              label="Email Subject"
              fieldName="emailSubject"
              id="emailSubject"
              name="emailSubject"
              value={values.emailSubject}
              error={!!errors.emailSubject}
              type="text"
            />

            <RichTextEditor
              label="Email Content"
              editorState={editorState}
              setEditorState={setEditorState}
            />
            <FileUpload
              placeholder="Upload File"
              helperText="upload your file here"
              selectedFile={(file: any) => setFieldValue("uploadFile", file)}
              multiple
              value={values?.uploadFile ?? []}
              accept={{ "text/pdf": [".pdf"] }}
              title="Upload File"
              hideDocumentVault
              width={"100%"}
            />
            <DatePicker
              placeholder="Publish Date"
              alignCalenderIcon="right"
              iconColor={getColor("primary", 700)}
              required
              name="publishDate"
              id="publishDate"
              error={!!errors?.publishDate}
              value={values?.publishDate}
              onChange={(value: any) => setFieldValue("publishDate", value)}
            />
            <FormControlLabel
              key={1}
              control={
                <Checkbox
                  checked={values?.sendEmailToInvestor}
                  onChange={(e) =>
                    setFieldValue("sendEmailToInvestor", e.target.checked)
                  }
                  value={values?.sendEmailToInvestor}
                  size="small"
                />
              }
              label="Send Email to Investors"
            />
            <Stack direction="row" justifyContent="end" mt={2}>
              <ActionButton
                isLoading={submitting}
                disabled={submitting}
                onClick={handleSubmit}
              >
                Submit
              </ActionButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </CommonModal>
  );
};

export default StartupUpdateModal;
