import { Grid, Stack } from "@mui/material";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import SyndicateForm from "./SyndicateForm";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Syndicate Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Create Syndicate",
    navigationButton: false,
  },
};

const CreateSyndicate = () => {
  return (
    <Stack>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>
      <SyndicateForm />
    </Stack>
  );
};

export default CreateSyndicate;
