import { API_STATUS } from "../../../../constants/commonConstants";
import { Box, Grid } from "@mui/material";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { Link } from "react-router-dom";
import { BreadCrumbDataNomineeInformation } from "../../constants/informationConstants";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import moment from "moment";
import { getColor } from "../../../../ui-library/Styles/Colors";
import UserInformationManager from "../../managers/userInformationManager";

export default function NomineeInformation() {
  const authContext = useContext(AuthContext);
  const dashboardManager = new UserInformationManager(authContext);

  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getData(page + 1);
  };

  const columns = [
    {
      field: "name",
      headerName: "Profile Name",
      headerAlign: "left",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) => {
        return params?.row?.name ? (
          <Link
            to={`/user/user-panel/${params.row?.user_id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      field: "nominee_name",
      headerName: "Nominee Name",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "date",
      headerName: "Nominee DOB",
      align: "center",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) =>
        params?.row?.date && moment(params?.row?.date).format("MMM DD, YYYY"),
    },
    {
      field: "nominee_relation_Type",
      headerName: "Nominee Relation Type",
      headerAlign: "left",
      flex: 2,
      minWidth: 200,
    },

    {
      field: "nominee_address",
      headerName: "Nominee Address",
      headerAlign: "left",
      flex: 2,
      minWidth: 170,
    },
    {
      field: "nominee_minor",
      headerName: "Is Nominee Minor",
      headerAlign: "left",
      flex: 2,
      minWidth: 170,
    },
  ];

  const getData = async (pageNo: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);

      let data = await dashboardManager.getNomineeInformation(value, pageNo);

      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    setPage(1);
    setSearchText(value);
  };

  useEffect(() => {
    getData(1);
  }, [searchText]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataNomineeInformation?.steps}
            pageHeader={BreadCrumbDataNomineeInformation.pageHeader}
            stepKey={BreadCrumbDataNomineeInformation.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box>
            <CustomTextField
              label={"Search by Investor Name"}
              width={"300px"}
              InputLabelProps={{}}
              customStyles={{}}
              error={false}
              value={value}
              onChange={setValue}
              id="name"
              name="name"
            />
          </Box>
          <ActionButton onClick={handleSearchClick}>Search</ActionButton>
        </Grid>
      </Grid>
      <div style={{ overflow: "auto" }}>
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="50px"
            columnHeaderHeight="40px"
          />
        ) : (
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={50}
            headerHeight={40}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={10}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </div>
    </Box>
  );
}
