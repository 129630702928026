import React, { FC, useState } from "react";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { IconButton, Stack, Tooltip } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import { AwaitingInvestorType } from "./AwaitingInvestor";
import NoteModal from "../modals/NoteModal";
import { Link } from "react-router-dom";
import moment from "moment";

const RejectedInvestor: FC<AwaitingInvestorType> = (
  props: AwaitingInvestorType
) => {
  const { setPage, formattedData, page, count, refreshData } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<any>();

  const columns = [
    {
      field: "date",
      headerName: "Date",
      align: "right",
      headerAlign: "right",
      minWidth: 170,
    },

    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      minWidth: 200,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            to={`/user/user-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "manager",
      headerName: "Relationship Manager/Onboarder",
      headerAlign: "left",
      minWidth: 180,
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "outbound_user",
      headerName: "Outbound User",
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "phone_no",
      headerName: "Phone No.",
      align: "center",
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Location",
      headerAlign: "left",
      minWidth: 150,
    },
    {
      field: "joined",
      headerName: "Joined",
      headerAlign: "left",
      minWidth: 100,
    },

    {
      field: "action",
      headerName: "Actions",
      align: "center",
      flex: 6,
      minWidth: 100,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Stack direction="row" gap={1}>
            <IconButton
              sx={{
                color: getColor("action_button", 300),
              }}
              onClick={() => {
                setSelected(params.row);
                setIsOpen(true);
              }}
            >
              <Tooltip title="Add/View Notes">
                <DescriptionIcon fontSize="small" />
              </Tooltip>
            </IconButton>

            <IconButton
              sx={{
                color: getColor("action_button", 300),
              }}
              onClick={() => {
                window.open(
                  `mailto:${params.row?.email}?subject=SendMail&body=Description`
                );
              }}
            >
              <Tooltip title="Mail">
                <EmailIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    refreshData && refreshData(page + 1);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={60}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={30}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
        rightPinnedColumns={["action"]}
      />
      {isOpen && (
        <NoteModal
          isOpen={isOpen}
          handleClose={handleClose}
          selected={selected}
          type="User"
        />
      )}
    </div>
  );
};

export default RejectedInvestor;
