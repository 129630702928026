import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import {
  ForeignInvPaymentBreadCrumbData,
  ForeignInvPaymentTabs,
} from "../../constants/ForeignInvestorPaymentConstant";
import PendingPayments from "./PendingPayments";
import PaidPayments from "./PaidPayments";

const ForeignInvestorPaymentStatus = () => {
  const [selectedTab, setSelectedTab] = useState(
    ForeignInvPaymentTabs[0].value
  );

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Breadcrumb
            steps={ForeignInvPaymentBreadCrumbData?.steps}
            pageHeader={ForeignInvPaymentBreadCrumbData.pageHeader}
            stepKey={ForeignInvPaymentBreadCrumbData.stepKey}
          />
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={ForeignInvPaymentTabs as any}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
        xAxisPadding={false}
      />
      {selectedTab === "pending" ? <PendingPayments /> : <PaidPayments />}
    </Box>
  );
};

export default ForeignInvestorPaymentStatus;
