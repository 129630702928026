export const breadCrumbDataCADocManagement = {
  stepKey: 1,
  steps: [
    {
      title: "AIF Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "CA Doc Management",
    navigationButton: false,
  },
};

export const breadCrumbDataSchemeDoc = {
  stepKey: 1,
  steps: [
    {
      title: "AIF Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Scheme Doc Management",
    navigationButton: false,
    helpText:
      "Kindly make sure the deal (of which scheme is about to be generated) is added in the Post Commitment Status Management",
  },
};

export const schemeFormPlaceholderConfig = Array(15).fill("input");
