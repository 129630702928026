import _ from "lodash";

export const getChangedValues = (values: any = {}, initialValues: any = {}) => {
  return _.reduce(
    values,
    (result: any, value, key) => {
      if (!_.isEqual(value, initialValues[key])) {
        result[key] = value;
      }
      return result;
    },
    {}
  );
};

export const getFormattedData = (data: any, keyMapping: any, formData: any) => {
  Object.keys(data).reduce((acc: any, key: string) => {
    const newKey = keyMapping[key] || key;
    if (data[key] !== undefined && data[key] !== null) {
      if (typeof data[key] === "object" && data[key]?.value) {
        formData.append(newKey, data[key].value);
      } else if (typeof data[key] === "object" && data[key]?.length) {
        formData.append(`${newKey}`, JSON.stringify(data[key]));
      } else {
        formData.append(newKey, data[key]);
      }
    }
    return acc;
  }, {});
  return formData;
};

export const getFormattedDataJson = (
  data: any,
  keyMapping: any,
  formData: any
) => {
  return Object.keys(data).reduce((acc: any, key: string) => {
    const newKey = keyMapping[key] || key;
    if (data[key] !== undefined && data[key] !== null) {
      if (typeof data[key] === "object" && data[key]?.value) {
        formData.append(newKey, data[key].value);
        acc[newKey] = data[key].value;
      } else {
        formData.append(newKey, data[key]);
        acc[newKey] = data[key];
      }
    }
    return acc;
  }, {});
};

export const processValue = (value: any): any => {
  if (typeof value === "string") {
    return value.trim(); // Sanitize string by trimming whitespace
  } else if (Array.isArray(value)) {
    return value.map((item) =>
      typeof item === "object" ? processValue(item) : item
    ); // Recursively process objects inside arrays
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((acc: any, key) => {
      acc[key] = processValue(value[key]); // Recursively process nested objects
      return acc;
    }, {});
  } else if (value instanceof File || value instanceof Blob) {
    // Handle images or files
    return value; // Assumes the API accepts File or Blob objects as-is
  }
  return value; // Default for primitives like numbers, booleans, etc.
};
