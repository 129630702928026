import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { TablePlaceholder } from "../../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "lodash";
import { API_STATUS } from "../../../../../constants/commonConstants";
import { AuthContext } from "../../../../../contexts/authContext";
import StartupManagement from "../../../managers/startupManager";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { OutlinedButton } from "../../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../../ui-library/Styles/Colors";
import VerifyModal from "../../../../userManagement/components/modals/VerifyModal";

const ShowAllCommitments = () => {
  const authContext = useContext(AuthContext);
  const startupManagementManager = new StartupManagement(authContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const navigate = useNavigate();
  const { startup_id }: any = useParams();

  const AllCommitmentBreadCrumbData = useMemo(
    () => ({
      stepKey: 2,
      breadcrumbSeparator: true,
      steps: [
        { title: "Startup Management", titleDisplay: true },
        {
          title: "All Commitments & Investor Profile Details",
          titleDisplay: true,
        },
      ],
      pageHeader: {
        header: "All Commitments & Investor Profile Details",
        navigationButton: true,
        navigationFunction: () => navigate(-1),
      },
    }),
    [navigate]
  );

  let columns = [
    {
      field: "name",
      headerName: "Full Name (Name of Investing Entity)",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: "Email Address",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "phone_no",
      headerName: "Phone Number",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "investment_amount",
      headerName: "Investment Amount",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "authorised_signatory",
      headerName: "Full Name of Authorised Signatory, if an investing entity",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "relation_name",
      headerName: "Father's name/Husband's name",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "relation_type",
      headerName: "Relation Type",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "address",
      headerName: "Residential Address/Registered Office Address",
      minWidth: 150,
      headerAlign: "left",
    },

    {
      field: "pan_no",
      headerName: "PAN Number",
      minWidth: 150,
      headerAlign: "left",
    },

    {
      field: "lookup_country",
      headerName: "Nationality",
      minWidth: 150,
      headerAlign: "left",
      renderCell: (params: any) =>
        params?.row?.lookup_country?.length
          ? params?.row?.lookup_country[0]?.label
          : "-",
    },
    {
      field: "entity_type",
      headerName: "Entity Type",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "created_at",
      headerName: "Commitment Date",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "bank_location",
      headerName: "Bank Location",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "bank_account_type",
      headerName: "Bank Type",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 120,
      align: "center",
      renderCell: (params: any) => {
        return (
          <OutlinedButton
            onClick={() => setOpenRejectModal(params?.row?.id)}
            customStyles={{
              color: getColor("action_button", 300),
              borderColor: getColor("action_button", 300),
            }}
            tableAction
          >
            Reject
          </OutlinedButton>
        );
      },
    },
  ];

  const getStartupDetails = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      if (!startup_id) {
        toast.error("Startup ID is required");
        navigate(-1);
      }
      const result = await startupManagementManager.getStartupDetail(
        startup_id
      );
      if (result) {
        setFormattedData(result?.show_all_commitment);
        setApiStatus(API_STATUS.SUCCESS);
      }
    } catch (err) {
      setApiStatus(API_STATUS.ERROR);
      toast.error("Failed to fetch startup details");
    }
  };

  const handleRejectSubmit = async () => {
    try {
      setRejecting(true);
      await startupManagementManager.updateStartupData(
        {
          admin_rejected_investment: openRejectModal,
          reject_commitment: true,
        },
        startup_id
      );
      setRejecting(false);
      setOpenRejectModal(false);
      toast.success("Investor profile rejected successfully");
      getStartupDetails();
    } catch (error) {
      setRejecting(false);
      setOpenRejectModal(false);
      toast.error("Error while rejecting the investor profile");
    }
  };

  useEffect(() => {
    getStartupDetails();
  }, [startup_id]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumb
            steps={AllCommitmentBreadCrumbData?.steps}
            pageHeader={AllCommitmentBreadCrumbData.pageHeader}
            stepKey={AllCommitmentBreadCrumbData.stepKey}
          />
        </Grid>
      </Grid>

      {openRejectModal && (
        <VerifyModal
          isOpen={openRejectModal}
          title="Reject Investor Profile"
          message="Are you sure to reject?"
          handleClose={() => setOpenRejectModal(false)}
          handleSubmit={handleRejectSubmit}
          submitting={rejecting}
        />
      )}

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            leftPinnedColumns={["name"]}
            rightPinnedColumns={["action"]}
          />
        </>
      )}
    </Box>
  );
};

export default ShowAllCommitments;
