import { Box, Grid } from "@mui/material";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { FC } from "react";

type VerifyModalType = {
  isOpen: boolean;
  handleClose?: () => void;
  handleSubmit?: () => void;
  message?: string;
  title?: string;
  submitting?: boolean;
};

const VerifyModal: FC<VerifyModalType> = (props) => {
  const {
    isOpen,
    handleClose,
    handleSubmit,
    title = "Confirm Verification",
    message = "Are you sure?",
    submitting,
  } = props;

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={submitting}
      disableSubmitButton={submitting}
      title={title}
      submitText="&nbsp;&nbsp;Yes&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;No&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
      closeIcon
    >
      <Box width="20vw">
        <Grid container gap={2}>
          <Box width="100%">
            <Grid item>{message}</Grid>
          </Box>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default VerifyModal;
