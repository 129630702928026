import {
  AttachEmailOutlined,
  AttachFile,
  DescriptionOutlined,
  PictureAsPdf,
  TableChartOutlined,
  TextSnippetOutlined,
  WysiwygOutlined,
} from "@mui/icons-material";

export const isEmpty = (value: any, falseStrings = [""]) =>
  value instanceof File
    ? false
    : typeof value === "undefined" ||
      value === null ||
      Number.isNaN(value) ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0) ||
      (typeof value === "string" && falseStrings.includes(value));

export const redirectToLetsventure = (
  url: string,
  openInNewTab: Boolean = true,
  addAccessToken: Boolean = true,
  addAccessKeyword: Boolean = false
) => {
  const BASE_URL = process.env.REACT_APP_LETSVENTURE_BASE_URL;
  const URL = `${BASE_URL}${url}?${addAccessKeyword ? `access=true&` : ""}${addAccessToken ? `access_token=${localStorage.getItem("token")}` : ""}`;
  console.log(URL);
  if (openInNewTab) {
    window.open(URL, "_blank");
  } else {
    window.location.href = URL;
  }
};

export const FileTypeIcon = ({ fileType }: { fileType: string }) => {
  switch (fileType) {
    case "pdf":
      return <PictureAsPdf />;
    case "pptx":
      return <WysiwygOutlined />;
    case "docx":
      return <DescriptionOutlined />;
    case "xlsx":
      return <TableChartOutlined />;
    case "txt":
      return <TextSnippetOutlined />;
    default:
      return <AttachFile />;
  }
};
