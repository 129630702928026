import { Box, Tooltip, Typography } from "@mui/material";
import { CardContainer } from "../../../../../ui-library/card/card-container";
import moment from "moment";
import { LinkButton } from "../../../../../ui-library/buttons/LinkButton";

const UserBasicDetails = ({ details = {} }: any) => {
  const cardOptions: any = [
    {
      label: "User Id",
      spacing: 3,
      value: details?.user_id ?? "-",
    },
    {
      label: "User Name",
      spacing: 3,
      value: details?.name ?? "-",
    },
    {
      label: "Phone Number",
      spacing: 3,
      value: details?.phone_no ?? "-",
    },
    {
      label: "Email",
      spacing: 3,
      value: details?.email ?? "-",
    },
    {
      label: "Email Confirmed?",
      spacing: 3,
      value: details?.confirmed_email ? "Yes" : "No",
    },
    {
      label: "LinkedIn",
      spacing: 3,
      renderer:
        details?.linkedin_url?.includes("http") ||
        details?.linkedin_url?.includes("www") ? (
          <LinkButton
            title={"Go to profile"}
            onClick={() =>
              window.open(
                details?.linkedin_url?.includes("http")
                  ? details?.linkedin_url
                  : `https://${details?.linkedin_url}`,
                "_blank"
              )
            }
          />
        ) : (
          "-"
        ),
    },
    {
      label: "Onboarding Fee",
      spacing: 3,
      value: details?.investor_ca_onboarding_info?.onboarding_fee ?? "-",
    },
    {
      label: "Upfront Drawdown",
      spacing: 3,
      value: details?.investor_ca_onboarding_info?.upfront_drawdown ?? "-",
    },
    {
      label: "Placement Fee",
      spacing: 3,
      value: details?.investor_ca_onboarding_info?.placement_fee ?? "-",
    },
    {
      label: "Admin Hashtag",
      spacing: 3,
      renderer: details?.private_hashtag_tokens?.length ? (
        details?.private_hashtag_tokens?.length > 1 ? (
          <>
            <Typography
              align="left"
              variant="heading_04_medium"
              fontWeight={600}
            >
              {details?.private_hashtag_tokens?.[0]?.name}{" "}
              <Tooltip
                title={details?.private_hashtag_tokens
                  .slice(1)
                  .map((hashtag: any) => hashtag.name)
                  .join(", ")}
                placement="top-start"
                arrow
                componentsProps={{
                  tooltip: { sx: { background: "#f2f2f2", color: "#000" } },
                }}
              >
                <Typography display="inline" variant="paragraph_03_bold">
                  <LinkButton
                    title={`+ ${
                      details?.private_hashtag_tokens?.length - 1
                    } more`}
                  />{" "}
                </Typography>
              </Tooltip>
            </Typography>
          </>
        ) : (
          <>{details?.private_hashtag_tokens?.[0]?.name} </>
        )
      ) : (
        "-"
      ),
    },
    {
      label: "Invited",
      spacing: 3,
      value: details?.invited ? "Yes" : "No",
    },

    {
      label: "Joined",
      spacing: 3,
      value: details?.joined_on
        ? moment(details?.joined_on).format("MMM DD, YYYY")
        : "-",
    },

    {
      label: "Category",
      spacing: 3,
      value: details?.category_name ?? "-",
    },
    {
      label: "Is Angel Investor",
      spacing: 3,
      value: details?.angel_investor ? "Yes" : "No",
    },
    {
      label: "Is Institutional Investor",
      spacing: 3,
      value: details?.institution_investor ? "Yes" : "No",
    },
    {
      label: "Is founder",
      spacing: 3,
      value: details?.startup_founder ? "Yes" : "No",
    },
    {
      label: "Is Lead Investor",
      spacing: 3,
      value: details?.is_lead_investor ? "Yes" : "No",
    },
    {
      label: "FaaS Status",
      spacing: 3,
      value: details?.is_faas ? "Yes" : "No",
    },
    {
      label: "Is Owner of Private Syndicate?",
      spacing: 3,
      value: details?.is_owner_of_syndicate ? "Yes" : "No",
    },
    {
      label: "Backer of Private Syndicate?",
      spacing: 3,
      renderer: details?.backer_private_syndicate?.length ? (
        details?.backer_private_syndicate?.length > 1 ? (
          <>
            <Typography
              align="left"
              variant="heading_04_medium"
              fontWeight={600}
            >
              {details?.backer_private_syndicate?.[0]?.syndicate_name}{" "}
              <Tooltip
                title={details?.backer_private_syndicate
                  .slice(1)
                  .map((item: any) => item?.syndicate_name)
                  .join(", ")}
                placement="top-start"
                arrow
                componentsProps={{
                  tooltip: { sx: { background: "#f2f2f2", color: "#000" } },
                }}
              >
                <Typography display="inline" variant="paragraph_03_bold">
                  <LinkButton
                    title={`+ ${
                      details?.backer_private_syndicate?.length - 1
                    } more`}
                  />{" "}
                </Typography>
              </Tooltip>
            </Typography>
          </>
        ) : (
          <>{details?.backer_private_syndicate?.[0]?.syndicate_name} </>
        )
      ) : (
        "-"
      ),
    },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ececec",
        borderRadius: "0.5rem",
        textAlign: "left",
        marginTop: "1rem",
      }}
    >
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default UserBasicDetails;
