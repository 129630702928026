import requestManager from "../../../api/requestManager";

export const getCaDocsByStatus = (status: string, page: number) => {
  const finalURL = `/v1/leegality/?ca_doc_status=${status}&page=${page}`;
  return requestManager.apiGet(finalURL);
};

export const getInvestorPayments = (paymentStatus: string, page: number) => {
  const finalURL = `/v1/investor_payments/?order_type=aif&page=${page}&payment_status=${paymentStatus}`;
  return requestManager.apiGet(finalURL);
};

export const getForiegnInvestorPayments = (
  paymentStatus: string,
  page: number
) => {
  const finalURL = `/v1/investor_payment_confirmations/?page=${page}&payment_confirmation_status=${paymentStatus}`;
  return requestManager.apiGet(finalURL);
};

export const confirmForiegnPayment = (data: any) => {
  const finalURL = `/v1/investor_payment_confirmations/confirm_investor_payment/`;
  return requestManager.apiPut(finalURL, data);
};

export const approveCAProcess = (data: any) => {
  const finalURL = `/v1/document_vault/investor/`;
  return requestManager.apiPost(finalURL, data);
};

export const generateCADocument = (data: any) => {
  const finalURL = `/v1/leegality/generate_ca_doc/`;
  return requestManager.apiPost(finalURL, data);
};

export const generateLeegalityLinkCAdoc = (data: any) => {
  const finalURL = `/v1/leegality/generate_leegality_link_ca_doc/`;
  return requestManager.apiPost(finalURL, data);
};

export const sendPaymentLinkMail = (data: any) => {
  const finalURL = `/v1/leegality/send_payment_link_mail/`;
  return requestManager.apiPost(finalURL, data);
};
