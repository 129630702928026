import React, { useState, useEffect } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import AifManagementManager from "../../managers/aifManagementManagers";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { IconConstant } from "../../../../constants/IconConstants";
import { NoDataAvailable } from "../../../../ui-library/error/NoDataAvailable";

const CaDocPaymentTable = (props: {
  status: string;
  page: number;
  setPage: any;
  type: string;
}) => {
  let { status, page, setPage, type } = props;
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const aifManagementManager = new AifManagementManager();

  let columns = [
    {
      field: "profile_name",
      headerName: "Profile Name",
      minWidth: 250,
      headerAlign: "left",
    },
    {
      field: "investor_email",
      headerName: "Investor Email",
      headerAlign: "left",
      minWidth: 300,
    },
    {
      field: "user_id",
      headerName: "User Id",
      headerAlign: "left",
      minWidth: 100,
    },
    {
      field: "order_type",
      headerName: "Order Type",
      headerAlign: "left",
      sortable: false,
      minWidth: 130,
    },
    {
      field: "amount",
      headerName: "Amount",
      sortable: false,
      headerAlign: "right",
      align: "right",
      minWidth: 130,
    },

    {
      field: "payment_link",
      headerName: "Payment Link",
      sortable: false,
      align: "center",
      minWidth: 400,
      renderCell: (params: any) => {
        return params?.row?.payment_link ? (
          <a href={params?.row?.payment_link} target="_blank" rel="noreferrer">
            {params?.row?.payment_link}
          </a>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "created_date",
      headerName: "Created Date",
      headerAlign: "right",
      align: "right",
      minWidth: 150,
    },
    {
      field: "last_updated_date",
      headerName: "Last Updated Date",
      headerAlign: "right",
      align: "right",
      minWidth: 180,
    },
  ];
  if (type === "paid") {
    columns = columns.filter((item) => item.field !== "payment_link");
  }

  const getPaymentDetails = async (currentPage = 1) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await aifManagementManager.getInvestorPayments(status, currentPage);
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getPaymentDetails(page + 1);
  };

  useEffect(() => {
    setPage(1);
    getPaymentDetails(1);
  }, [status]);

  if (apiStatus === API_STATUS.ERROR) {
    return (
      <div>
        <NoDataAvailable
          title="Error"
          subTitle={"Something went wrong"}
          image={IconConstant.DATA_NOT_FOUND_ICON}
        />
      </div>
    );
  }

  return apiStatus == API_STATUS.LOADING ? (
    <TablePlaceholder
      rows={6}
      columns={6}
      rowHeight="40px"
      columnHeaderHeight="40px"
    />
  ) : (
    <div>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={40}
        minWidth={750}
        filterMode="server"
        leftPinnedColumns={["name"]}
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={20}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
        defaultSortColumn={{ field: "_id", sort: "desc" }}
      />
    </div>
  );
};

export default CaDocPaymentTable;
