export const DocumentCategoryType = {
  PRE_EXECUTION_DOCUMENTS: "pre_execution_documents",
  EXECUTION_DOCUMENTS: "execution_documents",
  FILINGS_DOCUMENTS: "filings_documents",
  POST_TRANSFER_DOCUMENTS: "post_transfer_documents",
  EXIT_DOCUMENTS: "exit_documents",
};

export const DocumentTypes = {
  onboarding: "Onboarding",
  navdocument: "NAV",
  unitstatement: "Unit_Statements",
  exitdocumet: "Exit_Document",
  tax: "Tax",
  fund: "FUND",
};

export const QuarterOptions = [
  {
    label: "Q1",
    value: "Q1",
  },
  {
    label: "Q2",
    value: "Q2",
  },
  {
    label: "Q3",
    value: "Q3",
  },
  {
    label: "Q4",
    value: "Q4",
  },
];

export const DocumentTypeNames = [
  {
    label: "Onboarding",
    value: DocumentTypes.onboarding,
  },
  {
    label: "NAV",
    value: DocumentTypes.navdocument,
  },
  {
    label: "Unit Statements",
    value: DocumentTypes.unitstatement,
  },
  {
    label: "Exit Document",
    value: DocumentTypes.exitdocumet,
  },
  {
    label: "Tax",
    value: DocumentTypes.tax,
  },
  {
    label: "Fund",
    value: DocumentTypes.fund,
  },
];

export const DocumentCategoryOptions = [
  {
    label: "Pre Execution Documents",
    value: DocumentCategoryType.PRE_EXECUTION_DOCUMENTS,
  },
  {
    label: "Execution Documents",
    value: DocumentCategoryType.EXECUTION_DOCUMENTS,
  },
  {
    label: "Filling Documents",
    value: DocumentCategoryType.FILINGS_DOCUMENTS,
  },
  {
    label: "Post Transfer Documents",
    value: DocumentCategoryType.POST_TRANSFER_DOCUMENTS,
  },
  {
    label: "Exit Documents",
    value: DocumentCategoryType.EXIT_DOCUMENTS,
  },
];

export const InvestorDocumentNameOptions = [
  {
    label: "Nominee Form",
    value: "Nominee Form",
  },
  {
    label: "CKYC Form",
    value: "CKYC Form",
  },
  {
    label: "Bank Statement/ Cancelled Cheque",
    value: "Bank Statement/ Cancelled Cheque",
  },
  {
    label: "PAN Card",
    value: "PAN Card",
  },
  {
    label: "Proof of Identity (Passport / DL / Aadhaar)",
    value: "Proof of Identity (Passport / DL / Aadhaar)",
  },
  {
    label: "CA signed document",
    value: "CA signed document",
  },
  {
    label: "Exit Document",
    value: "Exit Document",
  },
];

export const BreadCrumbDataDealDocument = {
  stepKey: 1,
  steps: [
    {
      title: "Document Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Deal Documents",
    navigationButton: false,
  },
};

export const BreadCrumbDataInvestorDocument = {
  stepKey: 1,
  steps: [
    {
      title: "Document Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Investor Documents",
    navigationButton: false,
  },
};
