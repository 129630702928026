import requestManager from "../../../api/requestManager";

export const getSyndicateDealDocuments = (page: number) =>
  requestManager.apiGet(`/v1/syndicate_management/ps_manager/?page=${page}`);

export const createSyndicate = (data: any) =>
  requestManager.apiPost(
    `/v1/syndicates/create_or_update_syndicate/?data_source=lv_admin`,
    data,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const updateSyndicate = (data: any, permalink: any) =>
  requestManager.apiPut(
    `/v1/syndicates/create_or_update_syndicate/?data_source=lv_admin&permalink=${permalink}`,
    data
  );

export const getSyndicateDetail = (id: string) =>
  requestManager.apiGet(`/v1/syndicates/get_admin_syndicate_details/?id=${id}`);

export const deleteSyndicateLeadCarry = (
  syndicate_id: string,
  investor_id: string
) =>
  requestManager.apiGet(
    `/v1/syndicates/delete_lead_carry_for_syndicate/?syndicate_id=${syndicate_id}&investor_id=${investor_id}`
  );

export const addSyndicateLeadCarry = (data: any) =>
  requestManager.apiPost(`/v1/syndicates/add_lead_carry_for_syndicate/`, data);

export const createStartup = (data: any) =>
  requestManager.apiPost(
    `/v1/syndicates/create_or_update_startup/?data_source=lv_admin&type_of_action=create`,
    data,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const updateStartup = (data: any) =>
  requestManager.apiPut(
    `/v1/syndicates/create_or_update_startup/?data_source=lv_admin&type_of_action=update`,
    data
  );
