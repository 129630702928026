import requestManager from "../../../api/requestManager";

export const fetchCrmList = (type: string) => {
  const finalURL = `/v1/admin/commitment_management/commit_tracker/fetch_crm_list/?type=${type}`;
  return requestManager.apiGet(finalURL);
};

export const fetchCrmData = (type: string, tab: string, data: any) => {
  const params =
    type === "startups"
      ? `startup_id=${data.value}`
      : `investor_id=${data.investor_id}&investor_type=${data.investor_type}`;
  const finalURL = `/v1/admin/commitment_management/commit_tracker/fetch_crm_data/?type=${type}&tab=${tab}&${params}`;
  return requestManager.apiGet(finalURL);
};

export const updateCrmTrackerData = (data: any) => {
  const finalURL = `/v1/admin/commitment_management/commit_tracker/update_crm_data/`;
  return requestManager.apiPost(finalURL, data);
};

export const sendDrawdown = (data: any) => {
  const finalURL = `/v1/investor_drawdown/sent_drawdown_to_investor/`;
  return requestManager.apiPost(finalURL, data);
};

export const unlockCommit = (investor_commitment_id: any) => {
  const finalURL = `/v1/admin/commitment_management/commit_tracker/unlock_commit/?investor_commitment_id=${investor_commitment_id}`;
  return requestManager.apiPut(finalURL, {});
};

export const createPaymentLink = (data: any) => {
  const finalURL = `/v1/order/`;
  return requestManager.apiPost(finalURL, data);
};
export const commitWithdrawnInfo = (page: number) => {
  const finalURL = `/v1/admin/commitment_management/commit_withdrawn_info/?page=${page}`;
  return requestManager.apiGet(finalURL);
};

export const getPaymentStatus = (queryString: string) => {
  const finalURL = `/v1/investor_payments/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getPostCommitStatusList = (selectedTab: string, page: number) => {
  const finalURL = `/v1/admin/commitment_management/post_commit_status/?tab=${selectedTab}&page=${page}`;
  return requestManager.apiGet(finalURL);
};