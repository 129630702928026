import { Box, Grid, Stack, Typography, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import SchemeManager from "../../managers/schemeManager";
import { FormPlaceholder } from "../../../../ui-library/placeholders/form-placeholder/FormPlaceholder";
import { schemeFormPlaceholderConfig } from "../../constants/CADocConstants";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";
import { DatePicker } from "../../../../ui-library/form-elements/DatePicker";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { toast } from "react-toastify";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";

const DefaultInitialValues = {
  leadCarry: "",
  roundType: "",
  hurdleRate: "",
  transactionFees: "",
  fundCarry: "",
  dealProgress: "",
  totalFundraising: "",
  preMoneyValuation: "",
  pricePerShare: "",
  portfolioEntityProfile: "",
  numberOfSecurities: "",
  conversionPriceTerms: "",
  investmentSchemeName: "",
  rightUnderNegotiation: "",
  lastConsentDate: new Date(),
};

const validationScheme = Yup.object().shape({
  leadCarry: Yup.number().required("Lead Carry is required"),
  roundType: Yup.string().required("Investment Type is required"),
  hurdleRate: Yup.number().required("Hurdle Rate is required"),
  transactionFees: Yup.number().required("Transaction Fees are required"),
  fundCarry: Yup.number().required("Fund Carry is required"),
  dealProgress: Yup.number().required("Deal Progress is required"),
  totalFundraising: Yup.number().required("Total Fundraising is required"),
  preMoneyValuation: Yup.number().required("Pre Money Valuation is required"),
  pricePerShare: Yup.number().required("Price Per Share is required"),
  portfolioEntityProfile: Yup.string().required(
    "Portfolio Entity Profile is required"
  ),
  numberOfSecurities: Yup.number().required("Number of Securities is required"),
  conversionPriceTerms: Yup.string().when("roundType", {
    is: (val: any) => val !== "equity",
    then: (schema) => schema.required("Conversion Price Terms are required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  investmentSchemeName: Yup.string().required(
    "Investment Scheme Name is required"
  ),
  rightUnderNegotiation: Yup.string().required(
    "Right Under Negotiation is required"
  ),
  lastConsentDate: Yup.date().required("Last Consent Date is required"),
});

const NewSchemeGeneration: React.FC = () => {
  const [selectedVal, setSelectedVal] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>(DefaultInitialValues);
  const [schemeDocList, setSchemeDocList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const schemeManager = new SchemeManager();
  const [openSchemeNotFoundModal, setOpenSchemeNotFoundModal] = useState(false);

  const handleSchemeNotFoundModal = () => {
    setOpenSchemeNotFoundModal(!openSchemeNotFoundModal);
  };

  const fetchStartups = async (search: string) => {
    try {
      const result = await schemeManager.getAdminLookupData(
        "startups_as_per_tab",
        search,
        "new"
      );
      setOptions(result);
    } catch (error: any) {
      console.error(error);
    }
  };

  const fetchSchemeData = async (selected: any) => {
    try {
      if (selected?.value) {
        setLoading(true);
        const result = await schemeManager.getSchemeAndStartupData(
          selected.value
        );
        if (result === "Scheme Not Found") {
          handleSchemeNotFoundModal();
          setSelectedVal(null);
          setOptions([]);
        } else {
          setInitialValues(result?.specific_data);
          setSchemeDocList(result?.scheme_document_list);
        }
        setLoading(false);
      }
    } catch (error: any) {
      console.error(error);
      setLoading(false);
    }
  };

  const updateScheme = async (values: any) => {
    try {
      const data = {
        startup_id: selectedVal.value,
        total_lead_carry: values.leadCarry,
        round_type: values.roundType,
        hurdle_rate: values.hurdleRate,
        transaction_fees: values.transactionFees,
        fund_carry: values.fundCarry,
        commitment_via_lv: values.dealProgress,
        funding_ask: values.totalFundraising,
        pre_money_valuation: values.preMoneyValuation,
        share_price_with_text: values.pricePerShare,
        startup_service: values.portfolioEntityProfile,
        securities_description: values.numberOfSecurities,
        conversion_terms: values.conversionPriceTerms,
        scheme_name: values.investmentSchemeName,
        right_under_negotiations: values.rightUnderNegotiation,
        last_date_to_consent: values.lastConsentDate,
      };
      await schemeManager.updateSchemeDocument(data);
      toast.success("Scheme updated successfully!");
    } catch (error: any) {
      console.error(error);
    }
  };

  const generateScheme = async (values: any) => {
    try {
      const data = {
        startup_id: selectedVal.value,
      };
      await schemeManager.generateSchemeDocument(data);
      toast.success("Scheme generated successfully!");
    } catch (error: any) {
      console.error(error);
    }
  };

  let columns = [
    {
      field: "scheme_type",
      headerName: "Scheme Type",
      flex: 0.7,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "scheme_pdf_url",
      headerName: "Document",
      align: "center",
      minWidth: 100,
      renderCell: (params: any) => {
        const row = params?.row;
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("action_button", 200),
              borderColor: getColor("action_button", 200),
              width: "min-content",
            }}
            tableAction
            disabled={!row?.scheme_pdf_url}
            onClick={() => window.open(row?.scheme_pdf_url, "_blank")}
          >
            View
          </OutlinedButton>
        );
      },
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationScheme}
      onSubmit={updateScheme}
      enableReinitialize
    >
      {({ values, errors, handleSubmit }) => (
        <FormContainer>
          <Form>
            <Stack gap={2} mb={3}>
              <LvAutoComplete
                label="Select Startup"
                options={options}
                setOptions={setOptions}
                selectedObject={selectedVal}
                setSelectedObject={setSelectedVal}
                onChange={fetchStartups}
                onSelect={fetchSchemeData}
              />
              {loading ? (
                <FormPlaceholder elementsConfig={schemeFormPlaceholderConfig} />
              ) : (
                selectedVal && (
                  <>
                    <InputField
                      label="Total Lead Carry"
                      fieldName="leadCarry"
                      id="leadCarry"
                      name="leadCarry"
                      value={values.leadCarry}
                      error={!!errors.leadCarry}
                      type="number"
                      disabled
                    />
                    <InputField
                      label="Round type"
                      fieldName="roundType"
                      id="roundType"
                      name="roundType"
                      value={values.roundType}
                      error={!!errors.roundType}
                      disabled
                    />
                    <InputField
                      label="Hurdle rate"
                      fieldName="hurdleRate"
                      id="hurdleRate"
                      name="hurdleRate"
                      value={values.hurdleRate}
                      error={!!errors.hurdleRate}
                      type="number"
                      disabled
                    />
                    <InputField
                      label="Transaction fees"
                      fieldName="transactionFees"
                      id="transactionFees"
                      name="transactionFees"
                      value={values.transactionFees}
                      error={!!errors.transactionFees}
                      type="number"
                      disabled
                    />
                    <InputField
                      label="Fund carry"
                      fieldName="fundCarry"
                      id="fundCarry"
                      name="fundCarry"
                      value={values.fundCarry}
                      error={!!errors.fundCarry}
                      type="number"
                      disabled
                    />
                    <InputField
                      label="Deal progress on LV"
                      fieldName="dealProgress"
                      id="dealProgress"
                      name="dealProgress"
                      value={values.dealProgress}
                      error={!!errors.dealProgress}
                      type="number"
                      disabled
                    />
                    <InputField
                      label="Total fundraising"
                      fieldName="totalFundraising"
                      id="totalFundraising"
                      name="totalFundraising"
                      value={values.totalFundraising}
                      error={!!errors.totalFundraising}
                      type="number"
                      disabled
                    />
                    <InputField
                      label="Pre Money Valuation"
                      fieldName="preMoneyValuation"
                      id="preMoneyValuation"
                      name="preMoneyValuation"
                      value={values.preMoneyValuation}
                      error={!!errors.preMoneyValuation}
                      type="number"
                      disabled
                    />
                    <InputField
                      label="Price per share/Unit of Security"
                      fieldName="pricePerShare"
                      id="pricePerShare"
                      name="pricePerShare"
                      value={values.pricePerShare}
                      error={!!errors.pricePerShare}
                      type="number"
                    />
                    <InputField
                      label="Portfolio Entity profile and product details/services offered by the Portfolio Entity"
                      fieldName="portfolioEntityProfile"
                      id="portfolioEntityProfile"
                      name="portfolioEntityProfile"
                      value={values.portfolioEntityProfile}
                      error={!!errors.portfolioEntityProfile}
                    />
                    <InputField
                      label="Number of Securities"
                      fieldName="numberOfSecurities"
                      id="numberOfSecurities"
                      name="numberOfSecurities"
                      value={values.numberOfSecurities}
                      error={!!errors.numberOfSecurities}
                      type="number"
                    />
                    <InputField
                      label="Conversion Price Terms"
                      fieldName="conversionPriceTerms"
                      id="conversionPriceTerms"
                      name="conversionPriceTerms"
                      value={values.conversionPriceTerms}
                      error={!!errors.conversionPriceTerms}
                    />
                    <InputField
                      label="Investment Scheme Name"
                      fieldName="investmentSchemeName"
                      id="investmentSchemeName"
                      name="investmentSchemeName"
                      value={values.investmentSchemeName}
                      error={!!errors.investmentSchemeName}
                      disabled
                    />
                    <InputField
                      label="Right under negotiation"
                      fieldName="rightUnderNegotiation"
                      id="rightUnderNegotiation"
                      name="rightUnderNegotiation"
                      value={values.rightUnderNegotiation}
                      error={!!errors.rightUnderNegotiation}
                    />
                    <DatePicker
                      placeholder="Last date to provide consent to participate in the investment scheme"
                      alignCalenderIcon="right"
                      iconColor={getColor("primary", 700)}
                      required
                      name="lastConsentDate"
                      id="lastConsentDate"
                      error={!!errors?.lastConsentDate}
                      value={values?.lastConsentDate}
                      minDate={
                        new Date(new Date().setDate(new Date().getDate() + 1))
                      }
                    />
                    {selectedVal && schemeDocList?.length > 0 ? (
                      <Box width="min-width">
                        <br />
                        <LvComplexTable
                          rows={schemeDocList}
                          columns={columns}
                          rowHeight={40}
                          headerHeight={40}
                          // minWidth={750}
                          filterMode="server"
                          enableAdvancedFiltering
                          isUnFilteredDataPresent={!isEmpty(schemeDocList)}
                          enableAutoHeight={true}
                          hideToobar
                        />
                      </Box>
                    ) : null}
                  </>
                )
              )}
              {selectedVal && (
                <Stack direction="row" gap={2} justifyContent="end" mt={2}>
                  <ActionButton onClick={handleSubmit}>Save</ActionButton>
                  <ActionButton onClick={generateScheme} type="button">
                    Generate
                  </ActionButton>
                </Stack>
              )}

              <CommonModal
                isOpen={openSchemeNotFoundModal}
                handleClose={handleSchemeNotFoundModal}
                handleSubmit={handleSchemeNotFoundModal}
                title="Scheme Not Found"
                submitText="Okay"
                buttonStyles={{ width: "100%" }}
                closeIcon
              >
                The Scheme is currently unavailable. Please add it to Zoho and
                try generating it again.
              </CommonModal>
            </Stack>
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};

export default NewSchemeGeneration;
