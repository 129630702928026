import React, { ReactNode, useState } from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getColor } from "../../Styles/Colors";

export type AccordionPanelProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;
  /**
   * classNames prop for custom classes
   */
  classNames?: string;
  /**
   * accordion custom styles
   */
  accordionCustomStyles?: object;
  /**
   * accordion summary section custom styles
   */
  accordionSummaryCustomStyles?: object;
  /**
   * accordion details section custom styles
   */
  accordionDetailsCustomStyles?: object;
  /**
   * Initialise with expanded state
   */
  defaultExpanded?: boolean;
  /**
   * summary section to render
   */
  accordionSummaryContent: string | ReactNode;
  /**
   * icon to show in summary when expanded
   */
  expandedSummaryIcon?: ReactNode;
  /**
   * icon to show in summary when collapsed
   */
  collapsedSummaryIcon?: ReactNode;
  /**
   * callback that will be triggered when expanded or collapsed
   */
  onToggleExpansion?: (event: React.SyntheticEvent, state: boolean) => void;
};

export function AccordionPanel({
  classNames,
  defaultExpanded = false,
  onToggleExpansion,
  accordionCustomStyles = {},
  accordionSummaryCustomStyles = {},
  accordionDetailsCustomStyles = {},
  accordionSummaryContent,
  expandedSummaryIcon,
  collapsedSummaryIcon,
  children,
}: AccordionPanelProps) {
  const [isExpanded, setExpanded] = useState(defaultExpanded);

  const handleAccordionChange = (event: any, state: any) => {
    setExpanded(state);
    onToggleExpansion && onToggleExpansion(event, state);
  };

  const expandedIcon = expandedSummaryIcon ?? (
    <RemoveIcon
      sx={{
        fontSize: "20px",
        color: getColor("primary", 700),
        fontWeight: "500",
        marginLeft: "4px",
      }}
    />
  );

  const collapsedIcon = collapsedSummaryIcon ?? (
    <AddIcon
      sx={{
        fontSize: "20px",
        color: getColor("primary", 700),
        fontWeight: "500",
        marginRight: "4px",
      }}
    />
  );

  return (
    <div className={classNames}>
      <MuiAccordion
        sx={{ ...accordionCustomStyles }}
        disableGutters
        elevation={0}
        square
        expanded={isExpanded}
        onChange={handleAccordionChange}
      >
        <MuiAccordionSummary
          sx={{
            backgroundColor: getColor("primary", 50),
            color: getColor("primary", 700),
            flexDirection: "row-reverse",
            ...accordionSummaryCustomStyles,
          }}
          expandIcon={isExpanded ? expandedIcon : collapsedIcon}
        >
          {accordionSummaryContent}
        </MuiAccordionSummary>
        <MuiAccordionDetails
          sx={{ padding: 0, ...accordionDetailsCustomStyles }}
        >
          {children}
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
}
