export const formPlaceholderConfig = Array(8).fill("input");

export type UserDetailParams = {
  user_id: string;
};

export const PlanTypeOptionsArray = new Map([
  [
    "365_days_premium",
    { label: "365 days (premium)", value: "365_days_premium" },
  ],
  [
    "1825_days_premium",
    { label: "1825 days (premium)", value: "1825_days_premium" },
  ],
]);

export const PlanTypeObj = new Map([
  [
    "365_days_premium",
    {
      plan_type: "premium",
      plan_duration_in_days: 365,
    },
  ],
  [
    "1825_days_premium",
    {
      plan_type: "premium",
      plan_duration_in_days: 1825,
    },
  ],
]);

export const ShowUserTabsArray: any = [
  {
    name: "Basic Details",
    value: "basic_details",
    actionTitle: "Edit Basic Details",
  },
  {
    name: "Investment Profiles",
    value: "investment_profiles",
    actionTitle: "Edit Investment Profiles",
  },
  {
    name: "Access Details",
    value: "access_details",
    actionTitle: "Edit Access Details",
  },
  {
    name: "Subscription Details",
    value: "subscription_details",
    actionTitle: "Manage Subscription",
  },
];

export const ShowUserActionButtonOptions: any = [
  {
    label: "Update Profile",
  },
  {
    label: "Switch to This User",
  },
];
