import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import UserManagementManager from "../../../managers/userManagementManager";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../../../../contexts/authContext";
import { HorizontalTabs } from "../../../../../ui-library/tabs/horizontal-tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { BreadcrumbShowUser } from "../../../constants/informationConstants";
import { DropdownButton } from "../../../../../ui-library/buttons/DropdownButton";
import StartupPanelProgressSkeleton from "../../../../startupManagement/skeleton/StartupPanelSkeleton";
import UserBasicDetails from "./BasicDetails";
import AccessDetails from "./AccessDetails";
import InvestmentProfiles from "./InvestmentProfiles";
import SubscriptionDetails from "./SubscriptionDetails";
import { redirectToLetsventure } from "../../../../../utils/commonUtils";
import {
  ShowUserActionButtonOptions,
  ShowUserTabsArray,
} from "../../../constants/USER_PANEL_CONSTANTS";

type QuizParams = {
  user_id: string;
};

export default function ShowUser() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { user_id = "" } = useParams<QuizParams>();
  const { selectedTab: preSelectedTab = null } = location?.state ?? {};
  const userManagementManager = new UserManagementManager(authContext);
  const [selectedTab, setSelectedTab] = useState(ShowUserTabsArray[0]?.value);

  let breadcrumbData = BreadcrumbShowUser;

  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<any>({});

  const getData = async (id: string) => {
    try {
      setLoading(true);
      const { user, angel_investor_data, user_role } =
        await userManagementManager.getUserDetail(id);

      setDetail({ ...user, ...angel_investor_data, ...user_role });
      breadcrumbData.pageHeader.header = user?.name;

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Something Went Wrong");
      navigate(-1);
    }
  };

  useEffect(() => {
    getData(user_id);
    if (preSelectedTab) {
      setSelectedTab(preSelectedTab);
    }
  }, [user_id, preSelectedTab]);

  const handleDropdownOptionClick = (option: string) => {
    if (option === "Update Profile") {
      const URL = `/profile/setup?user_name=${detail?.user_name}/`;
      redirectToLetsventure(URL);
    } else if (option === "Switch to This User") {
      redirectToLetsventure("/", true, true, true);
    } else if (option === "Commitment Action") {
      navigate(`/user/user-panel/${user_id}/edit/commit-actions`, {
        state: { detail },
      });
    }
  };

  const handleEditModalOpen = () => {
    if (selectedTab === "basic_details") {
      navigate(`/user/user-panel/${user_id}/edit/basic`, {
        state: { detail },
      });
    } else if (selectedTab === "access_details") {
      navigate(`/user/user-panel/${user_id}/edit/access`, {
        state: { detail },
      });
    } else if (selectedTab === "subscription_details") {
      navigate(`/user/user-panel/${user_id}/edit/subscription`, {
        state: { detail },
      });
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={4}>
          <Breadcrumb
            steps={breadcrumbData?.steps}
            pageHeader={{
              ...breadcrumbData?.pageHeader,
              navigationFunction: () => navigate("/user/user-panel"),
            }}
            stepKey={breadcrumbData.stepKey}
            breadcrumbSeparator={true}
          />
        </Grid>
        <Grid item xs={8} display="flex" gap={2} justifyContent="end">
          <DropdownButton
            buttonText="Actions"
            options={[
              ...ShowUserActionButtonOptions,
              {
                label: "Commitment Action",
                disabled: !detail?.investment_profiles_data?.length,
              },
            ]}
            onOptionClick={(option: string) =>
              handleDropdownOptionClick(option)
            }
            menuItemStyle={{ width: "240px" }}
          />

          <ActionButton
            onClick={handleEditModalOpen}
            disabled={
              loading ||
              selectedTab === "investment_profiles" ||
              (selectedTab === "subscription_details" &&
                detail?.subscription_data?.active)
            }
          >
            {
              ShowUserTabsArray.find((tab: any) => tab.value === selectedTab)
                ?.actionTitle
            }
          </ActionButton>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={ShowUserTabsArray}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />

      {loading ? (
        <StartupPanelProgressSkeleton />
      ) : (
        <>
          {selectedTab === "basic_details" ? (
            <UserBasicDetails details={detail} />
          ) : null}
          {selectedTab === "access_details" ? (
            <AccessDetails details={detail} />
          ) : null}
          {selectedTab === "investment_profiles" ? (
            <InvestmentProfiles details={detail} />
          ) : null}
          {selectedTab === "subscription_details" ? (
            <SubscriptionDetails details={detail} />
          ) : null}
        </>
      )}
    </Box>
  );
}
