import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import * as Yup from "yup";
import { useState } from "react";
import UserManagementManager from "../../managers/userManagementManager";
import { toast } from "react-toastify";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";

const initialValues = {
  name: "",
  email: "",
  addPromotion: true,
  onboardingFees: 40000,
  upfrontDrawdown: 40000,
  placementFees: 0,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  onboardingFees: Yup.number().when("addPromotion", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Onboarding Fee is required"),
  }),
  upfrontDrawdown: Yup.number().when("addPromotion", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Upfront Drawdown is required"),
  }),
  placementFees: Yup.number().when("addPromotion", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Placement Fee is required"),
  }),
});

export default function AddUser() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [submitting, setSubmitting] = useState(false);

  const handleAddUser = async (values: any, actions: any) => {
    try {
      setSubmitting(true);
      const result = await userManagementManager.addUser(values);
      setSubmitting(false);
      if (result?.data) toast.success("User added successfully");
      actions.resetForm();
    } catch (error: any) {
      toast.error(error?.detail);
      setSubmitting(false);
    }
  };
  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "Add New User",
      navigationButton: false,
    },
  };
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={handleAddUser}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          setTouched,
          setFieldValue,
          handleSubmit,
        }) => {
          return (
            <FormContainer>
              <Form>
                <Stack gap={2}>
                  <InputField
                    label="Full Name"
                    error={errors?.name}
                    id="name"
                    name="name"
                    value={values?.name}
                    required
                  />
                  <InputField
                    label="Email"
                    error={errors?.email}
                    id="email"
                    name="email"
                    value={values?.email}
                    required
                  />
                  <FormControlLabel
                    label="Add Promotion"
                    id="addPromotion"
                    checked={values?.addPromotion}
                    value={values?.addPromotion}
                    onChange={(e: any) => {
                      setTouched({ ...touched, addPromotion: true });
                      setFieldValue("addPromotion", e.target.checked);
                    }}
                    control={<Checkbox />}
                  />
                  {values?.addPromotion ? (
                    <>
                      {" "}
                      <InputField
                        label={"Onboarding Fee"}
                        error={false}
                        id="onboardingFees"
                        name="onboardingFees"
                        fieldName="onboardingFees"
                        value={values?.onboardingFees}
                        type="number"
                        required
                      />
                      <InputField
                        label={"Upfront Drawdown"}
                        error={false}
                        id="upfrontDrawdown"
                        name="upfrontDrawdown"
                        fieldName="upfrontDrawdown"
                        value={values?.upfrontDrawdown}
                        type="number"
                        required
                      />
                      <InputField
                        label={"Placement Fee"}
                        error={false}
                        id="placementFees"
                        name="placementFees"
                        fieldName="placementFees"
                        value={values?.placementFees}
                        type="number"
                        required
                      />{" "}
                    </>
                  ) : null}
                  <Stack direction="row" gap={1} justifyContent="end">
                    <ActionButton
                      onClick={handleSubmit}
                      disabled={submitting}
                      isLoading={submitting}
                    >
                      Create User
                    </ActionButton>
                  </Stack>
                </Stack>
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </Box>
  );
}
