import { Tooltip, Typography } from "@mui/material";
import React, { FC } from "react";
import { LinkButton } from "../../../../../ui-library/buttons/LinkButton";
type Props = { details: any };

const RenderFounders: FC<Props> = ({ details }) => {
  return details?.founders?.length ? (
    details?.founders?.length > 1 ? (
      <>
        <Typography align="left" variant="heading_04_medium" fontWeight={600}>
          <LinkButton
            title={details?.founders?.[0]?.name}
            onClick={() =>
              window.open(
                `/user/user-panel/${details?.founders?.[0]?.id}`,
                "_blank"
              )
            }
          />

          <Tooltip
            title={details?.founders.slice(1).map((founder: any) => (
              <LinkButton
                title={founder?.name}
                onClick={() =>
                  window.open(`/user/user-panel/${founder?.id}`, "_blank")
                }
              />
            ))}
            placement="top-start"
            arrow
            componentsProps={{
              tooltip: { sx: { background: "#f2f2f2", color: "#000" } },
            }}
          >
            <Typography display="inline" variant="paragraph_03_bold">
              <LinkButton title={`+ ${details?.founders?.length - 1} more`} />{" "}
            </Typography>
          </Tooltip>
        </Typography>
      </>
    ) : (
      <LinkButton
        title={details?.founders?.[0]?.name}
        onClick={() =>
          window.open(
            `/user/user-panel/${details?.founders?.[0]?.id}`,
            "_blank"
          )
        }
      />
    )
  ) : (
    <p>-</p>
  );
};

export default RenderFounders;
