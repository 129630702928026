import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { dropdownStyle } from "./core-dropdown-styles";
import { Checkbox } from "@mui/material";

interface MultipleSelectCheckmarksProps {
  options: string[];
  label: string;
  selectedValues: string[];
  onChange?: (selected: string[]) => void;
  disabled?: boolean;
}

export default function MultipleSelectCheckmarks({
  options,
  label,
  selectedValues,
  onChange,
  disabled,
}: MultipleSelectCheckmarksProps) {
  const handleChange = (value: any) => {
    onChange && onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Autocomplete
      multiple
      id="combo-box-demo"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option}
      value={selectedValues}
      onChange={(event, newValue: any) => {
        handleChange(newValue);
      }}
      disabled={disabled}
      sx={dropdownStyle}
      renderOption={(props, option: any, { selected }) => (
        <li {...props}>
          <Checkbox
            checked={selected}
            sx={{
              padding: 0,
              paddingRight: 1,
            }}
          />
          {option}
        </li>
      )}
      renderTags={() => null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedValues.join(", "),
          }}
        />
      )}
    />
  );
}
