export const investorCommunicationBreadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Commited Investor Communication",
    navigationButton: false,
  },
};

export const update_types = [
  { label: "EGM/AGM/SCN", value: "egm_or_agm_or_scn" },
  {
    label: "Future Fundraise/Preemptive/Rights Issue",
    value: "future_fundraise_or_preemptive_or_rights_issue",
  },
  { label: "Distress", value: "distress" },
  { label: "Exit", value: "exit" },
  { label: "Conversion", value: "conversion" },
  { label: "Others", value: "others" },
];
