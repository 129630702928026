import { FC, useContext, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { FileUpload } from "../../../../../ui-library/form-elements/file-upload/FileUpload";
import { Dropdown } from "../../../../../ui-library/dropdowns/core-dropdown";
import { AuthContext } from "../../../../../contexts/authContext";
import { toast } from "react-toastify";
import {
  DocumentTypeNames,
  QuarterOptions,
} from "../../../constants/documentConstants";
import DocumentManagement from "../../../managers/documentManager";
import { DatePicker } from "../../../../../ui-library/form-elements/DatePicker";
import { Formik } from "formik";
import { OutlinedButton } from "../../../../../ui-library/buttons/OutlinedButton";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import * as Yup from "yup";

type ReactBulkUploadModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
};

const BulkUploadModal: FC<ReactBulkUploadModalType> = (props) => {
  const { isOpen, handleClose } = props;
  const authContext = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(authContext);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    typeOfFile: null,
    year: "",
    quarter: null,
    file: [],
  };

  const validationSchema = Yup.object().shape({
    typeOfFile: Yup.object().required("Type of file is required"),
    year: Yup.date().required("Year is required"),
    quarter: Yup.object().when("typeOfFile", {
      is: (val: any) => val?.value === "Unit_Statements",
      then: (schema: any) => schema.required("Quarter is required"),
      otherwise: (schema: any) => schema.notRequired(),
    }),
    file: Yup.array().min(1).required("File is required"),
  });

  const DocumentCategoryOptionsExtended = [
    ...DocumentTypeNames?.filter(
      (item) => item?.value !== "Onboarding" && item?.value !== "FUND"
    ),
  ];

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const data: any = {
        typeOfFile: values?.typeOfFile?.value,
        year: values?.year,

        file: values?.file[0],
      };
      if (values?.typeOfFile?.value === "Unit_Statements") {
        data.quarter = values?.quarter?.value;
      }
      const result = await documentManagementManager.uploadBulkDocument(data);
      toast.success("Document Uploaded Successfully");
      setIsLoading(false);
      handleClose();
    } catch (error: any) {
      toast.error(error.message ?? "Something Went Wrong");
      setIsLoading(false);
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Bulk Upload Document"
      hideButtons
      buttonStyles={{ width: "min-width" }}
      closeIcon
    >
      <Box width="30vw">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue, handleSubmit }: any) => (
            <Box>
              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Dropdown
                      id="typeOfFile"
                      name="typeOfFile"
                      required
                      fieldName="typeOfFile"
                      label="Choose Document Type"
                      options={DocumentCategoryOptionsExtended}
                      value={values?.typeOfFile}
                      error={
                        errors.typeOfFile && touched.typeOfFile
                          ? errors.typeOfFile
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DatePicker
                      placeholder="Choose Year"
                      id="year"
                      name="year"
                      fieldName="year"
                      value={values?.year}
                      onChange={(value: any) => setFieldValue("year", value)}
                      required
                      alignCalenderIcon="right"
                      views={["year"]}
                      error={errors.year && touched.year ? errors.year : ""}
                      touched={touched}
                      minDate={new Date("2020-01-01")}
                      maxDate={new Date()}
                    />
                  </Grid>
                </Grid>
              </Box>

              {values?.typeOfFile?.value === "Unit_Statements" && (
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Dropdown
                        id="quarter"
                        name="quarter"
                        fieldName="quarter"
                        label="Choose Quarter"
                        options={QuarterOptions}
                        value={values?.quarter}
                        required
                        error={
                          errors.quarter && touched.quarter
                            ? errors.quarter
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FileUpload
                      width="100%"
                      placeholder="select a file*"
                      helperText="You can upload zip file only. Maximum File Size 100MB."
                      selectedFile={(file: any) => setFieldValue("file", file)}
                      required
                      value={values?.file}
                      accept={{ "document/zip": [".zip"] }}
                      title="File Upload Dialog Box"
                      hideDocumentVault
                      error={errors.file && touched.file ? errors.file : ""}
                      maxFileSizeLimit={104857600}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Stack mt={3} gap={2} direction="row" justifyContent="flex-end">
                <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
                <ActionButton
                  isLoading={isLoading}
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  Submit
                </ActionButton>
              </Stack>
            </Box>
          )}
        </Formik>
      </Box>
    </CommonModal>
  );
};

export default BulkUploadModal;
