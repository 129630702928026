import { AuthContextProps, UserAuthInfo } from "../../../contexts/authContext";
import { getQueryString } from "../../../utils/apiUtils";
import {
  getInvestorInformation,
  getNomineeInformation,
  getDematInformation,
  unlockDematInformation,
  verifyDematInformation,
} from "../services/userInformationService";

export default class UserInformationManager {
  private userData: UserAuthInfo;

  constructor(AuthContext: AuthContextProps) {
    this.userData = AuthContext.authState;
  }
  async getInvestorInformation(
    search_text: string,
    page: number,
    ca_doc_sent_status: string,
    profile_type: string
  ) {
    try {
      const queryString = getQueryString({
        search_text,
        page,
        ca_doc_sent_status,
        profile_type,
      });
      let result: any = await getInvestorInformation(queryString);
      result = result.data;
      let formattedData = result?.results?.map((item: any) => ({
        ...item,
        // date: item.approval_date,
        // manager: item.relationship_manager,
        // phone_no: item.users_phone,
        location: item.city,
        onboarding_as: item.onboard,
        // type: item.investor_type,
      }));
      return { formattedData, count: result?.count };
    } catch (error) {
      throw error;
    }
  }

  async getNomineeInformation(search_text: string, page: number) {
    try {
      const queryString = getQueryString({ search_text, page });
      let result: any = await getNomineeInformation(queryString);
      result = result.data;
      let formattedData = result?.results?.map((item: any) => ({
        ...item,
        location: item.city,
        onboarding_as: item.onboard,
      }));
      return { formattedData, count: result?.count };
    } catch (error) {
      throw error;
    }
  }

  async getDematInformation(search_text: string, page: number, tab: string) {
    try {
      const queryString = getQueryString({ search_text, page, tab });
      let result: any = await getDematInformation(queryString);
      result = result.data;
      let formattedData = result?.results?.map((item: any) => ({
        ...item,

        location: item.city,
        onboarding_as: item.onboard,
      }));
      return { formattedData, count: result?.count };
    } catch (error) {
      throw error;
    }
  }

  async unlockDematInformation(id: any) {
    try {
      let result: any = await unlockDematInformation({
        investor_information_id: id,
      });
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async verifyDematInformation(id: any) {
    try {
      let result: any = await verifyDematInformation({
        investor_information_id: id,
      });
      return result?.data;
    } catch (error) {
      throw error;
    }
  }
}
