import React from "react";
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getColor } from "../../Styles/Colors";
import { MoreVert } from "@mui/icons-material";

export type ActionDropdownProps = {
  /**
   * An array of options to be rendered in dropdown menu
   */
  options?: {
    label: string;
    subText?: string;
    subTextStyle?: any;
    disabled?: boolean;
    labelStyle?: any;
  }[];

  /**
   * A callback function which receives the value of selected option
   */
  onOptionClick?: Function;
  /**
   * boolean to render button with filled background
   */
  fill?: boolean;
  /**
   * width to be applied on  button
   */
  width?: number | string;
  /**
   * prop to hide the button
   */
  hidden?: boolean;
  /**Custom styles to button */
  customStyles?: object;

  menuItemStyle?: object;
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    marginTop: theme.spacing(1),
    width: 230,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  height: "32px",
}));

const StyledDivider = styled(Divider)(() => ({
  margin: "0 !important",
}));

const StyledEmptyMenuItem = styled(MenuItem)(() => ({
  height: "32px",
  justifyContent: "center",
}));

export function ActionDropdown({
  onOptionClick,
  options,
  fill,
  width,
  customStyles = {},
  menuItemStyle = {},
  hidden = false,
}: ActionDropdownProps) {
  const [anchorElm, setAnchorElm] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElm);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElm(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElm(null);
  };

  const handleMenuClick = (value: string) => {
    handleClose();
    onOptionClick && onOptionClick(value);
  };

  if (hidden) {
    return <div />;
  }

  return (
    <div>
      <IconButton onClick={handleClick} aria-haspopup="true" aria-label="more">
        <MoreVert
          style={{
            color: fill ? "white" : getColor("action_button", 300),
            ...customStyles,
          }}
        />
      </IconButton>
      <StyledMenu
        sx={{ ...menuItemStyle }}
        anchorEl={anchorElm}
        open={open}
        onClose={handleClose}
      >
        {options ? (
          options.map(
            (
              { label, subText, subTextStyle = {}, disabled, labelStyle = {} },
              index
            ) => (
              <div key={`dropdown-button-${index}`}>
                <StyledMenuItem
                  disabled={disabled}
                  onClick={() => handleMenuClick(label)}
                >
                  <div>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontSize: 14,
                        ...labelStyle,
                      }}
                    >
                      {label}
                    </Typography>
                    {subText && (
                      <Typography sx={{ ...subTextStyle }}>
                        {subText}
                      </Typography>
                    )}
                  </div>
                </StyledMenuItem>
                {index !== options.length - 1 && <StyledDivider />}
              </div>
            )
          )
        ) : (
          <StyledEmptyMenuItem disabled>
            No Option Available
          </StyledEmptyMenuItem>
        )}
      </StyledMenu>
    </div>
  );
}
