import { useState, useEffect, useContext } from "react";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { NoDataAvailable } from "../../../../ui-library/error/NoDataAvailable";
import { IconConstant } from "../../../../constants/iconConstantss";
import { isEmpty } from "../../../../utils/commonUtils";
import DocumentManagement from "../../managers/documentManager";
import UploadDocumentModal from "./uploadDocumentModal";
import { AuthContext } from "../../../../contexts/authContext";
import { Box, Grid, Stack } from "@mui/material";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import {
  BreadCrumbDataDealDocument,
  DocumentCategoryOptions,
} from "../../constants/documentConstants";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import UnpublishModal from "./unPublishModal";
import PublishModal from "./publishModal";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

export default function DealDocuments() {
  const DocumentCategoryOptionsExtended = [...DocumentCategoryOptions];
  const [openUnpublishModal, setOpenUnpublishModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);

  const [selected, setSelected] = useState<any>();

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);
  const [page, setPage] = useState(1);

  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] =
    useState(false);
  const [documentCategory, setDocumentCategory] = useState<any>({});

  const [selectedDeal, setSelectedDeal] = useState<any>({});
  const [dealOptions, setDealOptions] = useState<any>([]);
  const handleSearchDealName = async (value: string) => {
    try {
      let result = await documentManagementManager.searchDealName(value);
      result = result?.data.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setDealOptions(result);
    } catch (error: any) {
      toast.error(error);
    }
  };

  const viewFile = (url: string) => {
    window.open(url, "_blank");
  };

  const columns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      sortable: false,
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "formated_document_category",
      headerName: "Document Category",
      sortable: false,

      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "formated_document_name",
      headerName: "Document Name",
      sortable: false,
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "notes",
      headerName: "Notes",
      sortable: false,
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "last_updated_by",
      headerName: "Last Updated By",
      sortable: false,
      headerAlign: "left",
      minWidth: 150,
    },
    {
      field: "last_updated_date",
      headerName: "Last Updated Date",
      headerAlign: "right",
      align: "right",
      minWidth: 180,
      type: "date",
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      minWidth: 250,
      align: "start",
      renderCell: (params: any) => {
        return (
          <Stack
            direction="row"
            gap={2}
            width={
              params.row?.document_url &&
              params.row.document_category === "execution_documents"
                ? "100%"
                : "46%"
            }
          >
            {params.row?.document_url && (
              <OutlinedButton
                customStyles={{
                  color: getColor("action_button", 300),
                  borderColor: getColor("action_button", 300),
                }}
                onClick={() => {
                  viewFile(params.row?.document_url);
                }}
                tableAction
              >
                View
              </OutlinedButton>
            )}
            {params.row.document_category === "execution_documents" && (
              <OutlinedButton
                customStyles={{
                  color: params.row?.publish
                    ? getColor("error", 500)
                    : getColor("action_button", 300),
                  borderColor: params.row?.publish
                    ? getColor("error", 500)
                    : getColor("action_button", 300),
                }}
                onClick={() => {
                  setSelected(params.row);
                  params.row?.publish
                    ? setOpenUnpublishModal(true)
                    : setOpenPublishModal(true);
                }}
                tableAction
              >
                {params.row?.publish ? "Unpublish" : "Publish"}
              </OutlinedButton>
            )}
          </Stack>
        );
      },
    },
  ];

  const getDealDocuments = async (
    currentPage = 1,
    deal_id?: string,
    document_category?: string
  ) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await documentManagementManager.getDealDocuments(
          currentPage,
          deal_id,
          document_category
        );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getDealDocuments(page + 1, selectedDeal?.value, documentCategory?.value);
  };

  useEffect(() => {
    getDealDocuments(1, selectedDeal?.value, documentCategory?.value);
  }, []);

  const handleSearch = () => {
    setPage(1);
    getDealDocuments(1, selectedDeal?.value, documentCategory?.value);
  };
  const reloadData = () => {
    getDealDocuments(1, selectedDeal?.value, documentCategory?.value);
  };

  if (apiStatus === API_STATUS.ERROR) {
    return (
      <div>
        <NoDataAvailable
          title="Error"
          subTitle={"Something went wrong"}
          image={IconConstant.DATA_NOT_FOUND_ICON}
        />
      </div>
    );
  }

  const handleUploadDocumentModal = () => {
    setIsUploadDocumentModalOpen(false);
  };

  return (
    <Box>
      <UnpublishModal
        isOpen={openUnpublishModal}
        handleClose={() => setOpenUnpublishModal(false)}
        selected={selected}
        reloadData={reloadData}
      />
      <PublishModal
        isOpen={openPublishModal}
        handleClose={() => setOpenPublishModal(false)}
        selected={selected}
        reloadData={reloadData}
      />

      <Grid container>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataDealDocument?.steps}
            pageHeader={BreadCrumbDataDealDocument.pageHeader}
            stepKey={BreadCrumbDataDealDocument.stepKey}
          />
        </Grid>
        <Grid item xs={9}>
          <Stack direction="row" gap={2} justifyContent="end">
            <ActionButton onClick={() => setIsUploadDocumentModalOpen(true)}>
              Upload Document
            </ActionButton>
          </Stack>
          <Stack direction="row" gap={2} justifyContent="end" mt={2} mb={1}>
            <Box>
              <LvAutoComplete
                label="Enter Deal Name"
                options={dealOptions}
                setOptions={setDealOptions}
                selectedObject={selectedDeal}
                setSelectedObject={setSelectedDeal}
                onSelect={() => setDealOptions([])}
                onChange={handleSearchDealName}
              />
            </Box>
            <Box width={"190px"}>
              <CoreDropdown
                label="Document Category"
                options={DocumentCategoryOptionsExtended}
                value={documentCategory}
                onClickMenuItem={(value: any) => setDocumentCategory(value)}
                clearable
                onClear={() => setDocumentCategory({})}
              />
            </Box>
            <ActionButton onClick={handleSearch}>Search</ActionButton>
          </Stack>
        </Grid>
      </Grid>

      <div>
        {apiStatus === API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            filterMode="server"
            leftPinnedColumns={["name"]}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
            defaultSortColumn={{ field: "last_updated_date", sort: "desc" }}
          />
        )}
      </div>
      {isUploadDocumentModalOpen && (
        <UploadDocumentModal
          openModal={isUploadDocumentModalOpen}
          handleModal={handleUploadDocumentModal}
          reloadData={reloadData}
        />
      )}
    </Box>
  );
}
