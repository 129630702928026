import { Box, Typography, Grid, Stack, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState, useCallback, useRef } from "react";
import { RadioButton } from "../../../../ui-library/form-elements/RadioButtonSelect";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import CrmTable from "./CrmTable";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { CommitTrackerBreadCrumbData } from "../../constants/CrmConstants";
import { CardContainer } from "../../../../ui-library/card/card-container";
import CommitTrackerSkeleton from "../skeletons/CommitTrackerSkeleton";
import { NoDataAvailable } from "../../../../ui-library/error/NoDataAvailable";
import { IconConstant } from "../../../../constants/IconConstants";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  analyticsContainer: {
    backgroundColor: "rgb(230 230 230)",
    height: "8rem",
    border: "1px solid #dedede",
    width: "100%",
    marginLeft: "1.5rem",
    marginTop: "1.5rem",
    borderRadius: "6px",
  },
  analyticsInnerContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    "& .MuiTypography-root": {
      fontWeight: 600,
    },
  },
});

const CommitTracker = () => {
  const ref1: any = useRef(null);

  const classes: any = useStyles();
  const tabs: any = [
    {
      name: "Progress",
      value: "progress",
    },
    {
      name: "Completed",
      value: "completed",
    },
  ];
  const [filter, setFilter] = useState<string>("startups");
  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const commitmentManagementManager = new CommitmentManagementManager();

  const handleTextChange = useCallback(async () => {
    try {
      let result = await commitmentManagementManager.getCrmList(filter);
      setOption(result?.formattedData);
      setFormattedData([]);
      // setSelectedVal({ label: '', value: null });
    } catch (err: any) {
      toast.error(err);
    }
  }, [filter]);

  const handleFilterChange = (val: any) => {
    setFilter(val);
    setSelectedVal(null);
    const elem = ref1?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    const inputElem = ref1?.current?.getElementsByClassName(
      "MuiOutlinedInput-input"
    )[0];

    if (elem) {
      elem.click();
    }
    if (inputElem) {
      inputElem.blur();
    }
  };

  const fetchCrmData = async (value: any) => {
    setLoadingDetail(true);
    try {
      let result = await commitmentManagementManager.getCrmData(
        filter,
        selectedTab,
        value
      );
      setFormattedData(result?.formattedData);
      setLoadingDetail(false);
    } catch (err) {
      setLoadingDetail(false);
    }
  };

  useEffect(() => {
    handleTextChange();
  }, [filter]);

  useEffect(() => {
    selectedVal?.value && fetchCrmData(selectedVal);
  }, [selectedTab]);

  const cardOptions: any = [
    {
      label: "No of investors",
      spacing: 3,
      value: formattedData?.length ?? "-",
    },
    {
      label: "No of drawdown sent",
      spacing: 3,
      value: formattedData.reduce((initial, item) => {
        item.aif_direct == "aif" &&
          !!item.ca_doc_status &&
          !!item.drawdown_sent &&
          initial++;
        return initial;
      }, 0),
    },
    {
      label: "Total amount received",
      spacing: 3,
      value: formattedData.reduce((initial, item) => {
        if (
          (item.aif_direct == "aif" &&
            !!item.ca_doc_status &&
            !!item.amount_transfer_status) ||
          (item.aif_direct == "direct" && !!item.amount_transfer_status)
        )
          initial = initial + Number(item.amount_transfered);
        return initial;
      }, 0),
    },

    {
      label: "No of scheme sent",
      spacing: 3,
      value: formattedData.reduce((initial, item) => {
        item.aif_direct == "aif" &&
          !!item.ca_doc_status &&
          item.scheme_doc_status == "Sent" &&
          initial++;
        return initial;
      }, 0),
    },
    {
      label: "No of drawdown accepted",
      spacing: 3,
      value: formattedData.reduce((initial, item) => {
        ((item.aif_direct == "aif" &&
          !!item.ca_doc_status &&
          !!item.amount_transfer_status) ||
          (item.aif_direct == "direct" && !!item.amount_transfer_status)) &&
          initial++;
        return initial;
      }, 0),
    },
    {
      label: "No of scheme accepted",
      spacing: 3,
      value: formattedData.reduce((initial, item) => {
        item.aif_direct == "aif" &&
          !!item.ca_doc_status &&
          !!item.deal_consent_received &&
          initial++;
        return initial;
      }, 0),
    },
    {
      label: "Total committed amount",
      spacing: 3,
      value: formattedData.reduce(
        (initial, item) => initial + Number(item.proposed_amount.amount),
        0
      ),
    },
  ];

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <div>
            <Breadcrumb
              steps={CommitTrackerBreadCrumbData?.steps}
              pageHeader={CommitTrackerBreadCrumbData.pageHeader}
              stepKey={CommitTrackerBreadCrumbData.stepKey}
            />
          </div>
        </Grid>
      </Grid>

      <Stack gap={2} direction="row" alignItems="start">
        <Box>
          <RadioButton
            formLabelStyle={{ textAlign: "start" }}
            formLabel="Filter Type"
            options={[
              { name: "Startup", value: "startups" },
              { name: "Investor", value: "investors" },
            ]}
            value={filter}
            onChange={handleFilterChange}
          />
        </Box>
        <Box width={"40%"} pt={1}>
          <LvAutoComplete
            ref={ref1}
            label={filter === "startups" ? "Select Startup" : "Select Investor"}
            options={option}
            setOptions={setOption}
            selectedObject={selectedVal}
            setSelectedObject={setSelectedVal}
            onChange={handleTextChange}
            onSelect={(value: any) => {
              fetchCrmData(value);
            }}
          />
        </Box>
      </Stack>
      {selectedVal?.value && (
        <>
          {loadingDetail ? (
            <CommitTrackerSkeleton />
          ) : (
            <>
              <Box
                sx={{
                  border: "1px solid #ececec",
                  borderRadius: "0.5rem",
                  textAlign: "left",
                  marginTop: "1rem",
                }}
              >
                {formattedData?.length ? (
                  <CardContainer options={cardOptions} />
                ) : (
                  <Box p={3}>
                    <NoDataAvailable
                      title="No Data Available"
                      subTitle={"Search with different startup/investor"}
                      image={IconConstant.DATA_NOT_FOUND_ICON}
                    />
                  </Box>
                )}
              </Box>
              <Box mt="1rem">
                <HorizontalTabs
                  tabs={tabs}
                  selectedTab={selectedTab}
                  onTabChange={(tab: any) => {
                    // setValue('');
                    setSelectedTab(tab);
                  }}
                  scrollable={false}
                />
              </Box>
              <CrmTable
                formattedData={formattedData}
                page={page}
                setPage={setPage}
                count={count}
                tab={selectedTab}
                fetchCrmData={fetchCrmData}
                selectedVal={selectedVal}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CommitTracker;
