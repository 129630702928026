import { DocumentTypes } from "../constants/documentConstants";
import {
  getDealDocuments,
  getInvestorDocuments,
  handleDocStatus,
  uploadBulkDocument,
  uploadDocument,
  searchDealName,
  uploadDealDocument,
  publishUnpublish,
  getDealDocumentTypeOptions,
} from "../services/documentServices";
import { getFormattedDate } from "../../../utils/dateUtils";
import { getQueryString } from "../../../utils/apiUtils";
import { start } from "repl";

type investorDocuments = {
  id: number;
  investor_name: string;
  formated_document_category: string;
  formated_document_name: string;
  notes: string;
  last_updated_date: string;
  last_updated_by: string;
  document_url: string;
  status: number;
  publish: boolean;
  action: any;
  document_title: string;
  document_type: string;
  user_id: number;
};

type dealDocuments = {
  startup_name: string;
  formated_document_category: string;
  formated_document_name: string;
  notes: string;
  last_updated_by: string;
  last_updated_date: string;
  action: any;
};

export type actionButtonActions = {
  id: number;
  file: string;
  approve?: boolean;
  reject?: boolean;
  publish?: boolean;
  unpublish?: boolean;
  approveText?: string;
  rejectText?: string;
  viewText?: string;
  isOnboarding?: boolean;
};

type DealDocUploadType = {
  startup_id: string;
  document_category: string;
  document_name: string;
  document_url: any;
  document_notes: string;
};

type InvestorDocUploadType = {
  user_id?: string;
  investor_information_id?: string;
  document_title?: string;
  document_type?: string;
  notes?: string;
  year?: string;
  quarter?: string;
  document_url?: any;
};

type BulkUploadType = {
  typeOfFile?: string;
  year?: string;
  quarter?: string;
  file?: any;
};
export default class DocumentManagement {
  private userData: any;

  constructor(AuthContext: any) {
    this.userData = AuthContext.authState;
  }

  private getFormattedDealDocs = (data: any[]) => {
    let result: any = [];
    data.forEach((item: any) => {
      result.push({
        startup_name: item?.startup_name ?? 0,
        formated_document_category: item?.document_category ?? "",
        formated_document_name: item?.formated_document_name ?? "",
        notes: item?.document_notes ?? "",
        last_updated_by: item?.last_updated_user ?? "",
        last_updated_date: getFormattedDate(item?.last_updated_date) ?? "",
        action: "",
        ...item,
      });
    });
    return result;
  };

  private getFormattedInvestorDocs = (data: any[]) => {
    let result: investorDocuments[] = [];
    data.forEach((item: any) => {
      result.push({
        id: item?.id ?? -1,
        investor_name: item?.investor_name ?? "",
        formated_document_category: item?.document_type ?? "",
        formated_document_name: item?.document_title ?? "",
        notes: item?.document_notes ?? "",
        last_updated_by: item?.last_updated_by ?? "",
        last_updated_date: getFormattedDate(item?.created_date) ?? "",
        document_url: item?.document_url ?? "",
        status: item?.status ?? -1,
        publish: item?.publish ?? "",
        action: this.getActionsButtonData(item),
        document_title: item?.document_title ?? "",
        document_type: item?.document_type ?? "",
        user_id: item?.user_id ?? -1,
      });
    });
    return result;
  };

  private getActionsButtonData = (data: any) => {
    const {
      document_title: type = null,
      document_url = null,
      status = 0,
      publish = false,
      id = null,
    } = data;

    const actions: actionButtonActions = {
      id: id,
    } as actionButtonActions;

    if (type.toLowerCase() === "onboarding") {
      if (parseInt(status) === 0) {
        actions.approve = true;
        actions.reject = true;
      } else if (parseInt(status) === 1) {
        actions.reject = true;
      } else {
        actions.approve = true;
      }
      actions.approveText = "Approve";
      actions.rejectText = "Reject";
      actions.isOnboarding = true;
    } else {
      if (publish) actions.unpublish = true;
      else actions.publish = true;

      actions.approveText = "Publish";
      actions.rejectText = "Unpublish";
      actions.isOnboarding = false;
    }

    if (document_url) {
      actions.viewText = "View";
      actions.file = document_url;
    }

    return actions;
  };

  async getDealDocuments(
    page: number,
    deal_id?: string,
    document_category?: string
  ) {
    try {
      const queryString = getQueryString({
        page,
        admin_view: true,
        document_category: document_category,
        startup_id: deal_id,
      });
      let result = await getDealDocuments(queryString);
      let formattedData = this.getFormattedDealDocs(result?.data?.results);
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getDealDocumentTypeOptions() {
    try {
      let result = await getDealDocumentTypeOptions();
      const data = result?.data ?? {};
      const documentCategoryOptions = Object.keys(data).map((key) => ({
        label: key?.replaceAll("_", " "),
        value: key,
      }));
      const documentNameOptions = Object.keys(data).reduce((acc: any, key) => {
        acc[key] = data[key].map((item: any) => ({
          label: item?.replaceAll("_", " "),
          value: item,
        }));
        return acc;
      }, {});
      return { documentCategoryOptions, documentNameOptions };
    } catch (error) {
      throw error;
    }
  }

  async getInvestorDocuments(
    page: number,
    user_id?: any,
    investor_information_id?: any,
    document_title?: string
  ) {
    try {
      const queryString = getQueryString({
        admin_view: true,
        page,
        user_id,
        investor_information_id,
        document_title,
        page_size: 20,
      });
      let result = await getInvestorDocuments(queryString);
      let formattedData = this.getFormattedInvestorDocs(result?.data?.results);
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async approveOnboardingDoc(id: number | null) {
    try {
      const body = {
        status: "1",
        last_updated_by_user_id: this?.userData?.user?.id,
        last_updated_by: this?.userData?.user?.name,
      };
      const res = await handleDocStatus(id, body);
      return res;
    } catch (error) {
      throw error;
    }
  }
  async rejectOnboardingDoc(id: number | null, rejectText: string) {
    try {
      const body = {
        status: "2",
        document_note: rejectText.trim(),
        last_updated_by_user_id: this?.userData?.user?.id,
        last_updated_by: this?.userData?.user?.name,
      };
      const res = await handleDocStatus(id, body);
      return res;
    } catch (error) {
      throw error;
    }
  }
  async publishInvestorDoc(id: number) {
    try {
      const body = {
        publish: true,
        last_updated_by_user_id: this?.userData?.user?.id,
        last_updated_by: this?.userData?.user?.name,
      };
      const res = await handleDocStatus(id, body);
      return res;
    } catch (error) {
      throw error;
    }
  }
  async unPublishInvestorDoc(id: number) {
    try {
      const body = {
        publish: false,
        last_updated_by_user_id: this?.userData?.user?.id,
        last_updated_by: this?.userData?.user?.name,
      };
      const res = await handleDocStatus(id, body);
      return res;
    } catch (error) {
      throw error;
    }
  }

  async uploadBulkDocument({
    typeOfFile = "",
    year = "",
    quarter = "",
    file = null,
  }: BulkUploadType = {}) {
    try {
      let formData = new FormData();
      formData.append("document_type", typeOfFile);
      formData.append("bulk_document_file", file);
      formData.append("year", year);
      if (quarter) {
        formData.append("quarter", quarter);
      }
      const res = await uploadBulkDocument(formData);
      return res;
    } catch (error) {
      throw error;
    }
  }

  async uploadDocument(values: any) {
    const data = {
      user_id: values?.selectedInvestor?.value,
      investor_information_id: values?.selectedInvestmentProfile?.value,
      document_title: values?.documentName?.value,
      document_type: values?.documentType?.value,
      document_url: values?.document[0],
      document_notes: values?.notes,
      year: values?.year,
      quarter: values?.quarter?.value,
    };
    try {
      let formData = new FormData();
      formData.append("user_id", data?.user_id);
      formData.append("investor_information_id", data?.investor_information_id);
      formData.append("document_title", data?.document_title);
      if (data?.document_title === DocumentTypes.onboarding) {
        formData.append("document_type", data?.document_type);
      } else {
        if (data?.document_title === DocumentTypes.unitstatement) {
          formData.append("year", data?.year);
          formData.append("quarter", data?.quarter);
        } else {
          formData.append("year", data?.year);
        }
      }
      formData.append("document_url", data?.document_url);
      formData.append("notes", data?.document_notes);

      const res = await uploadDocument(formData);
      return res;
    } catch (error) {
      throw error;
    }
  }

  async searchDealName(search: string) {
    try {
      const res = await searchDealName(search);
      return { data: res.data.results };
    } catch (error) {
      throw error;
    }
  }

  async uploadDealDocument(data: DealDocUploadType) {
    try {
      let formData = new FormData();
      formData.append("startup_id", data.startup_id);
      formData.append("document_category", data.document_category);
      formData.append("document_name", data.document_name);
      formData.append("document_notes", data.document_notes);
      formData.append("document_url", data.document_url);

      const res = await uploadDealDocument(formData);
      return res;
    } catch (error) {
      throw error;
    }
  }

  async publishUnpublish(id: string, publish: boolean) {
    try {
      const data = {
        last_updated_user_id: this?.userData?.user?.id,
        publish,
      };
      const res = await publishUnpublish(id, data);

      return res;
    } catch (error) {
      throw error;
    }
  }
}
