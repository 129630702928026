import React, { FC, useState } from "react";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { AwaitingPhoneNumberType } from "./AwaitingPhoneNumber";

import NoteModal from "../modals/NoteModal";
import { Link } from "react-router-dom";
import { getColor } from "../../../../ui-library/Styles/Colors";

const VerifiedPhoneNumber: FC<AwaitingPhoneNumberType> = (
  props: AwaitingPhoneNumberType
) => {
  const { setPage, formattedData, page, count, refreshData } = props;
  const [isOpen, setIsOpen] = useState(false);

  const [selected, setSelected] = useState<any>();
  const columns = [
    {
      field: "date",
      headerName: "Date",
      minWidth: 150,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      minWidth: 200,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            to={`/user/user-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",
      flex: 2,
      minWidth: 300,
    },
    {
      field: "mobile",
      headerName: "Phone No.",
      headerAlign: "left",
      flex: 1,
      minWidth: 200,
    },
    {
      headerAlign: "left",
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 150,
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    refreshData && refreshData(page + 1);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={60}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={20}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
      />
      {isOpen && (
        <NoteModal
          isOpen={isOpen}
          handleClose={handleClose}
          selected={selected}
          type="User"
        />
      )}
    </div>
  );
};

export default VerifiedPhoneNumber;
