import React, { ReactNode, useEffect, useState, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import "./style.scss";
import { getColor } from "../../Styles/Colors";

export type PhoneNumberProps = {
  /**
   * default country
   */
  defaultCountry?: string | number;
  /**
   * disable or enable phone number field
   */
  disabled?: boolean;
  /**
   * disable or enable country dropdown
   */
  disableDropdown?: boolean;
  /**
   * disable or enable search field inside dropdown
   */
  enableSearch?: boolean;
  /**
   * formik error boolean
   */
  error?: boolean;
  /**
   * label name
   */
  label: string;
  /**
   *boolean for changing country code
   */
  countryCodeEditable?: boolean;
  /**
   *onChange function
   */
  onChange?: any;
  /**
   *value of phone number field
   */
  value: string;
  /**
   *width of phone number field
   */
  width?: string | number;
  /**
   *required boolean of phone number field
   */
  required?: boolean;
  /**
   *fieldArray props
   */
  index?: number;
  /**
   *fieldArray props
   */
  fieldArray?: boolean;
  /**
   *fieldArray props
   */
  formName?: string;
  /**
   *fieldArray props
   */
  fieldName?: string;
  /** prop from formik. */
  form: any;
  /** unique identifier for phone number field */
  id: string;
  /** custom component to be shown inside with absolute position */
  customComponent?: ReactNode | ReactNode;
  /** helper text */
  helperText?: ReactNode | ReactNode | string;
};

export const PhoneNumber = ({
  defaultCountry = "in",
  disabled = false,
  error,
  label,
  disableDropdown = false,
  enableSearch = true,
  countryCodeEditable = true,
  onChange,
  value = "",
  width = "100%",
  required = false,
  fieldArray = false,
  fieldName = "",
  index = 0,
  formName = "",
  form,
  id = "",
  customComponent = null,
  helperText = null,
}: PhoneNumberProps) => {
  const errorState = fieldArray
    ? error && form?.touched?.[formName]?.[index]?.[fieldName]
    : error && form?.touched[id];
  const [dialCodeLength, setDialCodeLength] = useState(1);
  const telephoneDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let inputElement: any =
      document.getElementsByClassName("form-control")[index];
    let labelElement: any =
      document.getElementsByClassName("special-label")[index];
    let mainDiv: any = telephoneDiv.current?.querySelector(
      ".react-tel-input div"
    );
    let labelClassList: any = mainDiv?.classList;
    if (labelElement) {
      labelElement.addEventListener("click", () => {
        inputElement.focus();
      });
    }
    const isPresent = labelClassList.value.includes("new-lable-class");
    if (value && value.length > dialCodeLength && !isPresent) {
      mainDiv.className += " new-lable-class";
    } else if (value && value.length <= 2 && isPresent) {
      labelClassList.remove("new-lable-class");
    }
    return () => {
      if (labelElement) {
        labelElement.removeEventListener("click", () => {});
      }
    };
  }, [value]);

  const handleChange = (
    value: string,
    data: {
      name: string;
      dialCode: string;
      countryCode: string;
      format: string;
    },
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    setDialCodeLength(data?.dialCode.length);
    if (form) {
      if (fieldArray) {
        const newTouched = form.touched[formName] ?? [];
        newTouched[index] = { ...(newTouched[index] ?? {}), [fieldName]: true };
        form.setTouched({ ...form.touched, [formName]: newTouched });
      } else {
        form?.setTouched({ ...form.touched, [id]: true });
      }
      form?.setFieldValue(id, value);
    }
    onChange && onChange(formattedValue, value, data, event.target.value);
  };

  const handleClick = () => {
    let mainDiv: any = telephoneDiv.current?.querySelector(
      ".react-tel-input div"
    );
    mainDiv.className += " new-lable-class";
  };

  const handleBlur = (props: any, data: any) => {
    let mainDiv: any = telephoneDiv.current?.querySelector(
      ".react-tel-input div"
    );
    let labelClassList: any = mainDiv?.classList;
    const isPresent = labelClassList.value.includes("new-lable-class");
    const dialCode = data?.dialCode;
    const phoneNumber = !value ? dialCode : value;
    const length = !value ? dialCode.length : dialCode.length + 1;
    if (phoneNumber.length <= length && isPresent) {
      labelClassList.remove("new-lable-class");
    }
  };

  const getErrorMessage = () => {
    let errorMessage = "";
    if (form) {
      if (
        fieldArray &&
        error &&
        form?.touched?.[formName]?.[index]?.[fieldName]
      ) {
        errorMessage = form?.errors?.[formName]?.[index]?.[fieldName];
      } else if (error && form?.touched[id]) {
        errorMessage = form?.errors[id];
      }
    }
    return errorMessage;
  };
  return (
    <div
      ref={telephoneDiv}
      style={{ width, position: "relative" }}
      className={errorState ? "invalid" : ""}
    >
      <PhoneInput
        autocompleteSearch
        value={value}
        enableSearch={enableSearch}
        disableDropdown={disableDropdown}
        specialLabel={`${label}${required ? "*" : ""}`}
        placeholder=""
        country={defaultCountry}
        inputStyle={{
          borderColor: errorState ? getColor("error", 500) : "",
          color: disabled ? "#00000061" : "#040607",
          backgroundColor: "transparent",
          fontFamily: "Work Sans",
        }}
        disabled={disabled}
        countryCodeEditable={countryCodeEditable}
        onChange={handleChange}
        onFocus={handleClick}
        onBlur={(props, data) => {
          handleBlur(props, data);
        }}
      />
      {customComponent && customComponent}
      {helperText && helperText}
      {errorState && (
        <p className="invalid-number-message">{getErrorMessage()}</p>
      )}
    </div>
  );
};
