import {
  FC,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import { ToggleSwitch } from "../../../../../ui-library/buttons/ToggleSwitch";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { FormContainer } from "../../../../../ui-library/form-elements/FormContainer";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";
import { Dropdown } from "../../../../../ui-library/dropdowns/core-dropdown";
import { isEmpty } from "../../../../../utils/commonUtils";
import { visibilityOptions } from "../../../constants/StartupDetailsContant";
import { AuthContext } from "../../../../../contexts/authContext";
import StartupManagementManager from "../../../managers/startupManager";
import { getChangedValues } from "../../../../../utils/formUtils";
import { toast } from "react-toastify";
import UserManagementManager from "../../../../userManagement/managers/userManagementManager";
import { RadioButtonSelect } from "../../../../../ui-library/form-elements/RadioButtonSelect";
import * as Yup from "yup";

type BasicDetailsEditType = {};

const BasicDetailsEdit: FC<BasicDetailsEditType> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const startupDetails = state?.startupDetails ?? {};

  const PitchingFounder = startupDetails?.pitching_founder;
  const formattedPitchingFounder = !isEmpty(PitchingFounder)
    ? { label: PitchingFounder.name, value: PitchingFounder.id }
    : null;

  const [pitchingFounderOptions, setPitchingFounderOptions] = useState([]);
  const [zenoSyndicateOptions, setZenoSyndicateOptions] = useState<any>([]);
  const [privateSyndicateOptions, setPrivateSyndicateOptions] = useState<any>(
    []
  );

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const authContext = useContext(AuthContext);
  const startupManagementManager = new StartupManagementManager(authContext);
  const userManagementManager = new UserManagementManager(authContext);

  const initialValues = useMemo(
    () => ({
      published: startupDetails?.published ?? false,
      dealDashboardAccess: startupDetails?.deal_dashboard_access ?? false,
      visibility:
        visibilityOptions.find(
          (option) => option.value === startupDetails?.visibility
        ) ?? "",
      superVisibility: startupDetails?.super_visibility ?? false,
      selectedPitchingFounder: formattedPitchingFounder,

      typeOfStartup: startupDetails?.part_of_private_syndicate
        ? "normal_private"
        : startupDetails?.is_zeno
        ? "zeno_private"
        : "public",
      startupAsNormalSyndicate:
        startupDetails?.part_of_private_syndicate &&
        startupDetails?.pvt_syndicate_id
          ? {
              label: startupDetails?.pvt_syndicate_name,
              value: startupDetails?.pvt_syndicate_id,
            }
          : undefined,
      startupAsZenoSyndicate:
        startupDetails?.is_zeno && startupDetails?.pvt_syndicate_id
          ? {
              label: startupDetails?.pvt_syndicate_name,
              value: startupDetails?.pvt_syndicate_id,
            }
          : undefined,
    }),
    [startupDetails]
  );

  const validationSchema = Yup.object().shape({
    startupAsNormalSyndicate: Yup.object().when("typeOfStartup", {
      is: (value: string) => value === "normal_private",
      then: (schema) => schema.required("Normal private syndicate is required"),
    }),

    startupAsZenoSyndicate: Yup.object().when("typeOfStartup", {
      is: (value: string) => value === "zeno_private",
      then: (schema) => schema.required("Zeno private syndicate is required"),
    }),
  });

  const fetchPrivateSyndicateOptions = async () => {
    try {
      let result = await userManagementManager.getLookupData({
        search: "",
        type: "private_syndicate",
      });

      result = result?.results?.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setPrivateSyndicateOptions(result);
    } catch (err) {
      toast.error("Error while fetching private syndicate options");
    }
  };

  const fetchZenoSyndicateOptions = async () => {
    try {
      let result = await userManagementManager.getLookupData({
        search: "",
        type: "zeno_private_syndicate",
      });

      result = result?.results?.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setZenoSyndicateOptions(result);
    } catch (err) {
      toast.error("Error while fetching zeno syndicate options");
    }
  };

  useEffect(() => {
    const formattedPitchingFounderOptions =
      startupDetails?.all_admin_founders?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      })) ?? [];
    setPitchingFounderOptions(formattedPitchingFounderOptions);
    fetchPrivateSyndicateOptions();
    fetchZenoSyndicateOptions();
  }, [startupDetails]);

  const EditBreadCrumbData = useMemo(
    () => ({
      stepKey: 3,
      breadcrumbSeparator: true,
      steps: [
        { title: "Startup Management", titleDisplay: true },
        { title: "Basic Details", titleDisplay: true },
        { title: "Edit Basic Details", titleDisplay: true },
      ],
      pageHeader: {
        header: startupDetails?.name ?? "Startup Details",
        navigationButton: true,
        navigationFunction: () =>
          navigate(`/startup/startup-panel/${startupDetails.id}/`, {
            state: { selectedTab: "basic_details" },
          }),
      },
    }),
    [navigate, startupDetails]
  );

  const handleStartupDetailsSubmit = async (values: any) => {
    try {
      setFormSubmitting(true);
      const newValues = getChangedValues(values, initialValues);

      await startupManagementManager.updateStartupData(
        newValues,
        startupDetails.id,
        values
      );
      setFormSubmitting(false);
      toast.success("Startup details updated successfully");
      setIsConfirmationModalOpen(false);
      navigate(`/startup/startup-panel/${startupDetails.id}/`, {
        state: { selectedTab: "basic_details" },
      });
    } catch (error) {
      console.error("Error while submitting the startup details");
      toast.error("Error while submitting the startup details");
      setFormSubmitting(false);
    }
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={5}>
          <Breadcrumb
            steps={EditBreadCrumbData.steps}
            pageHeader={EditBreadCrumbData.pageHeader}
            stepKey={EditBreadCrumbData.stepKey}
            breadcrumbSeparator={EditBreadCrumbData.breadcrumbSeparator}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={() => setIsConfirmationModalOpen(true)}
        validationSchema={validationSchema}
      >
        {({ values, touched, errors, setFieldValue, handleSubmit, dirty }) => {
          return (
            <Stack>
              <FormContainer>
                <Stack gap={2}>
                  <Stack alignItems="start">
                    <ToggleSwitch
                      switchLabel="Published"
                      showYesOrNo
                      switchLabelPositon="start"
                      defaultChecked={values.published}
                      labelSwitchDistance="20px"
                      onSwitchToggle={(value: boolean) =>
                        setFieldValue("published", value)
                      }
                    />
                  </Stack>
                  <Stack alignItems="start">
                    <ToggleSwitch
                      switchLabel="Deal Dashboard Accessibility"
                      showYesOrNo
                      switchLabelPositon="start"
                      defaultChecked={values.dealDashboardAccess}
                      labelSwitchDistance="20px"
                      onSwitchToggle={(value: boolean) =>
                        setFieldValue("dealDashboardAccess", value)
                      }
                    />
                  </Stack>
                  <LvAutoComplete
                    label="Pitching Founder"
                    options={pitchingFounderOptions}
                    setOptions={setPitchingFounderOptions}
                    selectedObject={values.selectedPitchingFounder}
                    setSelectedObject={(value: any) =>
                      setFieldValue("selectedPitchingFounder", value)
                    }
                  />
                  <Stack alignItems="start">
                    <RadioButtonSelect
                      formLabel="Type of Startup"
                      id="typeOfStartup"
                      fieldName="typeOfStartup"
                      formLabelStyle={{ textAlign: "left" }}
                      options={[
                        { name: "Public", value: "public" },
                        {
                          name: "Normal Private Syndicate",
                          value: "normal_private",
                        },
                        {
                          name: "Zeno Private Syndicate",
                          value: "zeno_private",
                        },
                      ]}
                      value={values?.typeOfStartup}
                    />
                  </Stack>
                  {values?.typeOfStartup === "normal_private" && (
                    <LvAutoComplete
                      label="Select Private Normal Syndicate"
                      options={privateSyndicateOptions}
                      setOptions={setPrivateSyndicateOptions}
                      selectedObject={values.startupAsNormalSyndicate}
                      setSelectedObject={(value: any) =>
                        setFieldValue("startupAsNormalSyndicate", value)
                      }
                      error={
                        errors.startupAsNormalSyndicate &&
                        touched?.startupAsNormalSyndicate &&
                        errors.startupAsNormalSyndicate
                      }
                    />
                  )}

                  {values?.typeOfStartup === "zeno_private" && (
                    <LvAutoComplete
                      label="Startup As Private Zeno Syndicate"
                      options={zenoSyndicateOptions}
                      setOptions={setZenoSyndicateOptions}
                      selectedObject={values.startupAsZenoSyndicate}
                      setSelectedObject={(value: any) =>
                        setFieldValue("startupAsZenoSyndicate", value)
                      }
                      error={
                        errors.startupAsZenoSyndicate &&
                        touched?.startupAsZenoSyndicate &&
                        errors.startupAsZenoSyndicate
                      }
                    />
                  )}

                  <Dropdown
                    label="Visibility"
                    id="visibility"
                    name="visibility"
                    fieldName="visibility"
                    options={visibilityOptions}
                    value={values.visibility}
                    error={!!errors.visibility}
                  />
                  <Stack alignItems="start">
                    <ToggleSwitch
                      switchLabel="Super Visibility"
                      showYesOrNo
                      switchLabelPositon="start"
                      defaultChecked={values.superVisibility}
                      labelSwitchDistance="20px"
                      onSwitchToggle={(value: boolean) =>
                        setFieldValue("superVisibility", value)
                      }
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="end" mt={2}>
                    <ActionButton disabled={!dirty} onClick={handleSubmit}>
                      Submit
                    </ActionButton>
                  </Stack>
                </Stack>
              </FormContainer>
              <CommonModal
                isOpen={isConfirmationModalOpen}
                handleClose={() => setIsConfirmationModalOpen(false)}
                handleSubmit={() => handleStartupDetailsSubmit(values)}
                title="Are you sure?"
                submitText="Submit"
                buttonStyles={{ width: "100%" }}
                closeIcon
                hideCancelButton
                isLoading={formSubmitting}
                disableSubmitButton={formSubmitting}
              >
                <Stack>Are you sure you want to submit the changes?</Stack>
              </CommonModal>
            </Stack>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default BasicDetailsEdit;
