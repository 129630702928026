import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { TablePlaceholder } from "../../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "lodash";
import { API_STATUS } from "../../../../../constants/commonConstants";
import { AuthContext } from "../../../../../contexts/authContext";
import StartupManagement from "../../../managers/startupManager";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const StartupExitData = () => {
  const authContext = useContext(AuthContext);
  const startupManagementManager = new StartupManagement(authContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);

  const navigate = useNavigate();
  const { startup_id }: any = useParams();

  const ExitDataBreadCrumbData = useMemo(
    () => ({
      stepKey: 2,
      breadcrumbSeparator: true,
      steps: [
        { title: "Startup Management", titleDisplay: true },
        {
          title: "Startup Exit Data",
          titleDisplay: true,
        },
      ],
      pageHeader: {
        header: "Startup Exit Data",
        navigationButton: true,
        navigationFunction: () => navigate(-1),
      },
    }),
    [navigate]
  );

  let columns = [
    {
      field: "investor_name",
      headerName: "Name",
      minWidth: 170,
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: "Email Address",
      minWidth: 200,
      headerAlign: "left",
    },
    {
      field: "exit_type",
      headerName: "Exit Type",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "actual_share_diluted",
      headerName: "No. of shares exited",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "total_shares_left",
      headerName: "Shares Left",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "exit_date",
      headerName: "Exit Date",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "exit_value",
      headerName: "Exit Price",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "note",
      headerName: "Exit Note",
      minWidth: 150,
      headerAlign: "left",
    },
  ];

  const getStartupDetails = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      if (!startup_id) {
        toast.error("Startup ID is required");
        navigate(-1);
      }
      const result = await startupManagementManager.getStartupDetail(
        startup_id
      );
      if (result) {
        setFormattedData(result?.startup_exit_data);
        setApiStatus(API_STATUS.SUCCESS);
      }
    } catch (err) {
      setApiStatus(API_STATUS.ERROR);
      toast.error("Failed to fetch startup details");
    }
  };

  useEffect(() => {
    getStartupDetails();
  }, [startup_id]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumb
            steps={ExitDataBreadCrumbData?.steps}
            pageHeader={ExitDataBreadCrumbData.pageHeader}
            stepKey={ExitDataBreadCrumbData.stepKey}
          />
        </Grid>
      </Grid>

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            rightPinnedColumns={["action"]}
          />
        </>
      )}
    </Box>
  );
};

export default StartupExitData;
