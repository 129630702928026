import React, { useEffect, useState } from "react";
import {
  CustomTextField,
  InputField,
} from "../../../../ui-library/form-elements/CustomTextField";
import { Dropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import makeStyles from "../../../styles";
import { FileUpload } from "../../../../ui-library/form-elements/file-upload/FileUpload";
import { toast } from "react-toastify";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import DocumentManagement from "../../managers/documentManager";
import { AuthContext } from "../../../../contexts/authContext";
import { useContext } from "react";

import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormHelperText, Stack } from "@mui/material";

export default function UploadDocumentModal(props: any) {
  const classes = makeStyles();
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);

  const { openModal, handleModal, reloadData } = props;

  const [documentCategoryOptions, setDocumentCategoryOptions] = useState<any>(
    []
  );
  const [documentNameOptions, setDocumentNameOptions] = useState<any>([]);

  const [selectedDeal, setSelectedDeal] = useState<any>({});
  const [dealOptions, setDealOptions] = useState<any>([]);
  const handleSearchDealName = async (value: string) => {
    try {
      let result = await documentManagementManager.searchDealName(value);
      result = result?.data.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setDealOptions(result);
    } catch (error: any) {
      toast.error(error);
    }
  };

  const initialValues = {
    selectedDeal: null,
    documentCategory: null,
    documentName: null,
    file: [],
    notes: "",
  };

  const validationScheme = Yup.object().shape({
    selectedDeal: Yup.object().required("Deal is required"),
    documentCategory: Yup.object().required("Document category is required"),
    documentName: Yup.object().required("Document name is required"),
    file: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  });

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      setIsSubmitButtonLoading(true);
      const data = {
        startup_id: values?.selectedDeal?.value,
        document_category: values?.documentCategory?.value,
        document_name: values?.documentName?.value,
        document_url: values?.file[0],
        document_notes: values?.notes,
      };

      const result = await documentManagementManager.uploadDealDocument(data);
      toast.success("Document Uploaded Successfully");
      setIsSubmitButtonLoading(false);
      handleModal && handleModal();
      reloadData && reloadData();
    } catch (error: any) {
      toast.error(error.message ?? "Something Went Wrong");
      setIsSubmitButtonLoading(false);
    }
  };

  const cancel = () => {
    handleModal();
  };

  const getDocumentOptions = async () => {
    try {
      const { documentCategoryOptions, documentNameOptions } =
        await documentManagementManager.getDealDocumentTypeOptions();
      setDocumentCategoryOptions(documentCategoryOptions);
      setDocumentNameOptions(documentNameOptions);
    } catch (e: any) {
      toast.error(e.message ?? "Something Went Wrong");
    }
  };

  useEffect(() => {
    getDocumentOptions();
  }, []);

  return (
    <>
      <div>
        <CommonModal
          submitText="continue"
          isOpen={openModal}
          handleClose={handleModal}
          title="Upload Deal Docuement"
          hideButtons
          closeIcon
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationScheme}
            validateOnBlur
          >
            {({
              values,
              setFieldValue,
              errors,
              handleSubmit,
              touched,
            }: any) => {
              return (
                <div style={{ width: "500px", overflow: "auto" }}>
                  <div
                    className={classes.fieldItem}
                    style={{ marginTop: "5px" }}
                  >
                    <LvAutoComplete
                      required
                      label="Enter Deal Name"
                      options={dealOptions}
                      setOptions={setDealOptions}
                      selectedObject={selectedDeal}
                      setSelectedObject={setSelectedDeal}
                      onSelect={(value: any) => {
                        setFieldValue("selectedDeal", value);
                        setDealOptions([]);
                      }}
                      onChange={handleSearchDealName}
                      error={
                        !!errors.selectedDeal &&
                        !!touched.selectedDeal &&
                        errors.selectedDeal
                      }
                    />
                  </div>

                  <div className={classes.fieldItem}>
                    <Dropdown
                      required
                      id="documentCategory"
                      name="documentCategory"
                      fieldName="documentCategory"
                      label="Select Document Category"
                      options={documentCategoryOptions}
                      value={values.documentCategory}
                      error={!!errors.documentCategory}
                    />
                  </div>
                  {values?.documentCategory?.value && (
                    <div className={classes.fieldItem}>
                      <Dropdown
                        required
                        id="documentName"
                        name="documentName"
                        fieldName="documentName"
                        label="Select Document Name"
                        options={
                          documentNameOptions[values?.documentCategory?.value]
                        }
                        value={values.documentName}
                        error={!!errors.documentName}
                      />
                    </div>
                  )}
                  <div className={classes.fieldItem}>
                    <FileUpload
                      placeholder="Choose File*"
                      value={values?.file}
                      selectedFile={(file: any) => setFieldValue("file", file)}
                      required
                      width="100%"
                      accept={{
                        "image/jpeg": [".jpg", ".jpeg"],
                        "application/pdf": [".pdf"],
                        "application/doc": [".doc", ".docx"],
                      }}
                      title="Choose File"
                      hideDocumentVault
                      helperText="You can upload a pdf, jpg, jpeg, doc or docx file only"
                      error={!!errors?.file && !!touched?.file}
                    />
                    {!!errors?.file && !!touched?.file && (
                      <FormHelperText error>{errors?.file}</FormHelperText>
                    )}
                  </div>
                  <div className={classes.fieldItem}>
                    <InputField
                      label="Notes"
                      width="100%"
                      placeholder="Notes"
                      id="notes"
                      name="notes"
                      fieldName="notes"
                      multiline
                      minRows={4}
                      value={values?.notes}
                    />
                  </div>
                  <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <OutlinedButton onClick={cancel}>Cancel</OutlinedButton>

                    <ActionButton
                      isLoading={isSubmitButtonLoading}
                      disabled={isSubmitButtonLoading}
                      onClick={handleSubmit}
                    >
                      Submit
                    </ActionButton>
                  </Stack>
                </div>
              );
            }}
          </Formik>
        </CommonModal>
      </div>
    </>
  );
}
