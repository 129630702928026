import { Box, Grid, Stack } from "@mui/material";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import React, { useContext, useState, useEffect } from "react";
import {
  BreadCrumbDataInvestorDocument,
  DocumentCategoryOptions,
  DocumentTypeNames,
} from "../../constants/documentConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { API_STATUS } from "../../../../constants/commonConstants";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { isEmpty } from "lodash";
import DocumentManagement from "../../managers/documentManager";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { AuthContext } from "../../../../contexts/authContext";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { toast } from "react-toastify";
import UploadInvestorDocumentModal from "./modal/uploadInvestorDocumentModal";
import BulkUploadModal from "./modal/bulkUploadModal";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import VerifyModal from "../../../userManagement/components/modals/VerifyModal";

const InvestorDocuments = () => {
  const DocumentCategoryOptionsExtended = [...DocumentTypeNames];

  const [selectedUser, setSelectedUser] = useState<any>({});
  const [userOptions, setUserOptions] = useState<any>([]);

  const [documentCategory, setDocumentCategory] = useState<any>({});

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);
  const userManagementManager = new UserManagementManager(userData);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [
    isUploadInvestorDocumentModalOpen,
    setIsUploadInvestorDocumentModalOpen,
  ] = useState(false);
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState<any>({
    approve: false,
    reject: false,
    publish: false,
    unpublish: false,
  });

  const viewFile = (url: string) => {
    window.open(url, "_blank");
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    fetchData({
      page: page + 1,
      user_id: selectedUser.value,
      document_title: documentCategory?.value,
    });
  };

  const handleSearchClick = () => {
    fetchData({
      user_id: selectedUser.value,
      document_title: documentCategory?.value,
    });
    setPage(1);
  };

  const handleTextChange = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "only_investor_users",
      });
      result = result?.results?.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setUserOptions(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  type GetDataParams = {
    page?: number;
    user_id?: any;
    investor_information_id?: any;
    document_title?: string;
  };

  const fetchData = async ({
    page = 1,
    user_id = null,
    investor_information_id = null,
    document_title = "",
  }: GetDataParams = {}) => {
    setApiStatus(API_STATUS.LOADING);
    try {
      const { formattedData, count } =
        await documentManagementManager.getInvestorDocuments(
          page,
          user_id,
          investor_information_id,
          document_title
        );

      setFormattedData(formattedData);
      !!count && setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error) {
      setApiStatus(API_STATUS.ERROR);
      console.error("Error fetching documents:", error);
    }
  };

  const approveOnboardingDocument = async () => {
    try {
      const res = await documentManagementManager.approveOnboardingDoc(
        openConfirmModal?.approve
      );
      toast.success("Document approved successfully");
      setOpenConfirmModal((prev: any) => ({
        ...prev,
        approve: false,
      }));
      fetchData({
        user_id: selectedUser.value,
        document_title: documentCategory?.value,
      });
      setPage(1);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const rejectOnboardingDocument = async () => {
    try {
      const res = await documentManagementManager.rejectOnboardingDoc(
        openConfirmModal?.reject,
        "rejected in testing"
      );
      toast.success("Document rejected successfully");

      setOpenConfirmModal((prev: any) => ({
        ...prev,
        reject: false,
      }));
      fetchData({
        user_id: selectedUser.value,
        document_title: documentCategory?.value,
      });
      setPage(1);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const unpublishOnboardingDocument = async () => {
    try {
      const res = await documentManagementManager.unPublishInvestorDoc(
        openConfirmModal?.unpublish
      );
      toast.success("Document unpublished successfully");

      fetchData({
        user_id: selectedUser.value,
        document_title: documentCategory?.value,
      });
      setPage(1);
      setOpenConfirmModal((prev: any) => ({
        ...prev,
        unpublish: false,
      }));
    } catch (e: any) {
      toast.error(e);
    }
  };

  const publishOnboardingDocument = async () => {
    try {
      const res = await documentManagementManager.publishInvestorDoc(
        openConfirmModal?.publish
      );
      toast.success("Document published successfully");

      fetchData({
        user_id: selectedUser.value,
        document_title: documentCategory?.value,
      });
      setPage(1);
      setOpenConfirmModal((prev: any) => ({
        ...prev,
        publish: false,
      }));
    } catch (e: any) {
      toast.error(e);
    }
  };

  const getAction = (data: any) => {
    if (data?.document_title == "Onboarding") {
      if (data?.status == 0 || data?.status === 2 || !data?.publish) {
        // data={'type': data?.document_type}
        return (
          <OutlinedButton
            onClick={() =>
              setOpenConfirmModal((prev: any) => ({
                ...prev,
                approve: data?.id,
              }))
            }
            tableAction
          >
            Approve
          </OutlinedButton>
        );
      } else if (data?.status === 1) {
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("error", 500),
              borderColor: getColor("error", 500),
            }}
            onClick={() =>
              setOpenConfirmModal((prev: any) => ({
                ...prev,
                reject: data?.id,
              }))
            }
            tableAction
          >
            Reject
          </OutlinedButton>
        );
      }
    } else {
      if (data?.publish) {
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("error", 500),
              borderColor: getColor("error", 500),
            }}
            onClick={() =>
              setOpenConfirmModal((prev: any) => ({
                ...prev,
                unpublish: data?.id,
              }))
            }
            tableAction
          >
            Unpublish
          </OutlinedButton>
        );
      } else {
        return (
          <OutlinedButton
            onClick={() =>
              setOpenConfirmModal((prev: any) => ({
                ...prev,
                publish: data?.id,
              }))
            }
            tableAction
          >
            Publish
          </OutlinedButton>
        );
      }
    }
  };

  const handleCloseUploadInvestorDocumentModal = () => {
    setIsUploadInvestorDocumentModalOpen(false);
  };
  const handleOpenUploadInvestorDocumentModal = () => {
    setIsUploadInvestorDocumentModalOpen(true);
  };

  const handleCloseBulkUploadModal = () => {
    setIsBulkUploadModalOpen(false);
  };
  const handleOpenBulkUploadModal = () => {
    setIsBulkUploadModalOpen(true);
  };

  useEffect(() => {
    fetchData({
      page: 1,
      user_id: selectedUser.value,
      document_title: documentCategory?.value,
    });
  }, []);

  const columns = [
    {
      field: "investor_name",
      headerName: "Investor Name",
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "formated_document_name",
      headerName: "Document Category",
      sortable: false,
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "formated_document_category",
      headerName: "Document Name",
      sortable: false,
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "notes",
      headerName: "Notes",
      sortable: false,
      headerAlign: "left",
      minWidth: 300,
    },
    {
      field: "last_updated_date",
      headerName: "Uploaded Date",
      headerAlign: "right",
      align: "right",
      minWidth: 180,
    },
    {
      field: "last_updated_by",
      headerName: "Last Uploaded By",
      headerAlign: "left",
      minWidth: 180,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      minWidth: 250,
      align: "center",
      renderCell: (params: any) => {
        return (
          <Stack direction="row" gap={2} width="100%">
            <OutlinedButton
              customStyles={{
                color: getColor("action_button", 300),
                borderColor: getColor("action_button", 300),
              }}
              onClick={() => {
                viewFile(params.row?.document_url);
              }}
              tableAction
            >
              View
            </OutlinedButton>

            <>{getAction(params.row)}</>
          </Stack>
        );
      },
    },
  ];

  const ActionModals = (
    <>
      {openConfirmModal?.approve && (
        <VerifyModal
          isOpen={openConfirmModal?.approve}
          title="Approve Investor Document"
          message="Are you sure to approve?"
          handleSubmit={approveOnboardingDocument}
          handleClose={() =>
            setOpenConfirmModal((prev: any) => ({
              ...prev,
              approve: false,
            }))
          }
        />
      )}

      {openConfirmModal?.reject && (
        <VerifyModal
          isOpen={openConfirmModal?.reject}
          title="Reject Investor Document"
          message="Are you sure to reject?"
          handleSubmit={rejectOnboardingDocument}
          handleClose={() =>
            setOpenConfirmModal((prev: any) => ({
              ...prev,
              reject: false,
            }))
          }
        />
      )}

      {openConfirmModal?.publish && (
        <VerifyModal
          isOpen={openConfirmModal?.publish}
          title="Publish Investor Document"
          message="Are you sure to publish?"
          handleSubmit={publishOnboardingDocument}
          handleClose={() =>
            setOpenConfirmModal((prev: any) => ({
              ...prev,
              publish: false,
            }))
          }
        />
      )}

      {openConfirmModal?.unpublish && (
        <VerifyModal
          isOpen={openConfirmModal?.unpublish}
          title="Unpublish Investor Document"
          message="Are you sure to unpublish?"
          handleSubmit={unpublishOnboardingDocument}
          handleClose={() =>
            setOpenConfirmModal((prev: any) => ({
              ...prev,
              unpublish: false,
            }))
          }
        />
      )}
    </>
  );

  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataInvestorDocument?.steps}
            pageHeader={BreadCrumbDataInvestorDocument.pageHeader}
            stepKey={BreadCrumbDataInvestorDocument.stepKey}
          />
        </Grid>
        <Grid item xs={9}>
          <Stack direction="row" gap={2} justifyContent="end">
            <ActionButton
              onClick={() => {
                handleOpenBulkUploadModal();
              }}
            >
              Bulk Upload Document
            </ActionButton>
            <ActionButton
              onClick={() => {
                handleOpenUploadInvestorDocumentModal();
              }}
            >
              Upload Document
            </ActionButton>
          </Stack>
          <Stack direction="row" gap={2} justifyContent="end" mt={2} mb={1}>
            <Box width={"280px"}>
              <LvAutoComplete
                label="Enter user name or email"
                options={userOptions}
                setOptions={setUserOptions}
                selectedObject={selectedUser}
                setSelectedObject={setSelectedUser}
                onSelect={() => setUserOptions([])}
                onChange={handleTextChange}
              />
            </Box>
            <Box width={"190px"}>
              <CoreDropdown
                label="Document Category"
                options={DocumentCategoryOptionsExtended}
                value={documentCategory}
                onClickMenuItem={async (value: any) => {
                  setDocumentCategory(value);
                }}
                clearable
                onClear={() => setDocumentCategory({})}
              />
            </Box>
            <ActionButton onClick={handleSearchClick}>Search</ActionButton>
          </Stack>
        </Grid>
      </Grid>

      {ActionModals}

      {apiStatus === API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={20}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <LvComplexTable
          rows={formattedData}
          columns={columns}
          rowHeight={40}
          headerHeight={50}
          minWidth={750}
          filterMode="server"
          leftPinnedColumns={["name"]}
          enableAdvancedFiltering
          isUnFilteredDataPresent={!isEmpty(formattedData)}
          enableAutoHeight={true}
          pageSize={20}
          page={page}
          enablePagination
          totalRowCount={count}
          handlePageChange={handlePageChange}
          paginationMode="server"
        />
      )}

      {isUploadInvestorDocumentModalOpen && (
        <UploadInvestorDocumentModal
          isOpen={isUploadInvestorDocumentModalOpen}
          handleClose={handleCloseUploadInvestorDocumentModal}
          selected={null}
        />
      )}

      {isBulkUploadModalOpen && (
        <BulkUploadModal
          isOpen={isBulkUploadModalOpen}
          handleClose={handleCloseBulkUploadModal}
          selected={null}
        />
      )}
    </Box>
  );
};

export default InvestorDocuments;
