import { Box, Skeleton, Stack } from "@mui/material";
import { CardContainer } from "../../../ui-library/card/card-container";
import { TablePlaceholder } from "../../../ui-library/placeholders/table-placeholder/TablePlaceholder";

const StartupPanelProgressSkeleton = () => {
  const cardOptions = [
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
  ];

  return (
    <>
      <Box
        sx={{
          border: "1px solid #ececec",
          borderRadius: "0.5rem",
          textAlign: "left",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <CardContainer options={cardOptions} />
      </Box>
    </>
  );
};

export default StartupPanelProgressSkeleton;
