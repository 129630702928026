export const GenderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const ProfileTypeOptions = [
  { label: "Business Owner", value: "business_owner" },
  { label: "Professional", value: "professional" },
  {
    label: "VC PE Professional",
    value: "vc_pe_professional",
  },
];
