import React from "react";
import { Grid } from "@mui/material";
import HashLoader from "react-spinners/HashLoader";
import { getColor } from "../Styles/Colors";

export type ApplicationLoaderProps = {
  /**
   * make loader compatible for whole page
   */
  fullScreen?: boolean;
};

export function ApplicationLoader(props: ApplicationLoaderProps) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: props.fullScreen ? "90vh" : "auto" }}
    >
      <Grid item>
        {/* <style>
          {`
          @keyframes spin {
            from {transform:rotate(0deg);}
            to {transform:rotate(360deg);}
          }
          `}
        </style>
        <img
          style={{ animation: "spin 1.5s linear infinite" }}
          alt="trica loading..."
          src="https://dsjvxb1plg419.cloudfront.net/fund-management-icons/trica-transparent-logo.svg"
        /> */}
        <HashLoader
          color={getColor("action_button", 400)}
          style={{ opacity: 0.5 }}
          size={130}
        />
      </Grid>
    </Grid>
  );
}

ApplicationLoader.defaultProps = {
  fullScreen: false,
};
