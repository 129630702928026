import { Box } from "@mui/material";
import React from "react";
import { AccordionPanel } from "../../../../../ui-library/panels/accordian-panel";
import { CardContainer } from "../../../../../ui-library/card/card-container";
import { NoDataAvailable } from "../../../../../ui-library/error/NoDataAvailable";
import { IconConstant } from "../../../../../constants/IconConstants";
import moment from "moment";

const InvestmentProfiles = ({ details = {} }: any) => {
  const cardOptions = (profile: any) => {
    let investmentExperience: any = {
      "Early stage investment experience":
        profile?.individual_statement_fresher,
      "Experience as a serial entrepreneur":
        profile?.individual_statement_intermediate,
      "Senior management professional with at least 10 years of experience":
        profile?.individual_statement_experience,
    };

    investmentExperience = Object.keys(investmentExperience).filter(
      (key) => investmentExperience[key]
    );

    return [
      {
        label: "Profile Name",
        spacing: 3,
        value: profile?.investor_name ?? "-",
      },
      {
        label: "Entity Name",
        spacing: 3,
        value: profile?.entity_name ?? "-",
      },
      {
        label: "Entity Type",
        spacing: 3,
        value: profile?.entity_type ?? "-",
      },

      {
        label: "Phone Number",
        spacing: 3,
        value: profile?.phone_no ?? "-",
      },
      {
        label: "Citizen of",
        spacing: 3,
        value: profile?.citizenship ?? "-",
      },

      {
        label: "Date of Birth",
        spacing: 3,
        value: profile?.dob ? moment(profile?.dob).format("MMM DD, YYYY") : "-",
      },
      {
        label: "Relation Name (Relation Type)",
        spacing: 3,
        value:
          profile?.relation_type && profile?.relation_name
            ? `${profile?.relation_name} (${profile?.relation_type})`
            : "-",
      },
      {
        label: "Registered Address",
        spacing: 3,
        value: profile?.address ?? "-",
      },
      {
        label: "Correspondence Address",
        spacing: 3,
        value: profile?.corres_address ?? "-",
      },
      {
        label: "Name As Per Bank",
        spacing: 3,
        value: profile?.name_as_per_bank ?? "-",
      },

      {
        label: "Account Number",
        spacing: 3,
        value: profile?.account_no ?? "-",
      },
      {
        label: "Bank Type",
        spacing: 3,
        value: profile?.bank_type ?? "-",
      },
      {
        label: "Bank Name",
        spacing: 3,
        value: profile?.bank_name ?? "-",
      },
      {
        label: "Branch Address",
        spacing: 3,
        value: profile?.branch_address ?? "-",
      },
      {
        label: "Location of Bank Account",
        spacing: 3,
        value: profile?.bank_location ?? "-",
      },

      {
        label: "IFSC Code",
        spacing: 3,
        value: profile?.ifsc_code ?? "-",
      },
      {
        label: "SWIFT Code",
        spacing: 3,
        value: profile?.swift_code ?? "-",
      },
      {
        label: "PAN No",
        spacing: 3,
        value: profile?.pan_no?.substring(0, 20) ?? "-",
      },
      {
        label: "Do you have Aadhaar",
        spacing: 3,
        value: profile?.aadhar_available ? "Yes" : "No",
      },
      {
        label: "Corpous Amount",
        spacing: 3,
        value: profile?.corpus_amount ?? "-",
      },
      {
        label: "Net Worth More than (2cr/10cr)",
        spacing: 3,
        value: profile?.net_worth ? "Yes" : "No",
      },
      {
        label: "Aif Fund Paid",
        spacing: 3,
        value: profile?.aif_fund_paid ? "Yes" : "No",
      },
      {
        label: "Aif Fund Paid Date",
        spacing: 3,
        value: profile?.aif_fund_paid_date
          ? moment(profile?.aif_fund_paid_date).format("MMM DD, YYYY")
          : "-",
      },

      {
        label: "Created At",
        spacing: 3,
        value: profile?.created_at
          ? moment(profile?.created_at).format("MMM DD, YYYY")
          : "-",
      },
      {
        label: "Investment Experience",
        spacing: 4,
        value: investmentExperience?.length
          ? investmentExperience?.join(", ")
          : "-",
      },
    ];
  };
  return (
    <Box>
      {details?.investment_profiles_data?.length ? (
        details?.investment_profiles_data?.map(
          (profile: any, index: number) => {
            return (
              <AccordionPanel
                defaultExpanded={index === 0}
                key={profile?.id}
                accordionSummaryContent={<span>{profile?.entity_name}</span>}
              >
                <CardContainer options={cardOptions(profile)} />
              </AccordionPanel>
            );
          }
        )
      ) : (
        <Box p={8}>
          <NoDataAvailable
            title="No Investment Profile Added"
            subTitle={"No Investment Profile"}
            image={IconConstant.DATA_NOT_FOUND_ICON}
          />
        </Box>
      )}
    </Box>
  );
};

export default InvestmentProfiles;
