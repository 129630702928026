import { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import { toast } from "react-toastify";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { CopyAll, Folder, RotateLeft, Save } from "@mui/icons-material";
import MultipleSelectCheckmarks from "../../../../ui-library/dropdowns/multiple-select-checkmark";
import QueryEditorManager from "../../managers/queryEditorManager";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { validateQuery } from "../../utils/queryEditorUtils";
import { CommonModal } from "../../../../ui-library/modals/common-modal";

const QueryEditor = ({ onExecute, onClear }: any) => {
  const [tables, setTables] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedTable, setSelectedTable] = useState<any>();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [selectedOperation, setSelectedOperation] = useState<any>();
  const [whereClause, setWhereClause] = useState("");
  const [setClause, setSetClause] = useState("");
  const [valuesClause, setValuesClause] = useState("");
  const [query, setQuery] = useState("");
  const [queryName, setQueryName] = useState("");
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [isSavedQueriesModalOpen, setIsSavedQueriesModalOpen] = useState(false);
  const [queryOptions, setQueryOptions] = useState<any>([]);
  const [selectedQuery, setSelectedQuery] = useState<any>();
  const [isSaveQueryModalOpen, setIsSaveQueryModalOpen] = useState(false);
  const queryEditorManager = new QueryEditorManager();

  useEffect(() => {
    fetchTables();
    fetchSavedQueries();
  }, []);

  const fetchSavedQueries = async () => {
    try {
      let queries = await queryEditorManager.getSavedQueries();
      queries = queries.map((query: any) => {
        return {
          label: `${query.name} <${query.created_by}>`,
          value: query.id,
          name: query.name,
          text: query.text,
        };
      });
      setQueryOptions(queries);
    } catch (error) {
      toast.error("Failed to fetch saved queries");
    }
  };

  useEffect(() => {
    if (selectedTable) fetchColumns(selectedTable.value);
  }, [selectedTable]);

  useEffect(() => {
    generateQuery();
  }, [
    selectedTable,
    selectedOperation,
    selectedColumns,
    whereClause,
    setClause,
    valuesClause,
    limit,
    offset,
  ]);

  const fetchTables = async () => {
    try {
      const tableNames = await queryEditorManager.fetchDatabaseTables();
      setTables(tableNames.map((name: any) => ({ label: name, value: name })));
    } catch (error) {
      toast.error("Failed to fetch tables");
    }
  };

  const fetchColumns = async (tableName: any) => {
    try {
      const { columns } = await queryEditorManager.fetchTableColumns(tableName);
      setColumns(columns);
    } catch (error) {
      toast.error("Failed to fetch columns");
    }
  };

  const generateQuery = () => {
    if (!selectedTable || !selectedOperation) return setQuery("");
    const table = selectedTable.value;
    const columnsString = selectedColumns.length
      ? selectedColumns.join(", ")
      : "*";

    let query = "";
    switch (selectedOperation.value) {
      case "SELECT":
        query = `SELECT ${columnsString} FROM ${table}`;
        if (whereClause) query += ` WHERE ${whereClause}`;
        if (limit) query += ` LIMIT ${limit}`;
        if (offset) query += ` OFFSET ${offset}`;
        break;
      case "INSERT":
        query = `INSERT INTO ${table} (${columnsString}) VALUES (${valuesClause})`;
        break;
      case "UPDATE":
        query = `UPDATE ${table} SET ${setClause}`;
        if (whereClause) query += ` WHERE ${whereClause}`;
        break;
      case "DELETE":
        query = `DELETE FROM ${table}`;
        if (whereClause) query += ` WHERE ${whereClause}`;
        break;
      case "COUNT":
        query = `SELECT COUNT(${columnsString}) FROM ${table}`;
        break;
      case "DISTINCT":
        query = `SELECT DISTINCT ${columnsString} FROM ${table}`;
        break;
      default:
        break;
    }
    setQuery(query);
  };

  const handleSaveQuery = async () => {
    if (!query) return toast.error("Please generate a query first");
    try {
      await queryEditorManager.saveQuery(query, queryName);
      toast.success("Query saved successfully!");
      setIsSaveQueryModalOpen(false);
      fetchSavedQueries();
    } catch (error) {
      toast.error("Failed to save query");
    }
  };

  const handleExecute = () => {
    try {
      if (!validateQuery(query, selectedOperation)) return;
      onExecute(query);
    } catch (error) {
      toast.error("Failed to execute query");
      onClear();
    }
  };

  const handleClear = () => {
    setSelectedTable(null);
    setSelectedColumns([]);
    setSelectedOperation(null);
    setWhereClause("");
    setSetClause("");
    setValuesClause("");
    setQuery("");
    onClear();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(query);
    toast.success("Query copied to clipboard!");
  };

  const extractQueryDetails = (query: string) => {
    const tableMatch = query.match(/FROM\s+(\w+)/i);
    const columnsMatch = query.match(/SELECT\s+(.*?)\s+FROM/i);
    const operationMatch = query.match(
      /^(SELECT|INSERT|UPDATE|DELETE|COUNT|DISTINCT)/i
    );

    const table = tableMatch ? tableMatch[1] : null;
    const columns = columnsMatch
      ? columnsMatch[1].split(",").map((col) => col.trim())
      : [];
    const operation = operationMatch ? operationMatch[1] : null;

    return { table, columns, operation };
  };

  const openSavedQueries = () => {
    if (!selectedQuery) return;
    const queryDetails = extractQueryDetails(selectedQuery.text);
    setSelectedTable({ label: queryDetails.table, value: queryDetails.table });
    if (queryDetails.columns.length === 1 && queryDetails.columns[0] === "*") {
      setSelectedColumns([]);
    } else {
      setSelectedColumns(queryDetails.columns);
    }
    setSelectedOperation({
      label: queryDetails.operation,
      value: queryDetails.operation,
    });
    setQuery(selectedQuery.text);
    setIsSavedQueriesModalOpen(false);
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ marginBottom: 20 }}
      >
        <Grid item xs={4}>
          <CoreDropdown
            label="Select Operation"
            options={[
              { label: "Select", value: "SELECT" },
              { label: "Insert", value: "INSERT" },
              { label: "Update", value: "UPDATE" },
              { label: "Delete", value: "DELETE" },
              { label: "Count", value: "COUNT" },
              { label: "Distinct", value: "DISTINCT" },
            ]}
            value={selectedOperation}
            handleChange={(value: any) => {
              setSelectedOperation(value);
              setWhereClause("");
              setSetClause("");
              setValuesClause("");
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CoreDropdown
            label="Select Table"
            options={tables}
            value={selectedTable}
            handleChange={setSelectedTable}
          />
        </Grid>
        <Grid item xs={4}>
          <MultipleSelectCheckmarks
            label="Select Columns"
            options={columns}
            selectedValues={selectedColumns}
            onChange={(option: any) => {
              setSelectedColumns(option);
            }}
          />
        </Grid>
        {selectedOperation?.value === "UPDATE" && (
          <Grid item xs={8}>
            <CustomTextField
              label="SET Clause"
              value={setClause}
              onChange={(value: any) => setSetClause(value)}
            />
          </Grid>
        )}
        {["SELECT", "INSERT", "UPDATE", "DELETE"].includes(
          selectedOperation?.value
        ) && (
          <Grid item xs={8}>
            <CustomTextField
              label="WHERE Clause"
              value={whereClause}
              onChange={(value: any) => setWhereClause(value)}
            />
          </Grid>
        )}
        {selectedOperation?.value === "INSERT" && (
          <Grid item xs={8}>
            <CustomTextField
              label="VALUES Clause"
              value={valuesClause}
              onChange={(value: any) => setValuesClause(value)}
            />
          </Grid>
        )}
        {selectedOperation?.value === "SELECT" && (
          <>
            <Grid item xs={2}>
              <CustomTextField
                label="Limit"
                type="number"
                value={limit}
                onChange={(value: any) => {
                  const numValue = Number(value);
                  if (numValue > 100) setLimit(100);
                  else if (numValue <= 0) setLimit(20);
                  else setLimit(numValue);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomTextField
                label="Offset"
                type="number"
                value={offset}
                onChange={(value: any) => setOffset(value)}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <ActionButton onClick={handleExecute}>Execute</ActionButton>
            <IconButton onClick={() => setIsSavedQueriesModalOpen(true)}>
              <Tooltip title="Open Saved Query">
                <Folder className="h-4 w-4" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => setIsSaveQueryModalOpen(true)}>
              <Tooltip title="Save Query">
                <Save className="h-4 w-4" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={handleCopy}>
              <Tooltip title="Copy Query">
                <CopyAll className="h-4 w-4" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={handleClear}>
              <Tooltip title="Clear Query">
                <RotateLeft className="h-4 w-4" />
              </Tooltip>
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <Editor
        height="150px"
        defaultLanguage="sql"
        theme="vs-dark"
        value={query}
        onChange={(value: any) => setQuery(value)}
        options={{
          minimap: { enabled: false },
          fontSize: 14,
          padding: { top: 16, bottom: 16 },
          suggestOnTriggerCharacters: true,
        }}
      />
      <CommonModal
        isOpen={isSavedQueriesModalOpen}
        handleClose={() => setIsSavedQueriesModalOpen(false)}
        handleSubmit={openSavedQueries}
        title="Saved Queries"
        submitText="Open"
        buttonStyles={{ width: "100%" }}
        closeIcon
      >
        <Stack mt={2} width={"45vw"}>
          <CoreDropdown
            label="Select Query"
            options={queryOptions}
            value={selectedQuery}
            handleChange={(option: any) => {
              setSelectedQuery(
                queryOptions.find((query: any) => query.value == option.value)
              );
            }}
          />
        </Stack>
      </CommonModal>
      <CommonModal
        isOpen={isSaveQueryModalOpen}
        handleClose={() => setIsSaveQueryModalOpen(false)}
        handleSubmit={handleSaveQuery}
        title="Save Query"
        submitText="Save"
        buttonStyles={{ width: "100%" }}
        closeIcon
      >
        <Stack mt={2} width={"45vw"}>
          <CustomTextField
            label="Query Name"
            value={queryName}
            onChange={(value: any) => setQueryName(value)}
            id="queryName"
            name="queryName"
          />
        </Stack>
      </CommonModal>
    </Box>
  );
};

export default QueryEditor;
