import React, { FC, useState, useContext, useEffect } from "react";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { makeStyles } from "@mui/styles";
import {
  ActionType,
  DealConsentOptions,
  INVESTMENT_TYPE,
  INVESTOR_RANKING,
  SCHEME_DOC_STATUS,
  TransferTypeOptions,
} from "../../constants/CrmConstants";
import { CustomDatePicker } from "../../../../ui-library/form-elements/DatePicker";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import { format } from "date-fns";
import { getColor } from "../../../../ui-library/Styles/Colors";
import VerifyModal from "../../../userManagement/components/modals/VerifyModal";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { AuthContext } from "../../../../contexts/authContext";

const useStyles = makeStyles({
  customModalWidth: {
    width: "40rem",
  },
  datePickerWidth: {
    width: "100%",
  },
});
import { Link } from "react-router-dom";
import { ThreeDotMenu } from "../../../../ui-library/tables/three-dot-menu";

export type CrmDataType = {
  formattedData: any[];
  page: number;
  setPage: (num: number) => void;
  count: number;
  tab: string;
  fetchCrmData: (num: number) => void;
  selectedVal: any;
};

const CrmTable: FC<CrmDataType> = (props: CrmDataType) => {
  const {
    setPage,
    formattedData,
    page,
    count,
    tab,
    fetchCrmData,
    selectedVal,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [openLockConfrim, setOpenLockConfirm] = useState<any>(false);
  const [openRestrictConfirm, setOpenRestrictConfirm] = useState<any>(false);
  const [openWithoutGSTConfirm, setOpenWithoutGSTConfirm] =
    useState<any>(false);
  const [openDrawdownSentConfirm, setOpenDrawdownSentConfirm] =
    useState<any>(false);
  const [openUnlockConfirm, setOpenUnlockConfirm] = useState<any>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isRestrictLoading, setIsRestrictLoading] = useState(false);
  const [isWithoutGSTLoading, setIsWithoutGSTLoading] = useState(false);
  const [isDrawdownLoading, setIsDrawdownLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState<any>([])
  const commitmentManagementManager = new CommitmentManagementManager();
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);

  const initialValues = {
    investment_type: null,
    ca_doc_creation_date: null,
    investor_ranking: null,
    amount_committed: 0,
    commit_date: null,
    onboarding_fee: null,
    transaction_fee: null,
    scheme_doc_status: null,
    scheme_sent_date: null,
    deal_consent_received: null,
    deal_consent_received_date: null,
    fund_consent_received: null,
    amount_transfer_status: null,
    amount_transfered: 0,
    amount_transfer_date: null,
    amount_transfer_last_date: null,
    transfer_type: null,
    country_id: null,
    comment: null,
  }; 
  const [value, setInitialValue] = useState<any>(initialValues);

  let columns: any = [
    {
      field: "investor",
      headerName: "Investor Name",
      minWidth: 200,
      headerAlign: "left",
      renderCell: (params: any) => {
        return (
          <Link
            to={`/user/user-panel/${params.row?.investor_id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.investor}{" "}
          </Link>
        );
      },
    },
    {
      field: "profile_investor_name",
      headerName: "Investor Profile Name",
      minWidth: 200,
      headerAlign: "left",
    },
    {
      field: "startup",
      headerName: "Startup Name",
      minWidth: 200,
      headerAlign: "left",
      renderCell: (params: any) => (
        <Link
          to={`/startup/startup-panel/${params.row?.startup_id}/`}
          style={{ color: getColor("action_button", 300) }}
        >
          {params?.row?.startup}{" "}
        </Link>
      ),
    },
    {
      field: "startup_scheme_name",
      headerName: "Investment Scheme Name",
      minWidth: 200,
      headerAlign: "left",
    },
    {
      field: "ca_doc_status",
      headerName: "CA Doc Status",
      minWidth: 100,
      headerAlign: "left",
      valueFormatter: (params: any) =>
        params.value != null
          ? !!params.value
            ? "Completed"
            : "Incomplete"
          : params.value,
    },
    {
      field: "investment_type",
      headerName: "Investment Type",
      minWidth: 120,
      headerAlign: "left",
      valueFormatter: (params: any) => params.value.toUpperCase(),
    },
    {
      field: "ca_doc_creation_date",
      headerName: "CA Doc Creation Date",
      minWidth: 200,
      headerAlign: "right",
      align: "right",
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "investor_ranking",
      headerName: "Investor Ranking",
      minWidth: 120,
      headerAlign: "left",
      valueFormatter: (params: any) =>
        params.value !== null && params.value !== ""
          ? "P" + params.value
          : params.value,
    },
    {
      field: "aifManager",
      headerName: "AIF Manager",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "proposed_amount",
      headerName: "Amount Committed (INR/USD)",
      minWidth: 200,
      valueFormatter: (params: any) =>
        `${params.value.amount} ${params.value.currency}`,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "commit_date",
      headerName: "Commit Date",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "onboarding_fee",
      headerName: "Onboarding Fees (INR)",
      minWidth: 150,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "transaction_fee",
      headerName: "Transaction Fees (INR)",
      minWidth: 150,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "total_fees",
      headerName: "Total Fees (Incl. GST)",
      headerAlign: "right",
      align: "right",
      minWidth: 150,
      valueGetter: (params: any) =>
        !!params.row.transfer_type &&
        ["Indian", "NRO"].includes(params.row.transfer_type)
          ? params.row.onboarding_fee +
            params.row.transaction_fee +
            0.18 * params.row.transaction_fee
          : params.row.onboarding_fee + params.row.transaction_fee,
    },
    {
      field: "fund_carry",
      headerName: "Fund Carry %",
      minWidth: 100,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "lead_carry",
      headerName: "Lead Carry %",
      minWidth: 100,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "scheme_doc_status",
      headerName: "Scheme Doc Status",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "scheme_sent_date",
      headerName: "Scheme Sent Date",
      minWidth: 200,
      headerAlign: "right",
      align: "right",
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "deal_consent_received",
      headerName: "Deal Consent Received",
      minWidth: 150,
      headerAlign: "left",
      valueFormatter: (params: any) =>
        params.value != null ? (!!params.value ? "Yes" : "No") : params.value,
    },
    {
      field: "deal_consent_received_date",
      headerName: "Deal Consent Received Date",
      minWidth: 200,
      headerAlign: "right",
      align: "right",
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "fund_consent_received",
      headerName: "Fund Consent Received",
      minWidth: 150,
      headerAlign: "left",
      valueFormatter: (params: any) =>
        params.value != null ? (!!params.value ? "Yes" : "No") : params.value,
    },
    {
      field: "amount_transfer_status",
      headerName: "Amount Transfer Status",
      minWidth: 150,
      headerAlign: "left",
      valueFormatter: (params: any) =>
        params.value != null ? (!!params.value ? "Yes" : "No") : params.value,
    },
    {
      field: "amount_transfered",
      headerName: "Amount Transfered(INR/USD)",
      minWidth: 150,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "amount_transfer_date",
      headerName: "Amount Transfer Date",
      minWidth: 200,
      headerAlign: "right",
      align: "right",
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "amount_transfer_last_date",
      headerName: "Transfer By",
      minWidth: 200,
      headerAlign: "right",
      align: "right",
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "transfer_type",
      headerName: "Transfer Type",
      headerAlign: "left",
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Country Of Transfer",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "amount_difference",
      headerName: "Difference (INR)",
      minWidth: 150,
      headerAlign: "right",
      align: "right",
      valueGetter: (params: any) =>
        !!params.row.transfer_type &&
        ["Indian", "NRO"].includes(params.row.transfer_type)
          ? params.row.onboarding_fee +
            params.row.transaction_fee +
            0.18 * params.row.transaction_fee +
            params.row.proposed_amount.amount -
            params.row.amount_transfered
          : params.row.onboarding_fee +
            params.row.transaction_fee +
            params.row.proposed_amount.amount -
            params.row.amount_transfered,
    },
    {
      field: "comment",
      headerName: "Comments",
      minWidth: 200,
      headerAlign: "left",
    },
    {
      field: "whatsapp_alert_sent",
      headerName: "Whatsapp Status",
      headerAlign: "left",
      minWidth: 120,
      valueFormatter: (params: any) =>
        params.value != null ? (!!params.value ? "Yes" : "No") : params.value,
    },
    {
      field: "whatsapp_alert_stage",
      headerName: "Whatsapp Stage",
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      maxWidth: 70,
      headerAlign: "left",
      renderCell: (params: any) => {
        const investmentType = params.row.investment_type;

        // Define lock conditions for both cases
        const directLockConditions = {
          amountTransferStatus: !!params.row.amount_transfer_status,
          amountTransfered: !!params.row.amount_transfered,
          amountTransferDate: !!params.row.amount_transfer_date,
        };

        const aifLockConditions = {
          caDocStatus: !!params.row.ca_doc_status,
          dealConsentReceived: !!params.row.deal_consent_received,
          fundConsentReceived: !!params.row.fund_consent_received,
          amountTransferStatus: !!params.row.amount_transfer_status,
          amountTransfered: !!params.row.amount_transfered,
          transferType: !!params.row.transfer_type,
          amountTransferDate: !!params.row.amount_transfer_date,
        };

        // Determine which set of conditions to evaluate
        const isDirectInvestment =
          investmentType === INVESTMENT_TYPE.DIRECT.value;
        const currentLockConditions = isDirectInvestment
          ? directLockConditions
          : aifLockConditions;

        // Check for missing fields in the selected conditions
        const missingLockFields = Object.entries(currentLockConditions)
          .filter(([_, condition]) => !condition) // Identify unmet conditions
          .map(([key]) => {
            switch (key) {
              case "amountTransferStatus":
                return "Amount Transfer Status";
              case "amountTransfered":
                return "Amount Transferred";
              case "amountTransferDate":
                return "Amount Transfer Date";
              case "caDocStatus":
                return "CA Doc Status";
              case "dealConsentReceived":
                return "Deal Consent Received";
              case "fundConsentReceived":
                return "Fund Consent Received";
              case "transferType":
                return "Transfer Type";
              default:
                return key;
            }
          });

        const lockCondition = missingLockFields.length === 0;
        const lockConditionReason = missingLockFields.length
          ? `This option is disabled because the following fields are missing: ${missingLockFields.join(
              ", "
            )}.`
          : "";

        // Define disableDrawdownConditions
        const disableDrawdownConditions = {
          caDocCreationDate: isEmpty(params.row.ca_doc_creation_date),
          caDocStatus: isEmpty(params.row.ca_doc_status),
          investmentTypeDirect: investmentType === "direct",
          transferType: isEmpty(params.row.transfer_type),
          amountTransferLastDate: isEmpty(params.row.amount_transfer_last_date),
        };

        // Generate missing fields and reason for disableDrawdown
        const missingDrawdownFields = Object.entries(disableDrawdownConditions)
          .filter(([_, condition]) => condition)
          .map(([key]) => {
            switch (key) {
              case "caDocCreationDate":
                return "CA Doc Creation Date";
              case "caDocStatus":
                return "CA Doc Status";
              case "investmentTypeDirect":
                return "Investment Type is Direct";
              case "transferType":
                return "Transfer Type";
              case "amountTransferLastDate":
                return "Amount Transfer Last Date";
              default:
                return key;
            }
          });

        const disableDrawdownReason = missingDrawdownFields.length
          ? `This option is disabled because the following fields are missing: ${missingDrawdownFields.join(
              ", "
            )}.`
          : "";

        // Define progress action options
        const progressActionOptions = [
          {
            name: ActionType.edit,
            onClickFunction: () => {
              setIsOpen(true);
              setInitialValue(getModalData(params.row));
            },
          },
          {
            name: ActionType.lock,
            disabled: !lockCondition,
            disabledReason: lockConditionReason,
            onClickFunction: () => {
              setOpenLockConfirm(params.row);
            },
          },
          {
            name: ActionType.restrict,
            disabled: params.row.user_whatsapp_switch,
            disabledReason: "This option is disabled",
            onClickFunction: () => {
              setOpenRestrictConfirm(params.row);
            },
          },
          {
            name: ActionType.without_gst,
            disabled: params.row.user_gst_switch,
            disabledReason: "This option is disabled",
            onClickFunction: () => {
              setOpenWithoutGSTConfirm(params.row);
            },
          },
          {
            name: params.row.drawdown_sent ? "Drawdown Sent" : "Send Drawdown",
            disabled: disableDrawdownReason || params.row.drawdown_sent,
            disabledReason: disableDrawdownReason,
            onClickFunction: () => {
              setOpenDrawdownSentConfirm(params.row);
            },
          },
        ];

        // Define completed action options
        const completedActionOptions = [
          {
            name: ActionType.unlock,
            onClickFunction: () => {
              setOpenUnlockConfirm(params.row);
            },
          },
        ];

        return (
          <ThreeDotMenu
            position="left"
            options={
              tab === "progress"
                ? progressActionOptions
                : completedActionOptions
            }
          />
        );
      },
    },
  ];

  const getModalData = (row: any) => {
    return {
      investor_commitment_id: row.investor_commitment_id,
      investment_type: row.aif_direct,
      ca_doc_creation_date: row.ca_doc_creation_date
        ? new Date(row.ca_doc_creation_date)
        : null,
      investor_ranking: Object.values(INVESTOR_RANKING).find(
        (item) => item.value === row.investor_ranking
      ),
      onboarding_fee: row.onboarding_fee,
      transaction_fee: row.transaction_fee,
      scheme_doc_status: Object.values(SCHEME_DOC_STATUS).find(
        (item) => item.value === row.scheme_doc_status
      ),
      scheme_sent_date: row.scheme_sent_date
        ? new Date(row.scheme_sent_date)
        : null,
      deal_consent_received: Object.values(DealConsentOptions).find(
        (item) => item.value === row.deal_consent_received
      ),
      deal_consent_received_date: row.deal_consent_received_date
        ? new Date(row.deal_consent_received_date)
        : null,
      fund_consent_received: Object.values(DealConsentOptions).find(
        (item) => item.value === row.fund_consent_received
      ),
      comment: row.comment,
      amount_committed: row.proposed_amount.amount,
      amount_transfer_status: Object.values(DealConsentOptions).find(
        (item) => item.value === row.amount_transfer_status
      ),
      amount_transfered: row.amount_transfered,
      amount_transfer_date: row.amount_transfer_date
        ? new Date(row.amount_transfer_date)
        : null,
      amount_transfer_last_date: row.amount_transfer_last_date
        ? new Date(row.amount_transfer_last_date)
        : null,
      transfer_type: Object.values(TransferTypeOptions).find(
        (item) => item.value === row?.transfer_type?.toLowerCase()
      ),
      country_id: row.country_id,
      currency: row.proposed_amount.currency,
    };
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const unlockCommit = async (investor_commitment_id: any) => {
    try {
      setIsSubmitLoading(true);
      const result = await commitmentManagementManager.unlockCommit(
        investor_commitment_id
      );
      toast.success(result?.message);
      fetchCrmData(selectedVal);
      setIsSubmitLoading(false);
    } catch (error) {
      console.error(error);
      setIsSubmitLoading(false);
      toast.error("An error occurred while submitting the data");
    }
  };

  const handleActions = async (data: any) => {
    try {
      setIsSubmitLoading(true);
      const result = await commitmentManagementManager.updateCrmData(data);
      toast.success(result?.message);
      fetchCrmData(selectedVal);
      setIsSubmitLoading(false);
    } catch (error) {
      console.error(error);
      setIsSubmitLoading(false);
      toast.error("An error occurred while submitting the data");
    }
  };

  const sendDrawdown = async (data: any) => {
    try {
      setIsSubmitLoading(true);
      const result = await commitmentManagementManager.sendDrawdown(data);
      toast.success("Drawdown sent successfully");
      fetchCrmData(selectedVal);
      setIsSubmitLoading(false);
    } catch (error: any) {
      console.error(error.message);
      setIsSubmitLoading(false);
      toast.error("Something went wrong");
    }
  };

  const getLookupOptions = async (search: string, type: string) => {
    try {
      let result = await userManagementManager.getLookupData({ search, type });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setCountryOptions(result);
    } catch (e: any) {
      console.error(e.message || "Error fetching lookup options");
    }
  };

  useEffect(() => {
    getLookupOptions("", "countries");
  }, []);

  const handleModalClose = () => {
    setIsSubmitLoading(false);
    setIsOpen(false);
  };
  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    try {
      const formattedData = {
        investor_commitment_id: value.investor_commitment_id,
        proposed_amount: {
          amount: value.amount_committed,
          currency: value.currency,
        },
        investor_ranking: value.investor_ranking?.value,
        scheme_doc_status: value.scheme_doc_status?.value,
        deal_consent_received: value.deal_consent_received?.value,
        fund_consent_received: value.fund_consent_received?.value,
        amount_transfer_status: value.amount_transfer_status?.value,
        transfer_type: value.transfer_type?.value,
        country_id: value.country_of_transfer?.value,
        ...(!isEmpty(value.amount_transfer_date) && {
          amount_transfer_date: value.amount_transfer_date,
        }),
        ...(!isEmpty(value.amount_transfer_last_date) && {
          amount_transfer_last_date: value.amount_transfer_last_date,
        }),
        ca_doc_creation_date: value.ca_doc_creation_date,
        ...(!isEmpty(value.deal_consent_received_date) && {
          deal_consent_received_date: value.deal_consent_received_date,
        }),
        ...(value.scheme_sent_date && {
          scheme_sent_date: value.scheme_sent_date,
        }),
      };
      const result = await commitmentManagementManager.updateCrmData(
        formattedData
      );
      
      if (result.success) {
        toast.success(result?.message);
        fetchCrmData(selectedVal);
        setIsOpen(false);
      }
    } catch (error) {
      toast.error("An error occurred while submitting the data");
      console.error(error);
    }
    setIsSubmitLoading(false);
  };

  const handleTransactionFee = (data: any) => {
    setInitialValue({ ...value, transaction_fee: data });
  };
  const handleOnboardingFee = (data: any) => {
    setInitialValue({ ...value, onboarding_fee: data });
  };
  let {
    investment_type,
    ca_doc_status,
    amount_transfer_status,
    transfer_type,
  } = value;
  return (
    <div>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={60}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={30}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
        leftPinnedColumns={["investor"]}
        rightPinnedColumns={["action"]}
        // defaultSortColumn={{ field: "investor_commitment_id", sort: "asc" }}
      />
      {openLockConfrim && (
        <VerifyModal
          isOpen={openLockConfrim}
          title="Lock Commit"
          message="Are you sure you want to lock this commit?"
          handleClose={() => setOpenLockConfirm(false)}
          submitting={isSubmitLoading}
          handleSubmit={() =>
            handleActions({
              investor_commitment_id: openLockConfrim.investor_commitment_id,
              lock: !openLockConfrim.lock,
            })
          }
        />
      )}
      {openRestrictConfirm && (
        <VerifyModal
          isOpen={openRestrictConfirm}
          title="Restrict Commit"
          message="Are you sure you want to restrict this commit?"
          handleClose={() => setOpenRestrictConfirm(false)}
          submitting={isSubmitLoading}
          handleSubmit={() =>
            handleActions({
              investor_commitment_id:
                openRestrictConfirm.investor_commitment_id,
              user_whatsapp_switch: !openRestrictConfirm.user_whatsapp_switch,
            })
          }
        />
      )}
      {openWithoutGSTConfirm && (
        <VerifyModal
          isOpen={openWithoutGSTConfirm}
          title="Without GST"
          message="Are you sure you want to proceed without GST?"
          handleClose={() => setOpenWithoutGSTConfirm(false)}
          submitting={isSubmitLoading}
          handleSubmit={() =>
            handleActions({
              investor_commitment_id:
                openWithoutGSTConfirm.investor_commitment_id,
              user_gst_switch: !openWithoutGSTConfirm.user_gst_switch,
            })
          }
        />
      )}
      {openDrawdownSentConfirm && (
        <VerifyModal
          isOpen={openDrawdownSentConfirm}
          title="Send Drawdown"
          message="Are you sure you want to send the drawdown?"
          handleClose={() => setOpenDrawdownSentConfirm(false)}
          submitting={isSubmitLoading}
          handleSubmit={() =>
            sendDrawdown({
              investor_commitment_id:
                openDrawdownSentConfirm.investor_commitment_id,
              ...(value?.amount_transfer_last_date && {
                transfer_by_Date: value.amount_transfer_last_date,
              }),
            })
          }
        />
      )}
      {openUnlockConfirm && (
        <VerifyModal
          isOpen={openUnlockConfirm}
          title="Unlock Commit"
          message="Are you sure you want to unlock this commit?"
          handleClose={() => setOpenUnlockConfirm(false)}
          submitting={isSubmitLoading}
          handleSubmit={() =>
            unlockCommit(openUnlockConfirm.investor_commitment_id)
          }
        />
      )}

      <CommonModal
        isOpen={isOpen}
        handleClose={handleModalClose}
        title="Edit commit tracker details"
        isLoading={isSubmitLoading}
        disableSubmitButton={isSubmitLoading}
        cancelText="&nbsp;&nbsp;Cancel&nbsp;&nbsp;"
        submitText="Submit"
        handleSubmit={handleSubmit}
        buttonStyles={{ width: "min-width" }}
        closeIcon
      >
        <Grid container width="40vw">
          <Grid item container direction="column" spacing={3}>
            <Grid item mt={2}>
              <CoreDropdown
                label="Investment Type"
                options={Object.values(INVESTMENT_TYPE)}
                onClickMenuItem={(option: any) => {
                  setInitialValue({ ...value, investment_type: option.value });
                }}
                defaultValue={Object.values(INVESTMENT_TYPE).find(
                  (item) => item.value === value.investment_type
                )}
                disabled
              />
            </Grid>
            <Grid item>
              <CustomDatePicker
                placeholder="CA Doc Creation Date"
                id="ca_doc_creation_date"
                name="ca_doc_creation_date"
                fieldName="ca_doc_creation_date"
                value={value?.ca_doc_creation_date}
                onChange={(newValue: any) =>
                  setInitialValue({ ...value, ca_doc_creation_date: newValue })
                }
                alignCalenderIcon="right"
                disabled={
                  isEmpty(value?.ca_doc_creation_date) ||
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>
            <Grid item>
              <CoreDropdown
                label="Investor Ranking"
                options={Object.values(INVESTOR_RANKING)}
                onClickMenuItem={(option: any) => {
                  setInitialValue({ ...value, investor_ranking: option });
                }}
                value={value?.investor_ranking}
                onClear={() =>
                  setInitialValue({ ...value, investor_ranking: "" })
                }
                clearable
                disabled={investment_type == "direct"}
              />
            </Grid>
            <Grid item>
              <CustomTextField
                label={"Amount Committed (INR/USD)"}
                error={false}
                value={value.amount_committed}
                onChange={(val: any) =>
                  setInitialValue({ ...value, amount_committed: val })
                }
                id="amount_committed"
                name="amount_committed"
              />
              {/* {value?.currency} */}
            </Grid>
            <Grid item>
              <CustomTextField
                label={"Onboarding Fees (INR)"}
                error={false}
                value={value.onboarding_fee}
                onChange={handleOnboardingFee}
                id="onboarding_fee"
                name="onboarding_fee"
                disabled
              />
            </Grid>
            <Grid item>
              <CustomTextField
                label={"Transaction Fees (INR)"}
                error={false}
                value={value.transaction_fee}
                onChange={handleTransactionFee}
                id="transaction_fee"
                name="transaction_fee"
                disabled
              />
            </Grid>
            <Grid item>
              <CoreDropdown
                label="Scheme Doc Status"
                options={Object.values(SCHEME_DOC_STATUS)}
                onClickMenuItem={(option: any) => {
                  setInitialValue({ ...value, scheme_doc_status: option });
                }}
                value={value?.scheme_doc_status}
                onClear={() =>
                  setInitialValue({ ...value, scheme_doc_status: "" })
                }
                clearable
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>
            <Grid item>
              <CustomDatePicker
                placeholder="Scheme Sent Date"
                id="scheme_sent_date"
                name="scheme_sent_date"
                fieldName="scheme_sent_date"
                value={value?.scheme_sent_date}
                onChange={(newValue: any) =>
                  setInitialValue({ ...value, scheme_sent_date: newValue })
                }
                alignCalenderIcon="right"
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>
            <Grid item>
              <CoreDropdown
                label="Deal Consent Received"
                options={DealConsentOptions}
                onClickMenuItem={(option: any) => {
                  setInitialValue({ ...value, deal_consent_received: option });
                }}
                value={value?.deal_consent_received}
                onClear={() =>
                  setInitialValue({ ...value, deal_consent_received: "" })
                }
                clearable
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>
            <Grid item>
              <CustomDatePicker
                placeholder="Deal Consent Received Date"
                id="deal_consent_received_date"
                name="deal_consent_received_date"
                fieldName="deal_consent_received_date"
                value={value?.deal_consent_received_date}
                onChange={(newValue: any) =>
                  setInitialValue({
                    ...value,
                    deal_consent_received_date: newValue,
                  })
                }
                alignCalenderIcon="right"
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>

            <Grid item>
              <CoreDropdown
                label="Fund Consent Received"
                options={DealConsentOptions}
                onClickMenuItem={(option: any) => {
                  setInitialValue({ ...value, fund_consent_received: option });
                }}
                value={value?.fund_consent_received}
                onClear={() =>
                  setInitialValue({ ...value, fund_consent_received: "" })
                }
                clearable
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>

            <Grid item>
              <CoreDropdown
                label="Amount Transfer Status"
                options={DealConsentOptions}
                onClickMenuItem={(option: any) => {
                  setInitialValue({ ...value, amount_transfer_status: option });
                }}
                value={value?.amount_transfer_status}
                onClear={() =>
                  setInitialValue({ ...value, amount_transfer_status: "" })
                }
                clearable
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>

            <Grid item>
              <CustomTextField
                label={"Amount Transfered (INR/USD)"}
                error={false}
                value={value.amount_transfered}
                onChange={(val: any) =>
                  setInitialValue({ ...value, amount_transfered: val })
                }
                id="amount_transfered"
                name="amount_transfered"
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" &&
                    ca_doc_status &&
                    !amount_transfer_status &&
                    (transfer_type == null || transfer_type == "Indian"))
                }
              />
            </Grid>

            <Grid item>
              <CustomDatePicker
                placeholder="Amount Transfer Date"
                id="amount_transfer_date"
                name="amount_transfer_date"
                fieldName="amount_transfer_date"
                value={value?.amount_transfer_date}
                onChange={(newValue: any) =>
                  setInitialValue({ ...value, amount_transfer_date: newValue })
                }
                alignCalenderIcon="right"
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>

            <Grid item>
              <CustomDatePicker
                placeholder="Last Date To Transfer By"
                id="amount_transfer_last_date"
                name="amount_transfer_last_date"
                fieldName="amount_transfer_last_date"
                value={value?.amount_transfer_last_date}
                onChange={(newValue: any) =>
                  setInitialValue({
                    ...value,
                    amount_transfer_last_date: newValue,
                  })
                }
                alignCalenderIcon="right"
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>
            <Grid item>
              <CoreDropdown
                label="Transfer Type"
                options={TransferTypeOptions}
                onClickMenuItem={(option: any) =>
                  setInitialValue({ ...value, transfer_type: option })
                }
                value={value?.transfer_type}
                onClear={() => setInitialValue({ ...value, transfer_type: "" })}
                clearable
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" && ca_doc_status)
                }
              />
            </Grid>

            <Grid item>
              <CoreDropdown
                label="Country of Transfer"
                options={countryOptions}
                onClickMenuItem={(option: any) =>
                  setInitialValue({ ...value, country_of_transfer: option })
                }
                value={value?.country_of_transfer}
                onClear={() =>
                  setInitialValue({ ...value, country_of_transfer: "" })
                }
                clearable
                disabled={
                  investment_type == "direct" ||
                  (investment_type == "aif" &&
                    ca_doc_status &&
                    !amount_transfer_status &&
                    (transfer_type == null || transfer_type == "Indian"))
                }
              />
            </Grid>

            <Grid item>
              <CustomTextField
                label="Comments"
                onChange={(val: string) =>
                  setInitialValue({ ...value, comment: val })
                }
                value={value?.comment}
              />
            </Grid>
          </Grid>
        </Grid>
      </CommonModal>
    </div>
  );
};

export default CrmTable;
