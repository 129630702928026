import { createUpdateRSVPEvent, deleteEvent, fetchCrmList, fetchEventList, fetchRsvpEventData, publishRSVPEvent } from "../services/campaignManagementService";

export default class CampaignManagementManager {
  async getCrmList(type: string) {
    try {
      let result = await fetchCrmList(type);
      let formattedData = [];
      if (result?.data?.success) {
        formattedData = result?.data?.result;
      }
      return { formattedData };
    } catch (error) {
      throw error;
    }
  }

  async getEventList(tab: string, page: number) {
    try {
      let result = await fetchEventList(tab, page);
      let formattedData = result?.data?.results;
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async removeEvent(event_id: number) {
    try {
      let result = await deleteEvent(event_id);
    } catch (error) {
      throw error;
    }
  }

  async publishEvent(event_id: number, publish: boolean) {
    try {
      let result = await publishRSVPEvent(event_id, publish);
    } catch (error) {
      throw error;
    }
  }

  async createUpdateRSVPEvent(data: any) {
    try {
      let result = await createUpdateRSVPEvent(data);
    } catch (error) {
      throw error;
    }
  }

  async fetchRsvpEventData(event_id: any) {
    try {
      let result = await fetchRsvpEventData(event_id);
      let formattedData = result?.data;
      return formattedData;
    } catch (error) {
      throw error;
    }
  }
}
