export const OrderTypeOptions: any = [
  {
    label: "Transaction Fee",
    value: "transaction_fee",
  },
  {
    label: "DrawDown",
    value: "drawdown",
  },
  {
    label: "AIF",
    value: "aif",
  },
  {
    label: "Annual Subscription",
    value: "subscription",
  },
];
