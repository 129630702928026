import * as Yup from "yup";

export const SyndicateTypeOptions = [
  {
    label: "Regular",
    value: "normal",
  },
  {
    label: "Private",
    value: "private",
  },
  {
    label: "Zeno",
    value: "zeno",
  },
];

export const formPlaceholderConfig = Array(12).fill("input");

export const syndicateFormValidationSchema = {
  syndicateName: Yup.string().required("Syndicate Name is required"),
  selectedInvestor: Yup.object().required("Investor is required"),
  syndicateType: Yup.object().required("Syndicate Type is required"),
  syndicateHeader: Yup.string().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.required("Syndicate Header is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  aboutSyndicate: Yup.string().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.required("About Syndicate is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  syndicateInvestmentThesis: Yup.string().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) =>
      schema.required("Syndicate Investment Thesis is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  minInvestment: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.required("Minimum Investment is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  maxInvestment: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.required("Maximum Investment is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  syndicateDealPerYear: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) =>
      schema.required("Syndicate Deal Per Year is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  syndicateProfileImage: Yup.array().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.min(1).required("Profile Image is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  syndicateCoverImage: Yup.array().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.min(1).required("Cover Image is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  maxCorpus: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) => schema.required("Maximum Corpus is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  preCommitTenure: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) =>
      schema.required("Pre-commit Syndicate Tenure is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  managementFee: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) =>
      schema.required("Management Fee Percentage is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  numberOfDrawdowns: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) => schema.required("Number of Drawdowns is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  drawDownIntervals: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) =>
      schema.required("Expected Drawdown Interval is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  LvCarry: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) => schema.required("LV Carry is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  hurdleRate: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) => schema.required("Hurdle Rate is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
};
