import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
} from "@mui/material";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import React from "react";
import { useState } from "react";
import { ReviewStatusOptions } from "./KycStatusConstant";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import UserManagementManager from "../../managers/userManagementManager";

const UpdateReviewStatusModal = (props: any) => {
  const { isOpen, handleClose, selected, reload } = props;
  const userData = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(userData);

  const [submitting, setSubmitting] = useState(false);
  const [reviewStatus, setReviewStatus] = useState<any>();
  const [kycMatch, setKycMatch] = useState(false);
  const [kycValue, setKycValue] = useState<any>();
  const [kraMatch, setKraMatch] = useState(false);
  const [kraValue, setKraValue] = useState<any>();
  const [comment, setComment] = useState("");

  const updateStatus = async () => {
    try {
      setSubmitting(true);
      const result = await userManagementManager.updateReviewStatus(
        selected?.id,
        selected?.user_id,
        kycValue,
        comment,
        kraValue,
        kraMatch,
        kycMatch,
        reviewStatus?.value
      );
      toast.success("Review Status Updated Successfully");
      setSubmitting(false);

      reload(1);
      handleClose();
    } catch (e: any) {
      setSubmitting(false);
      toast.error(e);
    }
  };

  useEffect(() => {
    setReviewStatus(
      ReviewStatusOptions?.find(
        (item: any) => item?.label === selected?.lv_review_stage
      )
    );
    setKycMatch(selected?.kyc_matches === "Yes" ? true : false);
    setKraMatch(selected?.kra_matches === "Yes" ? true : false);
    setComment(selected?.comments);
    setKycValue(selected?.ckyc_no);
    setKraValue(selected?.kra_id);
  }, [selected]);

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={updateStatus}
      disableSubmitButton={!selected?.id || submitting}
      isLoading={submitting}
      title="Update Review Status"
      submitText="&nbsp;&nbsp;Update Status&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Close&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
      closeIcon
    >
      <Box width="40vw">
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CoreDropdown
                options={ReviewStatusOptions}
                value={reviewStatus}
                onClickMenuItem={(val: any) => setReviewStatus(val)}
                label="Move Review Status"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                key={1}
                control={
                  <Checkbox
                    checked={kycMatch}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setKycMatch(e.target.checked)
                    }
                    value={kycMatch}
                    size="small"
                  />
                }
                label="KYC Matches"
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                label="KYC Value"
                value={kycValue}
                type="text"
                onChange={(val: any) => setKycValue(val)}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                key={1}
                control={
                  <Checkbox
                    checked={kraMatch}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setKraMatch(e.target.checked)
                    }
                    value={kraMatch}
                    size="small"
                  />
                }
                label="KRA Matches"
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                label="KRA Value"
                value={kraValue}
                type="text"
                onChange={(val: any) => setKraValue(val)}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                value={comment}
                label={"Comment"}
                placeholder="Enter Comment"
                type="number"
                multiline
                minRows={4}
                onChange={(val: any) => setComment(val)}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default UpdateReviewStatusModal;
