import requestManager from "../../../api/requestManager";

export const getInvestorInformation = (queryString: string) => {
  const finalURL = `/v1/investor_informations/get_admin_investor_informations/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getNomineeInformation = (queryString: string) => {
  const finalURL = `/v1/investor_informations/nominee_manager/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getDematInformation = (queryString: string) => {
  const finalURL = `/v1/investor_informations/demat_manager/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const unlockDematInformation = (body: any) =>
  requestManager.apiPut(
    `/v1/investor_informations/update_lock_demat_details/`,
    body
  );

export const verifyDematInformation = (body: any) =>
  requestManager.apiPut(`/v1/investor_informations/update_verify_demat/`, body);
