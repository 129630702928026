import { getQueryString } from "../../../utils/apiUtils";
import {
  fetchCrmList,
  fetchCrmData,
  updateCrmTrackerData,
  unlockCommit,
  createPaymentLink,
  commitWithdrawnInfo,
  getPaymentStatus,
  getPostCommitStatusList,
  sendDrawdown,
} from "../services/commitmentMangementService";

export default class CommitmentManagementManager {
  async getCrmList(type: string) {
    try {
      let result = await fetchCrmList(type);
      let formattedData = [];
      if (result?.data?.success) {
        formattedData = result?.data?.result;
      }
      return { formattedData };
    } catch (error) {
      throw error;
    }
  }

  async getCrmData(type: string, tab: string, data: any) {
    try {
      let result = await fetchCrmData(type, tab, data);
      let formattedData = [];
      if (result?.data?.result?.success) {
        formattedData = result?.data?.result?.data;
      }
      return { formattedData };
    } catch (error) {
      throw error;
    }
  }

  async updateCrmData(data: any) {
    try {
      let result = await updateCrmTrackerData(data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async sendDrawdown(data: any) {
    try {
      let result = await sendDrawdown(data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async unlockCommit(investor_commitment_id: any) {
    try {
      let result = await unlockCommit(investor_commitment_id);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async createPaymentLink(data: any) {
    try {
      let dataToUpdate = new FormData();
      dataToUpdate.append(
        "investor_information_id",
        data?.investmentProfile?.value
      );
      dataToUpdate.append("order_type", data.orderType.value);
      dataToUpdate.append("order_amount", data.amount);
      dataToUpdate.append("order_note", "Manual Order Created");
      if (!["aif", "subscription"].includes(data.orderType.value)) {
        dataToUpdate.append("startup_id", data?.selectedStartup.value);
      }
      let result = await createPaymentLink(dataToUpdate);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async commitWithdrawnInfo(page: number) {
    try {
      let result = await commitWithdrawnInfo(page);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async transactionPaymentStatus(
    page: number,
    startup_id: string,
    status_type: string
  ) {
    try {
      const queryString = getQueryString({
        page,
        startup_id,
        payment_status: status_type,
        order_type: "transaction_fee",
      });
      let result = await getPaymentStatus(queryString);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async drawdownPaymentStatus(
    page: number,
    startup_id: string,
    status_type: string
  ) {
    try {
      const queryString = getQueryString({
        page,
        startup_id,
        payment_status: status_type,
        order_type: "drawdown",
      });
      let result = await getPaymentStatus(queryString);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async getPostCommitStatusList(selectedTab: string, page: number) {
    try {
      let result = await getPostCommitStatusList(selectedTab, page);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }
}
