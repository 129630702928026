import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_STATUS } from "../../../../constants/commonConstants";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import NoteModal from "../../../userManagement/components/modals/NoteModal";
import ArticleIcon from "@mui/icons-material/Article";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";

import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { ApplicationLoader } from "../../../../ui-library/loaders/application-loader";

type QuizParams = {
  id: string;
};

const ShowInstitution = () => {
  const { id = "" } = useParams<QuizParams>();
  const [isNoteMoalOpen, setIsNoteModalOpen] = useState(false);
  const [loading, setLoading] = useState(API_STATUS.SUCCESS);
  const [detail, setDetail] = useState<any>({});
  const [accountManager, setAccountManager] = useState();

  const handleCloseNoteModal = () => {
    setIsNoteModalOpen(false);
  };
  const handleOpenNoteModal = () => {
    setIsNoteModalOpen(true);
  };

  const [selectedUser, setSelectedUser] = useState<any>({});
  const [userOptions, setUserOptions] = useState<any>([]);

  return loading === API_STATUS.LOADING ? (
    <ApplicationLoader fullScreen />
  ) : (
    <Box>
      {isNoteMoalOpen && (
        <NoteModal
          isOpen={isNoteMoalOpen}
          handleClose={handleCloseNoteModal}
          selected={{ id: "", name: "" }}
          type="User"
        />
      )}
      <Grid container rowGap={2}>
        <Grid item xs={3}>
          <Box display="flex" alignItems="center" gap={2}>
            <ArticleIcon />
            <Typography variant="h5">Name of Institution</Typography>
          </Box>
        </Grid>
        <Grid xs={9} textAlign="end">
          <Box display="inline-flex">
            <Box alignItems="center" gap={2} display="flex">
              <ActionButton onClick={handleOpenNoteModal}>
                View Notes
              </ActionButton>
              <ActionButton onClick={handleOpenNoteModal}>
                View Institution
              </ActionButton>{" "}
              <ActionButton onClick={handleOpenNoteModal}>
                Update Institution
              </ActionButton>
            </Box>
          </Box>
        </Grid>
        <br />
        <br />
        {/* headers */}
        <Grid item xs={4}>
          <Typography variant="h6">Field</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Actions</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ borderWidth: 1 }} />
        </Grid>

        {/* user details */}

        <Grid item xs={4}>
          <Typography>Name</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Name</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Publish Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.user?.published ? "Published" : "UnPublished"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="text"
            // onClick={
            //   !!detail?.user?.published ? handleUnPublish : handlePublish
            // }
          >
            {!!detail?.user?.published ? "UnPublished" : "Publish"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Admins</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: "10px",
          }}
        >
          <Typography>1. Admin1</Typography>

          <Typography>2. Admin2</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text" sx={{ display: "block", margin: "auto" }}>
            Remove Admin
          </Button>
          <Button variant="text" sx={{ display: "block", margin: "auto" }}>
            Remove Admin
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Non Admins</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: "10px",
          }}
        >
          <Typography>1. Admin1</Typography>

          <Typography>2. Admin2</Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Blocked?</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{!!detail?.blocked ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="text"
            // onClick={
            //   !!detail?.user?.published ? handleUnPublish : handlePublish
            // }
          >
            {!!detail?.blocked ? "UnBlock" : "Block"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>UAN?</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{!!detail?.uan ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Switch</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Startup Manager</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.activated ? "Activated" : "Not Activated"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Grant</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Account Manager</Typography>
        </Grid>
        <Grid item xs={4}>
          <CoreDropdown
            label="Change Account Manager"
            options={[{ label: "Sanjay Jha", value: "sanjayjha" }]}
            value={accountManager}
            onClickMenuItem={(value: any) => setAccountManager(value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Change Account Manager</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Add Team Members </Typography>
        </Grid>
        <Grid item xs={4}>
          <LvAutoComplete
            label="Select User"
            options={userOptions}
            setOptions={setUserOptions}
            selectedObject={selectedUser}
            setSelectedObject={setSelectedUser}
            onSelect={() => setUserOptions([])}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text" sx={{ display: "block", margin: "auto" }}>
            Add as Admin
          </Button>
          <Button variant="text" sx={{ display: "block", margin: "auto" }}>
            Add as Non-Admin
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShowInstitution;
