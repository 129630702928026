import { FC, useState, useMemo, useContext, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { FormContainer } from "../../../../../ui-library/form-elements/FormContainer";
import { AuthContext } from "../../../../../contexts/authContext";
import UserManagementManager from "../../../managers/userManagementManager";
import { RadioButtonSelect } from "../../../../../ui-library/form-elements/RadioButtonSelect";
import { ToggleSwitch } from "../../../../../ui-library/buttons/ToggleSwitch";
import { toast } from "react-toastify";
import { Dropdown } from "../../../../../ui-library/dropdowns/core-dropdown";
import {
  PlanTypeObj,
  PlanTypeOptionsArray,
} from "../../../constants/USER_PANEL_CONSTANTS";

type QuizParams = {
  user_id: string;
};

const SubscriptionDetailEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { detail } = location?.state ?? {};
  const { user_id = "" } = useParams<QuizParams>();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<any>(false);
  const [submitting, setSubmitting] = useState(false);

  const userManagementManager = new UserManagementManager(authContext);
  const initialValues = useMemo(
    () => ({
      planType: detail?.subscription_data?.plan_type ?? "",
    }),
    [detail]
  );

  const EditBreadCrumbData = useMemo(
    () => ({
      stepKey: 3,
      breadcrumbSeparator: true,
      steps: [
        { title: "User Management", titleDisplay: true },
        { title: "Subscription Details", titleDisplay: true },
        { title: "Edit Subscription Details", titleDisplay: true },
      ],
      pageHeader: {
        header: detail?.name ?? "Edit Subscription Details",
        navigationButton: true,
        navigationFunction: () =>
          navigate(`/user/user-panel/${user_id}/`, {
            state: { selectedTab: "subscription_details" },
          }),
      },
    }),
    [navigate]
  );

  const handleStartupDetailsSubmit = async (values: any) => {
    try {
      setSubmitting(true);

      await userManagementManager.updatePlanType(
        user_id,
        PlanTypeObj.get(isConfirmationModalOpen?.planType?.value)
      );
      toast.success("Subscription details submitted successfully");
      navigate(`/user/user-panel/${user_id}/`, {
        state: { selectedTab: "subscription_details" },
      });
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      toast.error("Failed to submit subscription details");
    }
  };

  useEffect(() => {
    if (!detail) {
      navigate(-1);
      toast.error(
        "Please select a user from User Panel to edit subscription details"
      );
    } else if (detail?.subscription_data?.active) {
      navigate(-1);
      toast.error("This user's subscription is active. You cannot edit it.");
    }
  }, [user_id, detail]);

  return (
    <Stack>
      <Grid container>
        <Grid item xs={5}>
          <Breadcrumb
            steps={EditBreadCrumbData.steps}
            pageHeader={EditBreadCrumbData.pageHeader}
            stepKey={EditBreadCrumbData.stepKey}
            breadcrumbSeparator
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => setIsConfirmationModalOpen(values)}
      >
        {({ values, errors, setFieldValue, handleSubmit, dirty }) => (
          <FormContainer>
            <Stack gap={2} textAlign={"left"}>
              <Dropdown
                label="Select Plan"
                id="planType"
                fieldName="planType"
                name="planType"
                options={Array.from(PlanTypeOptionsArray.values())}
                value={values.planType}
                error={errors.planType}
              />

              <Stack direction="row" justifyContent="end" mt={2}>
                <ActionButton disabled={!dirty} onClick={handleSubmit}>
                  Submit
                </ActionButton>
              </Stack>
            </Stack>
          </FormContainer>
        )}
      </Formik>
      <CommonModal
        isOpen={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleSubmit={handleStartupDetailsSubmit}
        isLoading={submitting}
        disableSubmitButton={submitting}
        title="Are you sure?"
        submitText="Submit"
        buttonStyles={{ width: "100%" }}
        closeIcon
        hideCancelButton
      >
        <Stack>Are you sure you want to submit the changes?</Stack>
      </CommonModal>
    </Stack>
  );
};

export default SubscriptionDetailEdit;
