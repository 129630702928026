import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getColor } from "../Styles/Colors";

const useStyles = makeStyles({
  root: {
    borderRadius: "0.5rem",
    backgroundColor: getColor("background", 400),
    padding: "1.875rem",
  },
  fullWidth: {
    width: "100%",
  },
});

export type FormContainerProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;
  /**
   *if true, full width container will render otherwise half width required as in forms
   */
  fullWidth?: ReactNode;
  /**
   *set customWidth of the form here.
   */
  customWidth?: string | number;
  /**
   * custom classes used for styling customizations
   */
  classNames?: string;
};

export function FormContainer({
  children,
  fullWidth,
  customWidth = "627px",
  classNames,
}: FormContainerProps) {
  const classes = useStyles();
  return (
    <Box
      id="form-container"
      className={
        classNames
          ? classNames
          : `${classes.root} ${fullWidth ? classes.fullWidth : ""}`
      }
      sx={{
        width: classNames ? undefined : customWidth,
        ["@media (max-width:900px)"]: {
          width: "100%",
        },
      }}
    >
      {children}
    </Box>
  );
}
