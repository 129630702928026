import React, { useState } from "react";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { Stack } from "@mui/material";

interface ResultsTableProps {
  data: any[];
  onUpdate: any;
}

const ResultsTable: React.FC<ResultsTableProps> = ({
  data = [],
  onUpdate,
}: any) => {
  const [page, setPage] = useState(1);

  // if (!data?.length) return null;
  let columns: any[] = [];
  let formattedData: any[] = [];
  if (data?.length) {
    columns = Object.keys(data[0]).map((key) => {
      const headerName = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
      const minWidth = headerName.length * 10;
      return {
        field: key,
        headerName,
        minWidth,
        headerAlign: "left",
      };
    });
    formattedData = data;
  }

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const isEmpty = (arr: any[]) => arr.length === 0;

  return (
    <Stack mt={2}>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={40}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={20}
        page={page}
        enablePagination
        totalRowCount={data.length}
        handlePageChange={handlePageChange}
        paginationMode="server"
        customNoRowsHeader="Execute a query to see results"
        customNoRowsMessage="You will see the results here after executing a query"
      />
    </Stack>
  );
};

export default ResultsTable;
