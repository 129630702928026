export const PostCommitDetailTabOptions: any = [
  {
    name: "Investments",
    value: "investments",
  },
  {
    name: "Global Status Management",
    value: "global_status_management",
  },
];

export const BreadCrumbDataPostCommitDetail = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Post Commit Status Detail",
    navigationButton: false,
  },
};

export const PostCommitStatusBreadcrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Post Commit Status Tracker",
    navigationButton: false,
  },
};

export const PostCommitStatusTabs: any = [
  {
    name: "Live Deals",
    value: "started",
  },
  {
    name: "Closed Deals",
    value: "finished",
  },
];

export const PostCommitStatusColumn = [
  {
    field: "name",
    headerName: "Startup Name",
    flex: 2,
    minWidth: 250,
  },
  {
    field: "start_date",
    headerName: "Post Commitment Process Started",
    flex: 2,
    minWidth: 200,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "funding_round_type",
    headerName: "Type of Round",
    flex: 2,
    minWidth: 150,
  },
  {
    field: "syndicate_close_date",
    headerName: "Syndicate Closed Date",
    flex: 2,
    minWidth: 150,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "update_date",
    headerName: "Status Last Updated Date",
    flex: 2,
    minWidth: 150,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "fundraise_amount",
    headerName: "Fundraise Amount",
    flex: 1,
    minWidth: 100,
    headerAlign: "right",
    align: "right",
  },
];

export const StageOptions = [
  {
    label: "Trasanction Document",
    value: "transaction_documents",
  },
  {
    label: "Due Diligence",
    value: "due_diligence",
  },
];

export const PositionOptions = [
  {
    label: "Yet To Start",
    value: "yet_to_start",
  },
];

export const StatusOptions = [
  {
    label: "Done",
    value: "done",
  },
];
