import { useState, useEffect } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { IconConstant } from "../../../../constants/IconConstants";
import { NoDataAvailable } from "../../../../ui-library/error/NoDataAvailable";
import StartupManagement from "../../managers/startupManager";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Stack } from "@mui/material";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { Link } from "react-router-dom";
import { Check } from "@mui/icons-material";
import VerifyModal from "../../../userManagement/components/modals/VerifyModal";

const RejectedTable = (props: any) => {
  const { sortBy, searchBySector, searchByStartup, trigger } = props;
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const [page, setPage] = useState(1);
  const [openMoveAwaitingModal, setOpenMoveAwaitingModal] = useState(false);
  const [submittingAction, setSubmittingAction] = useState(false);

  const getCaDocStatus = async (pageNo: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await startupManagement.getInitialScreeningDocs(
          "rejected",
          pageNo,
          searchByStartup ?? "",
          sortBy?.value ?? "",
          searchBySector?.value ?? ""
        );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleMoveToAwaiting = async (id: any) => {
    try {
      setSubmittingAction(true);
      const result = await startupManagement.initialScreeningAction(id, null);
      toast.success(result?.detail);
      setSubmittingAction(false);
      setOpenMoveAwaitingModal(false);
      getCaDocStatus(1);
      setPage(1);
    } catch (e: any) {
      setSubmittingAction(false);
      toast.error(e?.detail);
    }
  };

  const columns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      sortable: false,
      minWidth: 250,
      headerAlign: "left",

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            to={`/startup/startup-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "applied_on",
      headerName: "Applied On",
      sortable: false,
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "marked_good",
      headerName: "Marked Good",
      sortable: false,
      flex: 2,
      minWidth: 130,
      align: "center",
      renderCell: (params: any) => (params?.row?.marked_good ? <Check /> : ""),
    },
    {
      field: "referred_type_and_by",
      headerName: "Referred Type - Referred By",
      sortable: false,
      headerAlign: "left",
      minWidth: 250,
    },
    {
      field: "rejected_by",
      headerName: "Rejected By",
      sortable: false,
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      minWidth: 200,
      align: "center",
      renderCell: (params: any) => {
        return (
          <Stack gap={2} direction="row">
            <OutlinedButton
              onClick={() => setOpenMoveAwaitingModal(params.row?.id)}
              tableAction
            >
              Move To Awaiting
            </OutlinedButton>
          </Stack>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getCaDocStatus(page + 1);
  };

  useEffect(() => {
    getCaDocStatus(1);
    setPage(1);
  }, [trigger]);

  if (apiStatus === API_STATUS.ERROR) {
    return (
      <div>
        <NoDataAvailable
          title="Error"
          subTitle={"Something went wrong"}
          image={IconConstant.DATA_NOT_FOUND_ICON}
        />
      </div>
    );
  }

  return apiStatus == API_STATUS.LOADING ? (
    <TablePlaceholder
      rows={6}
      columns={6}
      rowHeight="40px"
      columnHeaderHeight="40px"
    />
  ) : (
    <div>
      {openMoveAwaitingModal && (
        <VerifyModal
          title="Move To Awaiting Status"
          message="Are you sure you want to move this startup to awaiting status?"
          isOpen={openMoveAwaitingModal}
          handleClose={() => setOpenMoveAwaitingModal(false)}
          handleSubmit={() => handleMoveToAwaiting(openMoveAwaitingModal)}
          submitting={submittingAction}
        />
      )}
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={40}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={10}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
        rightPinnedColumns={["action"]}
      />
    </div>
  );
};

export default RejectedTable;
