import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { AuthContext } from "../../../../contexts/authContext";
import { useContext } from "react";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import StartupManagement from "../../managers/startupManager";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getColor } from "../../../../ui-library/Styles/Colors";

export default function StartupTable() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const startupManagement = new StartupManagement(authContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [startupOptions, setStartupOptions] = useState<any>([]);
  const [selectedStartup, setSelectedStartup] = useState<any>(null);
  const [startupSchemeOptions, setStartupSchemeOptions] = useState<any>([]);
  const [selectedStartupScheme, setSelectedStartupScheme] = useState<any>(null);

  let columns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      minWidth: 150,
      headerAlign: "left",
      renderCell: (params: any) => {
        return (
          <Link
            to={`/startup/startup-panel/${params.row.startup_id}`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.startup_name}
          </Link>
        );
      },
    },
    {
      field: "startup_id",
      headerName: "Primary ID",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "website",
      headerName: "Website",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "stage",
      headerName: "Stage",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "scheme_name",
      headerName: "Scheme Name",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "created_at",
      headerName: "Created Date",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "updated_at",
      headerName: "Last Updated Date",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "is_faas",
      headerName: "FAAS",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "published",
      headerName: "Published",
      minWidth: 150,
      headerAlign: "left",
    },
  ];

  const handleStartupSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "admin_search_startups",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setStartupOptions(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const handleSchemeSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "admin_search_startup_scheme",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setStartupSchemeOptions(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  async function getStartupList(startupId: any = null, schemeId: any = null) {
    try {
      setApiStatus(API_STATUS.LOADING);
      let result = await startupManagement.getStartupList(
        page,
        startupId,
        schemeId
      );
      setCount(result.count);
      setFormattedData(result.results);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error) {
      toast.error("Error while fetching startup list");
      setApiStatus(API_STATUS.ERROR);
    }
  }

  useEffect(() => {
    getStartupList(selectedStartup?.value, selectedStartupScheme?.value);
  }, [page, selectedStartup, selectedStartupScheme]);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "Startup Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "Startup List",
      navigationButton: false,
    },
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box width="23vw">
            <LvAutoComplete
              label={"Search Startup"}
              options={startupOptions}
              setOptions={setStartupOptions}
              selectedObject={selectedStartup}
              setSelectedObject={setSelectedStartup}
              onSelect={() => {
                setStartupOptions([]);
                setSelectedStartupScheme(null);
                setPage(1);
              }}
              onChange={handleStartupSearch}
            />
          </Box>
          <Box width="23vw">
            <LvAutoComplete
              label={"Search Startup's Scheme"}
              options={startupSchemeOptions}
              setOptions={setStartupSchemeOptions}
              selectedObject={selectedStartupScheme}
              setSelectedObject={setSelectedStartupScheme}
              onSelect={() => {
                setStartupSchemeOptions([]);
                setSelectedStartup(null);
                setPage(1);
              }}
              onChange={handleSchemeSearch}
            />
          </Box>
        </Grid>
      </Grid>

      <br />
      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={10}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
            rightPinnedColumns={["action"]}
          />
        </>
      )}
    </Box>
  );
}
