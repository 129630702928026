import { Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import SyndicateManagement from "../../managers/syndicateManagementManager";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import SyndicateForm from "./SyndicateForm";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { FormPlaceholder } from "../../../../ui-library/placeholders/form-placeholder/FormPlaceholder";
import { formPlaceholderConfig } from "../../constants";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Syndicate Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Update Syndicate",
    navigationButton: false,
  },
};

const UpdateSyndicate = () => {
  const authUser = useContext(AuthContext);
  const syndicateManagementManager = new SyndicateManagement(authUser);
  const userManagementManager = new UserManagementManager(authUser);
  const [selectedSyndicate, setSelectedSyndicate] = useState<any>({});
  const [syndicateOptions, setSyndicateOptions] = useState<any>([]);
  const [initialData, setInitialData] = useState<any>();
  const [initialLoading, setInitialLoading] = useState(false);

  const searchSyndicate = async (search: string) => {
    try {
      let result = await userManagementManager.getLookupData({
        search,
        type: "syndicate",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setSyndicateOptions(result);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const formatInitialData = (data: any) => {
    return {
      syndicateName: data?.name,
      syndicatePermalink: data?.permalink,
      syndicateType: {
        label: data?.syndicate_type === "normal" ? "Regular" : "Zeno",
        value: data?.syndicate_type,
      },
      isPrivateSyndicate: data?.is_private ? "yes" : "no",
      syndicateHeader: data?.header,
      aboutSyndicate: data?.description,
      syndicateInvestmentThesis: data?.investment_thesis,
      minInvestment: data?.min_typical_investment,
      maxInvestment: data?.max_typical_investment,
      syndicateDealPerYear: data?.deal_per_year,
      syndicateProfileImage: data?.syndicate_profile_img
        ? [
            {
              path: data?.syndicate_profile_img,
            },
          ]
        : [],
      syndicateCoverImage: data?.syndicate_cover_image
        ? [
            {
              path: data?.syndicate_cover_image,
            },
          ]
        : [],
      maxCorpus: data?.syndicate_corpus,
      preCommitTenure: data?.pre_commit_tenure,
      managementFee: data?.management_fee,
      numberOfDrawdowns: data?.no_of_drawdowns,
      drawDownIntervals: data?.drawdown_interval,
      LvCarry: data?.lv_carry,
      hurdleRate: data?.hurdle_rate,
      selectedMailer: {
        value: data?.backer_mailer_list,
        label: data?.mailer_list_name,
      },
      selectedInvestor: selectedSyndicate,
    };
  };

  const getSyndicateDetail = async () => {
    try {
      setInitialLoading(true);
      const result = await syndicateManagementManager.getSyndicateDetail(
        selectedSyndicate?.value
      );
      const formattedData = formatInitialData(result);
      setInitialData(formattedData);
      setInitialLoading(false);
    } catch (e: any) {
      toast.error(e);
      setInitialLoading(false);
    }
  };

  const resetUpdateForm = () => {
    setSelectedSyndicate({});
  };

  useEffect(() => {
    if (selectedSyndicate?.value) getSyndicateDetail();
  }, [selectedSyndicate?.value]);

  return (
    <Stack>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>
      <FormContainer>
        <LvAutoComplete
          label={"Select Syndicate"}
          options={syndicateOptions}
          setOptions={setSyndicateOptions}
          selectedObject={selectedSyndicate}
          setSelectedObject={setSelectedSyndicate}
          onSelect={() => setSyndicateOptions([])}
          onChange={searchSyndicate}
        />
      </FormContainer>
      {initialLoading ? (
        <FormContainer>
          <FormPlaceholder elementsConfig={formPlaceholderConfig} />
        </FormContainer>
      ) : (
        selectedSyndicate?.value && (
          <SyndicateForm
            data={initialData}
            isUpdate
            resetUpdateForm={resetUpdateForm}
          />
        )
      )}
    </Stack>
  );
};

export default UpdateSyndicate;
