import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";
import { CardContainer } from "../../../../ui-library/card/card-container";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";

const CommitTrackerSkeleton = () => {
  const cardOptions = [
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />,
    },
  ];

  return (
    <>
      <Box
        sx={{
          border: "1px solid #ececec",
          borderRadius: "0.5rem",
          textAlign: "left",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <CardContainer options={cardOptions} />
      </Box>
      <TablePlaceholder
        rows={6}
        columns={6}
        rowHeight="40px"
        columnHeaderHeight="40px"
      />
    </>
  );
};

export default CommitTrackerSkeleton;
