import "./loginStyles.css";
import { useAuth0 } from "@auth0/auth0-react";
import LVAdminLogo from "./lv-admin-logo.png";
import { useEffect, useState } from "react";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => setIsLoading(false);
  }, []);

  return (
    <div className="app login-container">
      <div className="login-card">
        <div className="logo-container">
          <img alt="LV Admin Logo" src={LVAdminLogo} className="logo" />
        </div>
        <div className="welcome-text">Hello, Admin!</div>
        <p className="sub-text">
          Access your admin dashboard to manage internal operations.
        </p>
        <ActionButton
          customStyles={{
            width: "100%",
            maxWidth: "300px", // Limit width for desktop
            backgroundColor: "#0F4593",
            textTransform: "none",
            fontSize: "1.125rem", // Larger text
            padding: "1rem 1.5rem", // Bigger button
          }}
          onClick={() => {
            setIsLoading(true);
            loginWithRedirect();
          }}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {isLoading ? "Signing In..." : "Sign In"}
        </ActionButton>
      </div>
    </div>
  );
}
