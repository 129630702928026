import { useEffect } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import { setHeader, setInterceptors } from "./utils/interceptor";
import "./App.css";
import AppRoutes from "./routes";
import { useAuth0 } from "@auth0/auth0-react";
import { MUI_X_PRO_LICENSE_KEY } from "./constants/commonConstants";
import { useNavigate } from "react-router-dom";

function App() {
  const { getAccessTokenSilently, logout } = useAuth0();
  const navigate = useNavigate();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    throw new Error("Missing Auth0 environment variables");
  }

  const getToken = () =>
    getAccessTokenSilently({
      authorizationParams: {
        audience: audience,
      },
    });

  const handleLogout = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  useEffect(() => {
    setHeader();
    setInterceptors(getToken, handleLogout, navigate);
    LicenseInfo.setLicenseKey(MUI_X_PRO_LICENSE_KEY);
  }, []);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
