import { API_STATUS } from "../../../../constants/commonConstants";
import { Box, Grid } from "@mui/material";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { Link } from "react-router-dom";
import { getColor } from "../../../../ui-library/Styles/Colors";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import UserManagementManager from "../../managers/userManagementManager";
import { BreadcrumbUserPanel } from "../../constants/informationConstants";

export default function UserPanel() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);

  const [selectedVal2, setSelectedVal2] = useState<any>({});
  const [option2, setOption2] = useState<any>([]);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const columns = [
    {
      field: "name",
      headerName: "Investor Name",
      headerAlign: "left",
      flex: 2,
      minWidth: 180,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/user/user-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name ? params?.row?.name : "Go to User"}
          </Link>
        );
      },
    },
    {
      field: "email",
      headerName: "Investor Email",
      headerAlign: "left",
      flex: 2,
      minWidth: 300,
    },

    {
      field: "joined",
      headerName: "Joined on",
      align: "right",
      headerAlign: "right",
      minWidth: 150,
    },
    {
      field: "phone_no",
      headerName: "Phone no.",
      headerAlign: "left",
      minWidth: 150,
    },
    {
      field: "is_profile_completed",
      headerName: "Profile Completed",
      align: "center",
      minWidth: 180,
      renderCell: (params: any) =>
        params?.row?.is_profile_completed ? "Yes" : "No",
    },
    {
      field: "is_faas",
      headerName: "Faas/Non-Faas",
      headerAlign: "left",
      minWidth: 150,
      renderCell: (params: any) => (params?.row?.is_faas ? "Faas" : "Non-Faas"),
    },
  ];

  const getData = async (page: number, userId?: string) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data = await userManagementManager.getAllUsers(page, userId);

      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleTextChange = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "only_investor_users",
        user_search_private_hashtag: "",
        without_investment_profile: true,
      });
      result = result?.results?.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption(result);
    } catch (err: any) {
      toast.error(err?.detail);
    }
  };

  const handleTextChange2 = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "users",
        user_search_private_hashtag: "",
        without_investment_profile: true,
      });
      result = result?.results?.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption2(result);
    } catch (err: any) {
      toast.error(err?.detail);
    }
  };

  const onSelect = (val: any) => {
    setOption([]);
    setSelectedVal2(null);
    getData(1, val?.value);
    setPage(1);
  };

  const onSelect2 = (val: any) => {
    setOption2([]);
    setSelectedVal(null);
    getData(1, val?.value);
    setPage(1);
  };

  useEffect(() => {
    getData(page);
  }, [page]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadcrumbUserPanel?.steps}
            pageHeader={BreadcrumbUserPanel.pageHeader}
            stepKey={BreadcrumbUserPanel.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" justifyContent="end" gap={2}>
          <Box width={"18vw"}>
            <LvAutoComplete
              label="All Approved Users"
              options={option2}
              setOptions={setOption2}
              selectedObject={selectedVal2}
              setSelectedObject={setSelectedVal2}
              onChange={handleTextChange2}
              onSelect={onSelect2}
            />
          </Box>
          <Box width={"23vw"}>
            <LvAutoComplete
              label="Search by Investor Name or Email"
              options={option}
              setOptions={setOption}
              selectedObject={selectedVal}
              setSelectedObject={setSelectedVal}
              onChange={handleTextChange}
              onSelect={onSelect}
            />
          </Box>
        </Grid>
      </Grid>

      <div style={{ overflow: "auto" }}>
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={40}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </div>
    </Box>
  );
}
