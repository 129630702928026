import { toast } from "react-toastify";

export const validateQuery = (query: string, selectedOperation: any) => {
  selectedOperation = selectedOperation?.value ?? "";
  if (!query.trim()) {
    toast.error("Please generate a query first");
    return false;
  }
  if (
    !["INSERT", "UPDATE"].includes(selectedOperation) &&
    !query.includes("FROM")
  ) {
    toast.error("Query must include a table (FROM clause)");
    return false;
  }
  return true;
};
