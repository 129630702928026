import requestManager from "../../../api/requestManager";

export const fetchLvConfigs = (page: number) => {
  const finalURL = `/v1/admin/lv_config/?page=${page}`;
  return requestManager.apiGet(finalURL);
};

export const updateLvConfigData = (data: any) => {
  const finalURL = `/v1/admin/lv_config/update_lv_config/`;
  return requestManager.apiPost(finalURL, data);
};
