import React, { FC, useContext, useState } from "react";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { Link } from "react-router-dom";
import VerifyModal from "../modals/VerifyModal";
import TextButton from "../../../../ui-library/buttons/textButton/TextButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import UserManagementManager from "../../managers/userManagementManager";
import { AuthContext } from "../../../../contexts/authContext";
import { toast } from "react-toastify";

export type AwaitingPhoneNumberType = {
  formattedData: any[];
  page: number;
  setPage: (num: number) => void;
  count: number;
  refreshData?: (pageNo: number) => void;
};

const AwaitingPhoneNumber: FC<AwaitingPhoneNumberType> = (
  props: AwaitingPhoneNumberType
) => {
  const { setPage, formattedData, page, count, refreshData } = props;
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);

  const [selected, setSelected] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      align: "right",
      headerAlign: "right",
      flex: 1,
      minWidth: 170,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/user/user-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",

      minWidth: 300,
    },
    {
      field: "mobile_number",
      headerName: "Phone No.",
      align: "center",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "startup",
      headerName: "Startup",
      headerAlign: "left",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      flex: 2,
      minWidth: 150,

      renderCell: (params: any) => {
        return (
          <OutlinedButton
            onClick={() => {
              setSelected(params.row);
              setIsVerifyModalOpen(true);
            }}
            tableAction
          >
            Mark Verified
          </OutlinedButton>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    refreshData && refreshData(page + 1);
  };

  const handleCloseVerifyModal = () => {
    setIsVerifyModalOpen(false);
  };

  const markVerified = async () => {
    try {
      setSubmitting(true);
      const result = await userManagementManager.markVerified(selected.id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        setPage(1);
        refreshData && refreshData?.(1);
      }
      setSubmitting(false);
    } catch (error: any) {
      setSubmitting(false);
      toast.error(error);
    }
  };

  return (
    <div style={{ overflow: "auto" }}>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={40}
        minWidth={750}
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={20}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
        rightPinnedColumns={["action"]}
      />
      <VerifyModal
        isOpen={isVerifyModalOpen}
        handleClose={handleCloseVerifyModal}
        handleSubmit={markVerified}
        submitting={submitting}
      />
    </div>
  );
};

export default AwaitingPhoneNumber;
