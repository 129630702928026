import {
  fetchDatabaseTables,
  fetchTableColumns,
  executeQuery,
  saveQuery,
  getSavedQueries,
} from "../services/queryEditorService";

export default class QueryEditorManager {
  async fetchDatabaseTables() {
    try {
      let result: any = await fetchDatabaseTables();
      return result?.data?.tables ?? [];
    } catch (error) {
      throw error;
    }
  }

  async fetchTableColumns(tableName: string) {
    try {
      let result: any = await fetchTableColumns(tableName);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async executeQuery(query: string) {
    try {
      const result = await executeQuery(query);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async saveQuery(query: string, name: string) {
    try {
      const result = await saveQuery(query, name);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async getSavedQueries() {
    try {
      const result = await getSavedQueries();
      return result?.data;
    } catch (error) {
      throw error;
    }
  }
}
