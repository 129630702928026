import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { useState } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import {
  FileTypeIcon,
  isEmpty,
  redirectToLetsventure,
} from "../../../../utils/commonUtils";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import StartupManagement from "../../managers/startupManager";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { startupReportsBreadCrumbData } from "../../constants/StartupReportsConstant";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ThreeDotMenu } from "../../../../ui-library/tables/three-dot-menu";
import { CommonModal } from "../../../../ui-library/modals/common-modal";

export default function StartupReports() {
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [startupOptions, setStartupOptions] = useState<any>([]);
  const [selectedStartup, setSelectedStartup] = useState<any>(null);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState<any>(false);
  const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState<any>(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<any>(false);
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState<any>(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [actionSubmitting, setActionSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const Columns = [
    {
      field: "startup_id",
      headerAlign: "left",
      headerName: "Startup ID",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "startup_name",
      headerName: "Startup Name",
      headerAlign: "left",
      sortable: false,
      minWidth: 180,
      flex: 2,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/startup/startup-panel/${params.row?.startup_id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.startup_name}{" "}
          </Link>
        );
      },
    },
    {
      field: "report_periodicity",
      headerAlign: "left",
      headerName: "Report Periodicity",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "start_date_reporting",
      headerAlign: "left",
      headerName: "Reporting Period Start Date",
      flex: 2,
      minWidth: 180,
    },
    {
      field: "end_date_reporting",
      headerAlign: "left",
      headerName: "Reporting Period End Date",
      flex: 2,
      minWidth: 180,
    },
    {
      field: "source",
      headerAlign: "left",
      headerName: "Report Source",
      flex: 2,
      minWidth: 180,
      renderCell: (params: any) => {
        return params.row.source === "lv_excel_sheet"
          ? "LV Excel Sheet"
          : params.row.source === "on_finance"
          ? "OnFinance"
          : params.row.source;
      },
    },

    {
      field: "attachements",
      headerAlign: "left",
      headerName: "Attachment",
      flex: 2,
      minWidth: 180,
      renderCell: (params: any) => {
        return (
          <Stack direction="row" spacing={1}>
            {params.row.attachements.map((attachment: any, index: number) => (
              <Tooltip key={index} title={attachment.display_name}>
                <IconButton
                  component="a"
                  href={attachment.file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileTypeIcon fileType={attachment.file_type} />
                </IconButton>
              </Tooltip>
            ))}
          </Stack>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      maxWidth: 70,
      headerAlign: "left",
      renderCell: (params: any) => {
        return (
          <ThreeDotMenu
            position="left"
            options={[
              {
                name: "Preview",
                onClickFunction: () =>
                  redirectToLetsventure(
                    `/reports/report/${params.row.id}`,
                    true,
                    false
                  ),
              },
              {
                name: "Publish",
                disabled: params?.row?.publish,
                onClickFunction: () => {
                  setIsPublishModalOpen(true);
                  setSelectedReport(params.row.id);
                },
              },
              {
                name: "Unpublish",
                disabled: !params?.row?.publish,
                onClickFunction: () => {
                  setIsUnpublishModalOpen(true);
                  setSelectedReport(params.row.id);
                },
              },
              {
                name: "Archive",
                disabled: params?.row?.archive,
                onClickFunction: () => {
                  setIsArchiveModalOpen(true);
                  setSelectedReport(params.row.id);
                },
              },
              {
                name: "Notify",
                disabled: !params?.row?.publish || params?.row?.notified,
                onClickFunction: () => {
                  setIsNotifyModalOpen(true);
                  setSelectedReport(params.row.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const getStartupReports = async (startupId: any = "") => {
    try {
      setApiStatus(API_STATUS.LOADING);
      const data = await startupManagement.getStartupReports(startupId, page);
      setFormattedData(data?.results);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleStartupSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "admin_search_startups",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setStartupOptions(result);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const handleActions = async (action: string) => {
    try {
      setActionSubmitting(true);
      const actionMap: Record<string, any> = {
        publish: { publish: true },
        unpublish: { publish: false },
        archive: { archive: true },
      };

      const successMessages: Record<string, string> = {
        publish: "Report published successfully",
        unpublish: "Report unpublished successfully",
        archive: "Report archived successfully",
        notify: "Investors notified successfully",
      };

      if (action === "notify") {
        await startupManagement.notifyInvestors(selectedReport);
      } else if (actionMap[action]) {
        await startupManagement.updateStartupActions(
          selectedReport,
          actionMap[action]
        );
      }
      const modalSetters: Record<string, (state: boolean) => void> = {
        publish: setIsPublishModalOpen,
        unpublish: setIsUnpublishModalOpen,
        archive: setIsArchiveModalOpen,
        notify: setIsNotifyModalOpen,
      };
      setActionSubmitting(false);
      modalSetters[action]?.(false);
      toast.success(successMessages[action]);
      getStartupReports();
      setSelectedReport(null);
    } catch (error: any) {
      setActionSubmitting(false);
      toast.error(error?.detail || error?.detail || "An error occurred");
    }
  };

  useEffect(() => {
    getStartupReports(selectedStartup?.value);
  }, [page, selectedStartup]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={startupReportsBreadCrumbData?.steps}
            pageHeader={startupReportsBreadCrumbData.pageHeader}
            stepKey={startupReportsBreadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box width="23vw">
            <LvAutoComplete
              label={"Search Startup"}
              options={startupOptions}
              setOptions={setStartupOptions}
              selectedObject={selectedStartup}
              setSelectedObject={setSelectedStartup}
              onSelect={() => {
                setStartupOptions([]);
                setPage(1);
              }}
              onChange={handleStartupSearch}
            />
          </Box>
        </Grid>
      </Grid>

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={Columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={10}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}
      <CommonModal
        isOpen={isPublishModalOpen}
        handleClose={() => setIsPublishModalOpen(false)}
        handleSubmit={() => handleActions("publish")}
        disableSubmitButton={actionSubmitting}
        isLoading={actionSubmitting}
        title="Publish Report"
        submitText="Yes"
        buttonStyles={{ width: "100%" }}
        closeIcon
      >
        Are you sure you want to publish this report? <br /> It will be
        accessible to all investors associated with this startup.
      </CommonModal>
      <CommonModal
        isOpen={isUnpublishModalOpen}
        handleClose={() => setIsUnpublishModalOpen(false)}
        handleSubmit={() => handleActions("unpublish")}
        isLoading={actionSubmitting}
        disableSubmitButton={actionSubmitting}
        title="Unpublish Report"
        submitText="Yes"
        buttonStyles={{ width: "100%" }}
        closeIcon
      >
        Are you sure you want to unpublish this report? <br /> It will no longer
        be visible to investors.
      </CommonModal>
      <CommonModal
        isOpen={isArchiveModalOpen}
        handleClose={() => setIsArchiveModalOpen(false)}
        handleSubmit={() => handleActions("archive")}
        isLoading={actionSubmitting}
        disableSubmitButton={actionSubmitting}
        title="Archive Report"
        submitText="Yes"
        buttonStyles={{ width: "100%" }}
        closeIcon
      >
        Are you sure you want to archive this report? <br /> This action cannot
        be undone.
      </CommonModal>
      <CommonModal
        isOpen={isNotifyModalOpen}
        handleClose={() => setIsNotifyModalOpen(false)}
        handleSubmit={() => handleActions("notify")}
        isLoading={actionSubmitting}
        disableSubmitButton={actionSubmitting}
        title="Notify Investors"
        submitText="Yes"
        buttonStyles={{ width: "100%" }}
        closeIcon
      >
        Are you sure you want to notify investors? <br /> An email will be sent
        to all investors associated with this startup.
      </CommonModal>
    </Box>
  );
}
