import requestManager from "../../../api/requestManager";

export const getInitialScreeningDocuments = (queryString: string) => {
  const finalURL = `/v1/admin/startup_management/initial_screening/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getFinalScreeningDocuments = (queryString: string) => {
  const finalURL = `/v1/admin/startup_management/final_screening/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getStartupDetail = (startup_id: string) => {
  const finalURL = `/v1/admin/startup_detail/get_startup_details/?startup_id=${startup_id}`;
  return requestManager.apiGet(finalURL);
};

export const startupActions = (
  startup_id: string,
  action: string,
  data: any
) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const finalURL = `/v1/admin/startup_detail/admin_action/?startup_id=${startup_id}&todo=${action}`;
  return requestManager.apiPut(finalURL, data, config);
};

export const initialScreeningMarkAsGood = (data: any) => {
  const finalURL = `/v1/admin/startup_management/initial_screening/mark_as_good/`;
  return requestManager.apiPut(finalURL, data);
};

export const initialScreeningAction = (data: any) => {
  const finalURL = `/v1/admin/startup_management/initial_screening/initial_screening_action/`;
  return requestManager.apiPut(finalURL, data);
};

export const finalScreeningAction = (data: any) => {
  const finalURL = `/v1/admin/startup_management/final_screening/final_screening_action/`;
  return requestManager.apiPut(finalURL, data);
};

export const getStartupReports = (startupId: string = "", page: number) => {
  const finalURL = `/v1/startup_reports/?${
    startupId ? `startup_id=${startupId}&` : ""
  }admin_view=true&page=${page}`;

  return requestManager.apiGet(finalURL);
};

export const parseReport = () => {
  const finalURL = `/v1/startup_reports/parse_report/`;
  return requestManager.apiGet(finalURL);
};

export const reportAction = (startupId: string, data: any) => {
  const finalURL = `/startup_reports/${startupId}/`;
  return requestManager.apiPut(finalURL, data);
};

export const startupReportAction = (reportId: string, status: string) => {
  const finalURL = `/v1/startup_reports/publish_portfolio_update/?id=${reportId}&status=${status}`;
  return requestManager.apiGet(finalURL);
};

export const SendInvestorCommunication = (data: any) => {
  const config = { "content-type": "multipart/form-data" };
  const finalURL = `/v1/startup_reports/send_email_to_startup_committed_investors/`;
  return requestManager.apiPost(finalURL, data, config);
};

export const getCommitmentInvestorCommunication = (page: number) => {
  const finalURL = `/v1/startup_reports/get_portfolio_update_list/?page=${page}`;
  return requestManager.apiGet(finalURL);
};

export const searchDealName = (search: string) => {
  const finalURL = `/v1/admin/lookups/get_lookup_data/?type=admin_search_startups&q=${search}`;
  return requestManager.apiGet(finalURL);
};

export const getStartupList = (
  page: Number,
  startupId: any = null,
  schemeId: any = null
) => {
  const URL = `/v1/admin/startup_management/startup/get_startup_list/?page=${page}${
    startupId ? `&startup_id=${startupId}` : ""
  }${schemeId ? `&startup_id=${schemeId}` : ""}`;
  return requestManager.apiGet(URL);
};

export const cloneStartup = (startupId: any) => {
  const finalURL = `/v1/admin/startup_management/startup/clone_startup/`
  let data = {'startup_id': startupId}
  return requestManager.apiPost(finalURL, data);
};

export const updateStartupData = (data: any, startupId: any) => {
  const finalURL = `/v1/admin/startup_detail/admin_action/${
    startupId ? `?startup_id=${startupId}` : ""
  }`;
  return requestManager.apiPut(finalURL, data, {
    "Content-Type": "multipart/form-data",
  });
};

export const updateStartupActions = (reportId: any, data: any) => {
  const finalURL = `/v1/startup_reports/${reportId}/`;
  return requestManager.apiPatch(finalURL, data);
};

export const notifyInvestors = (reportId: any) => {
  const finalURL = `/v1/startup_reports/notify_investors/?${
    reportId ? `report_id=${+reportId}` : ""
  }`;
  return requestManager.apiGet(finalURL);
};
