import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { FileUpload } from "../../../../ui-library/form-elements/file-upload/FileUpload";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";
import AifManagementManager from "../../managers/aifManagementManagers";
import { toast } from "react-toastify";
import { array, object } from "yup";

const validationSchema = object().shape({
  selectedInvestor: object().required("Investor name is required"),
  signedCaDocument: array().min(1).required("Signed CA document is required"),
});

const initialValues = {
  selectedInvestor: "",
  signedCaDocument: [],
  isMarkPaid: false,
};

const CAApprovalProcess = () => {
  const userData = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(userData);
  const aifManagementManager = new AifManagementManager();

  const [option, setOption] = useState<any>([]);

  const handleTextChange = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "only_investor_users",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const approveCAProcess = async (values: any) => {
    try {
      const result = await aifManagementManager.approveCAProcess(values);
      toast.success("CA Process Approved Successfully");
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={approveCAProcess}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }: any) => (
        <FormContainer>
          <Form>
            <Stack gap={2}>
              <LvAutoComplete
                label="Select Investor User"
                options={option}
                setOptions={setOption}
                selectedObject={values.selectedInvestor}
                setSelectedObject={(value: any) =>
                  setFieldValue("selectedInvestor", value)
                }
                onChange={handleTextChange}
                required
                error={errors.selectedInvestor && touched.selectedInvestor}
              />
              <FileUpload
                placeholder="Upload Signed CA Document*"
                helperText="upload your file here"
                selectedFile={(file: any) =>
                  setFieldValue("signedCaDocument", file)
                }
                multiple
                value={values?.signedCaDocument ?? []}
                accept={{
                  "text/pdf": [".pdf"],
                }}
                title="Upload Signed CA Document"
                hideDocumentVault
                error={errors.signedCaDocument && touched.signedCaDocument}
              />
              <FormControlLabel
                key={1}
                control={
                  <Checkbox
                    checked={values?.isMarkPaid}
                    onChange={(e) =>
                      setFieldValue("isMarkPaid", e.target.checked)
                    }
                    value={values?.isMarkPaid}
                    size="small"
                  />
                }
                label="Mark as Paid only if the funds are received"
              />
              <Stack mt={2} direction="row" justifyContent="end">
                <ActionButton onClick={handleSubmit}>Approve</ActionButton>
              </Stack>
            </Stack>
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};

export default CAApprovalProcess;
