import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { getColor } from "../Styles/Colors";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles({
  threeDotMenu: {
    "& .MuiList-root": { padding: 0 },
  },
  heading: {
    fontWeight: "500 !important",
    fontSize: "12px !important",
    letterSpacing: "1px !important",
    color: "#818283",
    padding: "12px 16px 6px 16px !important",
  },
  menuItem: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
  },
});

export type ThreeDotMenuProps = {
  /**
   *you can give this prop value of left , bottom and right , and it will render the menu list accordingly
   */
  position?: String; //left bottom right
  /**
   *here you can specify the height of each menuItem.Its default value is 45
   */
  itemHeight?: number;
  /**
   * set max height for menu
   */
  menuMaxHeight?: number | string;
  /**
   * set width for menu
   */
  menuWidth?: number | string;
  /**
   * prop to hide the menu
   */
  hidden?: boolean;
  /**
   *here you can pass array of objects with each object taking the 'name' of the field and the 'onClickFunction' which will be called after clicking that particular field  and in the 'icon' prop you can pass mui icon for example icon: <PriorityHighIcon fontSize="small" />
   */
  options: {
    name: any;
    onClickFunction?: Function;
    icon?: any;
    type?: string;
    disabled?: boolean;
    isSelected?: boolean;
    labelStyle?: any;
    disabledReason?: string;
    groupOptions?: {
      name: string;
      onClickFunction?: Function;
      icon?: string;
      disabled?: boolean;
      isSelected?: boolean;
      labelStyle?: any;
    }[];
  }[];
};

export function ThreeDotMenu({
  options = [],
  position,
  itemHeight = 45,
  menuMaxHeight,
  menuWidth = "20.8ch",
  hidden = false,
}: ThreeDotMenuProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (hidden) {
    return <div />;
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: getColor("primary", 700) }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        className={classes.threeDotMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={
          position === "left"
            ? {
                vertical: "top",
                horizontal: "left",
              }
            : position === "right"
            ? {
                vertical: "top",
                horizontal: "right",
              }
            : {
                vertical: "bottom",
                horizontal: "right",
              }
        }
        transformOrigin={
          position === "left"
            ? {
                vertical: "top",
                horizontal: "right",
              }
            : position === "right"
            ? {
                vertical: "top",
                horizontal: "left",
              }
            : {
                vertical: "top",
                horizontal: "right",
              }
        }
        PaperProps={{
          style: {
            maxHeight: menuMaxHeight ? menuMaxHeight : itemHeight * 4.5,
            width: menuWidth,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          },
        }}
      >
        {options
          ? options.map((option, i) => {
              if (option.type === "group") {
                return (
                  <div
                    key={i}
                    style={{
                      paddingBottom: 5,
                      borderBottom: `1px solid ${getColor("border", 400)}`,
                    }}
                  >
                    <Typography
                      sx={{ ...option.labelStyle }}
                      className={classes.heading}
                    >
                      {option.name}
                    </Typography>
                    {option.groupOptions &&
                      option.groupOptions?.map((option: any) => (
                        <MenuItem
                          role={"menuItem"}
                          sx={{
                            height: 33,
                          }}
                          key={option.name}
                          disabled={option.disabled}
                          selected={option.isSelected}
                          onClick={() => {
                            option.onClickFunction && option.onClickFunction();
                            handleClose();
                          }}
                        >
                          {option.icon && (
                            <ListItemIcon>{option.icon}</ListItemIcon>
                          )}
                          <Typography
                            sx={{ ...option.labelStyle }}
                            className={classes.menuItem}
                            noWrap
                          >
                            {option.name}
                          </Typography>
                        </MenuItem>
                      ))}
                  </div>
                );
              } else {
                return (
                  <Tooltip
                    title={option.disabled ? option?.disabledReason : null}
                    placement="left"
                    arrow
                  >
                    <span>
                      <MenuItem
                        role={"menuItem"}
                        sx={{
                          height: itemHeight,
                          borderBottom: `1px solid ${getColor("border", 400)}`,
                        }}
                        key={option.name}
                        selected={option.isSelected}
                        disabled={option.disabled}
                        onClick={() => {
                          option.onClickFunction && option.onClickFunction();
                          handleClose();
                        }}
                      >
                        {option.icon && (
                          <ListItemIcon>{option.icon}</ListItemIcon>
                        )}
                        <Typography
                          sx={{ ...option.labelStyle }}
                          className={classes.menuItem}
                          noWrap
                        >
                          {option.name}
                        </Typography>
                      </MenuItem>
                    </span>
                  </Tooltip>
                );
              }
            })
          : ""}
      </Menu>
    </>
  );
}
