import axios from "axios";

const BASE_API_PATH = process.env.REACT_APP_DJANGO_BASE_URL;
const BASE_API_PATH_2 = process.env.REACT_APP_DJANGO_BASE_URL_2;

const getBaseApiPath = () => {
  const url = window.location.href;
  if (url.includes("staging2")) {
    return BASE_API_PATH_2;
  }
  return BASE_API_PATH;
};

function getHeaders() {
  return { "Content-Type": "application/json" };
}

function apiGet(url: string) {
  const finalUrl: string = getBaseApiPath() + url;
  return axios.get(finalUrl, { headers: getHeaders() });
}

function apiPost(url: string, body: object, headers: any = {}) {
  const finalUrl: string = getBaseApiPath() + url;
  return axios.post(finalUrl, body, {
    headers: Object.keys(headers).length ? headers : getHeaders(),
  });
}

function apiPut(url: string, body: object, headers: any = {}) {
  const finalUrl: string = getBaseApiPath() + url;
  return axios.put(finalUrl, body, {
    headers: Object.keys(headers).length ? headers : getHeaders(),
  });
}

function apiPatch(url: string, body: object, headers: any = {}) {
  const finalUrl: string = getBaseApiPath() + url;
  return axios.patch(finalUrl, body, {
    headers: Object.keys(headers).length ? headers : getHeaders(),
  });
}

function apiDelete(url: string) {
  const finalUrl: string = getBaseApiPath() + url;
  return axios.delete(finalUrl, { headers: getHeaders() });
}

function apiDeleteWithBody(url: string, data: object) {
  const finalUrl: string = getBaseApiPath() + url;
  return axios({
    url: finalUrl,
    method: "delete",
    responseType: "json",
    withCredentials: true,
    headers: getHeaders(),
    data,
  });
}

export default {
  getHeaders,
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  apiDeleteWithBody,
  apiPatch,
};
